import { IPricing, StripeType } from "../../Constants/types";
import { CheckIcon } from "@heroicons/react/24/outline";
type Props = {
	pricing: StripeType;
	onGetStarted: () => void;
	isSelected?: boolean;
	isUpgrading?: boolean;
};

const PricingItem = ({ pricing, onGetStarted, isSelected, isUpgrading }: Props) => {
	console.log("pricing: ", pricing);

	return (
		<div
			className={`flex mx-5  border-2 hover:border-success ${
				isSelected ? "border-success" : ""
			} slide-from-bottom flex-col items-center trans-all rounded-xl bg-white drop-shadow-lg md:w-1/2 lg:w-[350px] w-full`}>
			<div className='flex flex-col text-center'>
				<span className={`text-3xl mt-5 tracking-wide font-semibold`}>{pricing.product}</span>
				<span className={`lg:text-5xl text-2xl lg:my-5 text-black`}>
					{pricing.currency === "try" ? "₺" : pricing.currency === "usd" ? "$" : "€"}
					{pricing.price}
					<span className={`text-xl`}>/month</span>
				</span>
			</div>
			<div className='flex flex-col items-start lg:mt-10 mt-5 mb-20'>
				{pricing.features.map((feature, index) => (
					<div className='flex flex-row font-bold items-center w-max' key={index}>
						<CheckIcon className='lg:w-7 lg:h-7 w-5 h-5 mx-2 text-success' strokeWidth={2} color='text-success' />
						<span className={`text-black flex-1 text-sm`}>{feature.name}</span>
					</div>
				))}
			</div>
			<button
				disabled={isSelected}
				onClick={onGetStarted}
				className={`w-10/12 mx-auto py-2 mb-5 trans-all text-center rounded-lg hover:bg-success border-2 border-success text-success hover:text-white font-semibold ${
					isSelected ? "" : "cursor-pointer"
				}`}>
				{isUpgrading ? "Upgrade" : "Get Started"}
			</button>
		</div>
	);
};

export default PricingItem;
