import { ChangeEvent, useEffect, useState } from "react";
import Lottie from "lottie-react";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { showAlert } from "../../Redux/reducers/reducers";
import { useLocation, useNavigate } from "react-router-dom";
import { login } from "../../Redux/actions/auth.actions";
import { APP_PATHS } from "../../Constants/constants";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
type Props = {};

const SignInPage = (props: Props) => {
	const [formValues, setFormValues] = useState({
		email: "",
		password: "",
	});
	const { search } = useLocation();

	const [isLeaving, setIsLeaving] = useState({
		leaving: false,
		path: "",
	});
	const navigate = useNavigate();
	const { isAuthenticated, isFetchingUser } = useAppSelector((state) => state.global);
	const dispatch = useAppDispatch();
	const handleInputs = (event: ChangeEvent<HTMLInputElement>) => {
		setFormValues({ ...formValues, [event.currentTarget.name]: event.currentTarget.value });
	};
	const handleSignIn = () => {
		if (!formValues.email || !formValues.password) {
			dispatch(
				showAlert({
					type: "error",
					text: "Email and Password fields are required to sign in !",
				})
			);
			return;
		} else {
			let isAfterStripe = false;
			if (search.includes("stripe=true")) isAfterStripe = true;
			dispatch(
				login({
					email: formValues.email,
					password: formValues.password,
					isAfterStripe,
				})
			);
		}
	};
	useEffect(() => {
		if (isAuthenticated) {
			navigate("/dashboard");
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (isLeaving.leaving) {
			setTimeout(() => {
				navigate(`/${isLeaving.path}`);
			}, 350);
		}
	}, [isLeaving]);
	return (
		<div className='flex items-center justify-center'>
			<div
				className={`flex lg:flex-row lg:mt-24 my-12 flex-col-reverse items-center justify-center mx-auto signin-card rounded-xl w-11/12 lg:w-3/4 ${
					isLeaving.leaving ? "slide-from-top-reverse" : "slide-from-top"
				}`}>
				<div className='lg:border-r border-success border-t lg:border-t-0 mt-2 lg:mt-0 slide-from-left w-11/12 lg:w-1/2'>
					<Lottie animationData={require("../../Assets/Animations/AdminWorking.json")} loop={true} />
				</div>
				<div className='lg:flex-auto flex flex-col fade-in justify-center lg:w-2/3 w-10/12 '>
					<span className='text-2xl mb-2 mt-2 lg:mt-0 mx-auto font-semibold'>Welcome to Talentify Pro !</span>
					<div className='mx-auto px-1  py-3 md:w-2/3 xl:w-1/2 w-full rounded-xl items-center justify-evenly lg:justify-center flex flex-col '>
						<form
							className='p-fluid'
							onSubmit={(e) => {
								e.preventDefault();
								handleSignIn();
							}}>
							<div className='field my-1'>
								<label htmlFor='email' className='font-semibold'>
									Email
								</label>
								<InputText
									id='email'
									type='email'
									name='email'
									className='ring-0 outline-none focus:border-black hover:border-black transition-all duration-300 ease-in-out border px-2 py-1'
									value={formValues.email}
									onChange={handleInputs}
								/>
							</div>
							<div className='field my-1'>
								<label htmlFor='password' className='font-semibold'>
									Password
								</label>
								<InputText
									id='password'
									type='password'
									name='password'
									className='ring-0 outline-none focus:border-black hover:border-black transition-all duration-300 ease-in-out border px-2 py-1'
									value={formValues.password}
									onChange={handleInputs}
								/>
							</div>
							<Button
								label={isFetchingUser ? "Signing In..." : "Sign In"}
								loading={isFetchingUser}
								disabled={!formValues.email && !formValues.password}
								onClick={handleSignIn}
								className='mt-3 py-1 px-3 sign-in'
							/>
						</form>

						<button
							onClick={() => setIsLeaving({ leaving: true, path: APP_PATHS.GET_STARTED })}
							className='px-5 my-1 py-1 w-fit mx-auto text-sm rounded-xl text-black hover:underline'>
							Don't have an account? Sign Up!
						</button>
						<button
							onClick={() => setIsLeaving({ leaving: true, path: APP_PATHS.FORGOT_PASSWORD })}
							className='px-5 my-1 py-1 w-fit mx-auto text-xs rounded-xl text-black mt-10 hover:underline'>
							Forgot Password?
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignInPage;
