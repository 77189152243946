import { useEffect, useState } from "react";
import PageContainer from "../../Components/PageContainer/PageContainer";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import BodyHeader from "../../Components/BodyHeader/BodyHeader";
import { createUser, deleteUser, getUserListByCompanyId, updateUser } from "../../Redux/actions/user.actions";
import { CreateUserBody, IUser } from "../../Constants/types";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "primereact/button";
import { showAlert } from "../../Redux/reducers/reducers";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";

const initialFormValues: CreateUserBody = {
	companyId: -1,
	email: "",
	fullName: "",
	image: "",
	password: "",
	file: undefined,
	birthDate: undefined,
	id: undefined,
};
const UsersPage = () => {
	const { user, users, reFetch, isFetchingUser } = useAppSelector((state) => state.global);
	const [isDeleting, setIsDeleting] = useState({
		deleting: false,
		id: -1,
	});
	const [isDialogVisible, setIsDialogVisible] = useState(false);
	const [formValues, setFormValues] = useState<CreateUserBody>(initialFormValues);
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (reFetch) {
			dispatch(getUserListByCompanyId(user.company.id));
			isDeleting.deleting && setIsDeleting({ deleting: false, id: -1 });
			isDialogVisible && setIsDialogVisible(false);
		}
	}, [reFetch]);

	useEffect(() => {
		if (user) {
			dispatch(getUserListByCompanyId(user.company.id));
		}
	}, [user]);

	const handleDelete = () => {
		dispatch(deleteUser(isDeleting.id));
	};
	const onSave = () => {
		if (isDeleting.deleting) {
			handleDelete();
		} else {
			if (formValues.id) {
				dispatch(updateUser(formValues));
			} else {
				dispatch(
					createUser({
						...formValues,
						companyId: user.companyId,
						password: Math.random().toString(36).slice(-8),
					})
				);
			}
		}
	};
	const onCreateNewUser = () => {
		if (users.length === user.company.numberOfUsers) {
			dispatch(
				showAlert({
					text: `Your plan allows you to have ${user.company.numberOfUsers} users and you already have ${users.length} users`,
					type: "error",
				})
			);
		} else {
			setFormValues(initialFormValues);
			setIsDialogVisible(true);
		}
	};
	const actionBodyTemplate = (rowData: IUser) => {
		return (
			<div className='flex flex-row items-center relative gap-2 justify-center'>
				<Button
					icon='pi pi-pencil'
					className='w-5 ring-0 outline-none'
					severity='info'
					onClick={() => {
						setFormValues({
							...formValues,
							companyId: rowData.companyId,
							email: rowData.email,
							fullName: rowData.fullName,
							image: rowData.image as any,
							id: rowData.id,
							birthDate: rowData.birthDate,
						});
						setIsDialogVisible(true);
					}}
				/>
				<Button
					icon='pi pi-trash'
					className='w-5 ring-0 outline-none'
					severity='danger'
					onClick={() => {
						setFormValues({ ...formValues, fullName: rowData.fullName });
						setIsDeleting({ deleting: true, id: rowData.id! });
					}}
				/>
			</div>
		);
	};
	const dialogFooter = () => (
		<div className='flex flex-row items-center gap-2 justify-between mt-5 md:mt-0'>
			<Button
				loading={isFetchingUser}
				disabled={isFetchingUser}
				label='Cancel'
				outlined
				className='border border-red-600 text-red-600 p-1 outline-none ring-0'
				onClick={hideDialog}
			/>
			<Button
				label='Save'
				loading={isFetchingUser}
				disabled={isFetchingUser}
				className='border border-green-600 text-green-600 py-1 px-2 outline-none ring-0'
				onClick={onSave}
			/>
		</div>
	);
	const hideDialog = () => {
		let vals = initialFormValues;
		vals = { ...vals, companyId: user.companyId };

		setFormValues(vals);
		isDeleting.deleting ? setIsDeleting({ deleting: false, id: -1 }) : setIsDialogVisible(false);
	};
	return (
		<>
			<PageContainer pageTitle='Users'>
				<BodyHeader
					leftItem={
						<span onClick={onCreateNewUser} className='font-semibold cursor-pointer px-2 py-1 hover:underline'>
							Create New User
						</span>
					}
				/>
				<div className='p-5 drop-shadow-md'>
					<DataTable showGridlines loading={isFetchingUser} value={users} stripedRows rowHover dataKey='id'>
						<Column
							field='fullName'
							header='Name Surname'
							headerClassName='bg-black text-white text-xs'
							className='w-max'
						/>
						<Column field='email' header='Email' headerClassName='bg-black text-white text-xs w-max' />
						<Column
							field='company.address'
							header='Address'
							className='w-[250px] overflow-scroll flex whitespace-nowrap no-scrollbar max-h-[60px]'
							headerClassName='bg-black text-white text-xs'
						/>

						<Column
							field='birthDate'
							headerClassName='bg-black text-white text-xs'
							header='Birth Date'
							body={(row) => (row.birthDate ? <div>{moment(row.birthDate).format("DD.MM.YYYY")}</div> : <></>)}
						/>
						<Column
							field='isActive'
							headerClassName='bg-black text-white text-xs'
							header='Active'
							style={{ maxWidth: 50 }}
							body={(row) =>
								row.isActive ? (
									<CheckIcon width={18} height={18} strokeWidth={4} />
								) : (
									<XMarkIcon width={18} height={18} strokeWidth={4} />
								)
							}
						/>
						<Column body={actionBodyTemplate} headerClassName='bg-black text-white text-xs w-[100px]'></Column>
					</DataTable>
				</div>
			</PageContainer>
			<Dialog
				visible={isDialogVisible}
				style={{ width: "32rem" }}
				closeOnEscape
				breakpoints={{ "960px": "75vw", "641px": "90vw" }}
				header={formValues?.id ? "Update User " : "Create New User"}
				modal
				className='p-fluid'
				footer={dialogFooter}
				onHide={hideDialog}>
				<div className='field my-1'>
					<label htmlFor='fullName' className='font-bold'>
						Name Surname
					</label>
					<InputText
						id='fullName'
						autoFocus
						className='ring-0 outline-none border px-2 py-1'
						value={formValues.fullName}
						onChange={(e) => setFormValues({ ...formValues, fullName: e.currentTarget.value })}
					/>
				</div>
				<div className='field my-1'>
					<label htmlFor='email' className='font-bold'>
						Email
					</label>
					<InputText
						id='email'
						type='email'
						className='ring-0 outline-none border px-2 py-1'
						value={formValues.email}
						onChange={(e) => setFormValues({ ...formValues, email: e.currentTarget.value })}
					/>
				</div>
				<div className='field my-1'>
					<label htmlFor='positionClosingDate' className='font-bold'>
						Birth Date
					</label>
					<Calendar
						inputClassName='ring-0 outline-none border px-2 py-1'
						id='positionClosingDate'
						value={formValues.birthDate ? new Date(formValues.birthDate) : undefined}
						onChange={(e) => setFormValues({ ...formValues, birthDate: e.value as Date })}
					/>
				</div>
			</Dialog>

			<Dialog
				visible={isDeleting.deleting}
				closeOnEscape
				style={{ width: "32rem" }}
				breakpoints={{ "960px": "75vw", "641px": "90vw" }}
				header='Confirm'
				modal
				footer={dialogFooter}
				onHide={hideDialog}>
				<div className='confirmation-content'>
					<i className='pi pi-exclamation-triangle mr-3' style={{ fontSize: "2rem" }} />
					<span>
						Are you sure you want to delete <b>{formValues.fullName}</b>?
					</span>
				</div>
			</Dialog>
		</>
	);
};

export default UsersPage;
