import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateCompanyService, GetCompanyListService, UpdateCompanyService } from "../services/company.service";
import { ICompany } from "../../Constants/types";

export const getCompanyList = createAsyncThunk("company/getCompanyList", async (_, thunkAPI) => {
	try {
		return await GetCompanyListService();
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const createCompany = createAsyncThunk("company/createCompany", async (body: Partial<ICompany>, thunkAPI) => {
	try {
		return await CreateCompanyService(body);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const updateCompany = createAsyncThunk("company/updateCompany", async (body: ICompany, thunkAPI) => {
	try {
		return await UpdateCompanyService(body);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
