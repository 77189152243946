import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	CancelMeetingService,
	CreateMeetingService,
	DeleteMeetingService,
	GetMeetingByIdService,
	GetMeetingListByCompanyIdService,
	UpdateMeetingService,
} from "../services/meeting.service";
import { IMeeting } from "../../Constants/types";
import { GetPositionByIdService } from "../services/position.service";

export const getMeetingListByCompanyId = createAsyncThunk(
	"meeting/getMeetingListByCompanyId",
	async (companyId: number, thunkAPI) => {
		try {
			return await GetMeetingListByCompanyIdService(companyId);
		} catch (error: any) {
			const message =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const getMeetingListByPositionId = createAsyncThunk(
	"meeting/getMeetingListByPositionId",
	async (positionId: number, thunkAPI) => {
		try {
			return await GetPositionByIdService(positionId);
		} catch (error: any) {
			const message =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);
export const createMeeting = createAsyncThunk("meeting/createMeeting", async (body: IMeeting, thunkAPI) => {
	try {
		return await CreateMeetingService(body);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const updateMeeting = createAsyncThunk("meeting/updateMeeting", async (body: IMeeting, thunkAPI) => {
	try {
		return await UpdateMeetingService(body);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
export const deleteMeeting = createAsyncThunk("meeting/deleteMeeting", async (id: number, thunkAPI) => {
	try {
		return await DeleteMeetingService(id);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const getMeetingById = createAsyncThunk("meeting/getMeetingById", async (id: number, thunkAPI) => {
	try {
		return await GetMeetingByIdService(id);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
export const cancelMeeting = createAsyncThunk("meeting/cancelMeeting", async (id: number, thunkAPI) => {
	try {
		return await CancelMeetingService(id);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
