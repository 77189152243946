import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../../Redux/store/store";
import SideBar from "../Navbar/SideBar";
import Loading from "../Loading/Loading";

type Props = {};

const RouteProtecter = (props: Props) => {
	const location = useLocation();
	const { isAuthenticated } = useAppSelector((state) => state.global);

	return isAuthenticated === undefined ? (
		<Loading />
	) : isAuthenticated === false ? (
		<Navigate to={"/sign-in"} state={{ from: location }} />
	) : (
		<div className='flex flex-row my-auto min-h-screen'>
			<SideBar />
			<Outlet />
		</div>
	);
};

export default RouteProtecter;
