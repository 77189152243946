import { useEffect } from "react";
import { clearGlobalStates, setVisitorFrom } from "./Redux/reducers/reducers";
import Alert from "./Components/Alert/Alert";
import { useAppDispatch, useAppSelector } from "./Redux/store/store";
import axios from "axios";

import { Timestamp, doc, setDoc, increment } from "firebase/firestore";
import { firestore } from "./Utils/firebase";
type Props = {};

const Main = (props: Props) => {
	const { isAuthenticated, success, error, message, user } = useAppSelector((state) => state.global);
	const dispatch = useAppDispatch();
	const getVisiter = async () => {
		try {
			const response = await axios.get("https://geolocation-db.com/json/");
			const from =
				response.data.country_name === "Turkey"
					? "try"
					: response.data.country_name?.includes("America")
					? "usd"
					: "eur";
			dispatch(setVisitorFrom(from));

			await setDoc(
				doc(firestore, "visiters", `${response.data.IPv4}`),
				{
					...response.data,
					date: Timestamp.fromDate(new Date()),
					visitCount: increment(+1),
				},
				{ merge: true }
			);
		} catch (error) {
			return;
		}
	};
	useEffect(() => {
		getVisiter();
	}, []);
	useEffect(() => {
		if (user.fullName) {
			document.title = `Talentify Pro (${user.company.companyName})`;
		} else {
			document.title = `Talentify Pro`;
		}
	}, [user]);

	useEffect(() => {
		setTimeout(() => {
			dispatch(clearGlobalStates());
		}, 3000);
	}, [error, success]);
	useEffect(() => {
		if (message.length)
			setTimeout(() => {
				dispatch(clearGlobalStates());
			}, 3000);
	}, [message.length]);

	return (
		<>
			{!isAuthenticated &&
				message.length > 0 &&
				message.map((item, index) => <Alert key={index} text={item.text} type={item.type} />)}
		</>
	);
};

export default Main;
