import { createAsyncThunk } from "@reduxjs/toolkit";

import { IReceivedApplication } from "../../Constants/types";
import {
	CreateReceivedApplicationService,
	GetAIReviewService,
	GetReceivedApplicationsListByCompanyIdService,
	GetReceivedApplicationsListService,
	UpdateReceivedApplicationService,
	UpdateReceivedApplicationStatusService,
} from "../services/receivedApplication.service";

export const getReceivedApplicationsListByCompanyId = createAsyncThunk(
	"received-application/getReceivedApplicationsListByCompanyId",
	async (companyId: number, thunkAPI) => {
		try {
			return await GetReceivedApplicationsListByCompanyIdService(companyId);
		} catch (error: any) {
			const message =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const getReceivedApplicationsList = createAsyncThunk(
	"received-application/getReceivedApplicationsList",
	async (_, thunkAPI) => {
		try {
			return await GetReceivedApplicationsListService();
		} catch (error: any) {
			const message =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const createReceivedApplication = createAsyncThunk(
	"received-application/CreateReceivedApplicationService",
	async (body: IReceivedApplication, thunkAPI) => {
		try {
			return await CreateReceivedApplicationService(body);
		} catch (error: any) {
			const message =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const updateReceivedApplication = createAsyncThunk(
	"received-application/updateReceivedApplication",
	async (body: IReceivedApplication, thunkAPI) => {
		try {
			return await UpdateReceivedApplicationService(body);
		} catch (error: any) {
			const message =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);
export const updateReceivedApplicationStatus = createAsyncThunk(
	"received-application/updateReceivedApplicationStatus",
	async (body: { id: number; status: string; emailContent?: string }, thunkAPI) => {
		try {
			return await UpdateReceivedApplicationStatusService(body);
		} catch (error: any) {
			const message =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const getAiReview = createAsyncThunk("received-application/getAiReview", async (id: number, thunkAPI) => {
	try {
		return await GetAIReviewService(id);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
