import { HelpCenterRequestType, IGeneralResponse } from "../../Constants/types";
import axiosInstance from "../../Helpers/axiosClient";
const helpcenter = "helpcenter";
export const CreateHelpCenterRequestService = async (body: HelpCenterRequestType) => {
	try {
		const response = await axiosInstance.post(`${helpcenter}`, body);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.data.message,
		};
	}
};
export const GetHelpCenterRequestsService = async () => {
	try {
		const response = await axiosInstance.get(`${helpcenter}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.data.message,
		};
	}
};
