import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateHelpCenterRequestService, GetHelpCenterRequestsService } from "../services/help-center.service";
import { HelpCenterRequestType } from "../../Constants/types";

export const createHelpCenterRequest = createAsyncThunk(
	"helpcenter/createHelpCenterRequest",
	async (body: HelpCenterRequestType, thunkAPI) => {
		try {
			return await CreateHelpCenterRequestService(body);
		} catch (error: any) {
			const message =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const saveCancelSubscriptionReason = createAsyncThunk(
	"helpcenter/saveCancelSubscriptionReason",
	async (body: HelpCenterRequestType, thunkAPI) => {
		try {
			return await CreateHelpCenterRequestService(body);
		} catch (error: any) {
			const message =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);
export const getHelpCenterRequests = createAsyncThunk("helpcenter/getHelpCenterRequests", async (_, thunkAPI) => {
	try {
		return await GetHelpCenterRequestsService();
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
