import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	EmilCodeVerificationService,
	CreateUserService,
	DeleteUserService,
	ForgotPasswordService,
	GetUserListByCompanyIdService,
	ResetPasswordService,
	SignUpNewUserWithCompanyService,
	UpdateUserService,
	GetAllUsersService,
	CancelSubscriptionService,
} from "../services/users.service";
import {
	EmilCodeVerificationBody,
	CreateUserBody,
	RestPasswordBody,
	SignUpUserWithCompanyBody,
	UpdateUserBody,
} from "../../Constants/types";

export const getUserListByCompanyId = createAsyncThunk("user/getUserListByCompanyId", async (id: number, thunkAPI) => {
	try {
		return await GetUserListByCompanyIdService(id);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllUsers = createAsyncThunk("user/getAllUsers", async (id: number, thunkAPI) => {
	try {
		return await GetAllUsersService();
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const signUpNewUserWithCompany = createAsyncThunk(
	"user/signUpNewUserWithCompany",
	async (body: SignUpUserWithCompanyBody, thunkAPI) => {
		try {
			return await SignUpNewUserWithCompanyService(body);
		} catch (error: any) {
			const message =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const createUser = createAsyncThunk("user/createUser", async (body: CreateUserBody, thunkAPI) => {
	try {
		return await CreateUserService(body);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
export const updateUser = createAsyncThunk("user/updateUser", async (body: UpdateUserBody, thunkAPI) => {
	try {
		return await UpdateUserService(body);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const deleteUser = createAsyncThunk("user/deleteUser", async (id: number, thunkAPI) => {
	try {
		return await DeleteUserService(id);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const forgotPassword = createAsyncThunk("user/forgotPassword", async (email: string, thunkAPI) => {
	try {
		return await ForgotPasswordService(email);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const emailCodeVerification = createAsyncThunk(
	"user/emailCodeVerification",
	async (body: EmilCodeVerificationBody, thunkAPI) => {
		try {
			return await EmilCodeVerificationService(body);
		} catch (error: any) {
			const message =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);
export const resetPassword = createAsyncThunk("user/resetPassword", async (body: RestPasswordBody, thunkAPI) => {
	try {
		return await ResetPasswordService(body);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const cancelSubscription = createAsyncThunk(
	"user/cancelSubscription",
	async (body: { companyId: number }, thunkAPI) => {
		try {
			return await CancelSubscriptionService(body);
		} catch (error: any) {
			const message =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);
