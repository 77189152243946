import { ChangeEvent, useEffect, useState } from "react";
import PageContainer from "../../../Components/PageContainer/PageContainer";
import { showAlert } from "../../../Redux/reducers/reducers";
import { useAppDispatch, useAppSelector } from "../../../Redux/store/store";
import BodyHeader from "../../../Components/BodyHeader/BodyHeader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { XMarkIcon, CheckIcon } from "@heroicons/react/24/outline";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { Feature, IPricing, StripePriceType } from "../../../Constants/types";
import { createPricing, getStripePricingList, updatePricing } from "../../../Redux/actions/pricing.actions";
import { GetStripePricesService } from "../../../Redux/services/stripe.service";
import { HttpStatusCode } from "axios";
type Props = {};
const initialFormValues: IPricing = {
	activePositionCount: 0,
	id: "",
	numberOfUsers: 0,
	price: 1,
	specs: "",
	title: "",
	totalPositionCount: 0,
	lookupkey: "",
};
const AdminPricingPage = (props: Props) => {
	const { reFetch, user, openPositions, pricingList, visitorFrom } = useAppSelector((state) => state.global);
	const [stripePrices, setStripePrices] = useState<StripePriceType[]>([]);
	const [loading, setLoading] = useState(true);
	const [isDialogVisible, setIsDialogVisible] = useState(false);
	const [formValues, setFormValues] = useState<IPricing>(initialFormValues);
	const dispatch = useAppDispatch();

	const getStripePrices = async () => {
		const response = await GetStripePricesService();
		if (response.status === HttpStatusCode.Ok) {
			setStripePrices(response.data);
		} else {
			dispatch(
				showAlert({
					type: "error",
					text: response.message,
				})
			);
		}
		setLoading(false);
	};
	useEffect(() => {
		getStripePrices();
	}, []);

	useEffect(() => {
		if (reFetch) {
			dispatch(getStripePricingList("usd"));
			setFormValues(initialFormValues);
			setIsDialogVisible(false);
		}
	}, [reFetch]);

	const handleInputs = (event: ChangeEvent<HTMLInputElement>) => {
		setFormValues({ ...formValues, [event.currentTarget.name]: event.currentTarget.value });
	};
	const onDialogSave = () => {
		dispatch(updatePricing(formValues));
	};

	const actionBodyTemplate = (rowData: StripePriceType) => {
		return (
			<div className='flex flex-row items-center relative gap-2 justify-center'>
				<Button
					icon='pi pi-pencil'
					className='w-5 outline-none ring-0'
					severity='info'
					onClick={() => {
						let _specs = "";
						rowData.product.features.map((feature, index) => {
							_specs += index === rowData.product.features.length - 1 ? feature.name : feature.name + ", ";
						});
						setFormValues({
							activePositionCount: Number(rowData.product.metadata.activeJobForms),
							id: rowData.id,
							lookupkey: rowData.lookup_key,
							numberOfUsers: Number(rowData.product.metadata.numberOfUsers),
							specs: _specs,
							price: Number(rowData.unit_amount) / 100,
							title: rowData.product.name,
							totalPositionCount: Number(rowData.product.metadata.totalJobForms),
						});
						setIsDialogVisible(true);
					}}
				/>
			</div>
		);
	};
	const dialogFooter = () => (
		<div className='flex flex-row items-center gap-2 justify-between mt-5 md:mt-0'>
			<Button
				label='Cancel'
				loading={loading}
				disabled={loading}
				className='border border-red-600 text-red-600 p-1 outline-none ring-0'
				onClick={hideDialog}
			/>
			<Button
				label='Save'
				loading={loading}
				disabled={loading}
				className='border border-green-600 text-green-600 py-1 px-2 outline-none ring-0'
				onClick={onDialogSave}
			/>
		</div>
	);

	const hideDialog = () => {
		setFormValues(initialFormValues);
		setIsDialogVisible(false);
	};
	return (
		<>
			<PageContainer pageTitle='Pricings'>
				<BodyHeader />
				<div className='p-5 drop-shadow-md'>
					<DataTable showGridlines loading={loading} value={stripePrices} stripedRows rowHover dataKey='id'>
						<Column field='product.name' header='Product' headerClassName='bg-black text-white' key={"product.name"} />
						<Column
							field='product.features.name'
							key='product.features.name'
							header='Features'
							className='w-[750px] overflow-scroll flex whitespace-nowrap no-scrollbar max-h-[60px]'
							headerClassName='bg-black text-white'
							body={(row) => (
								<div className='uppercase' key={row.id}>
									{row.product.features.map((feature: Feature, index: number) => (
										<small key={index}>{feature.name}, </small>
									))}
								</div>
							)}
						/>
						<Column
							field='unit_amount'
							key='unit_amount'
							header='Price'
							headerClassName='bg-black text-white'
							body={(row) => (
								<div className='uppercase'>
									{row.unit_amount / 100} {row.currency}
								</div>
							)}
						/>
						<Column field='lookup_key' key='lookup_key' header='Lookup Key' headerClassName='bg-black text-white' />
						<Column body={actionBodyTemplate} key={"action"} headerClassName='bg-black text-white'></Column>
					</DataTable>
				</div>
			</PageContainer>
			<Dialog
				visible={isDialogVisible}
				style={{ width: "32rem" }}
				closeOnEscape
				breakpoints={{ "960px": "75vw", "641px": "90vw" }}
				header={formValues?.id ? "Update Pricing " : "Create Pricing"}
				modal
				className='p-fluid'
				footer={dialogFooter}
				onHide={hideDialog}>
				<div className='field my-1'>
					<label htmlFor='title' className='font-semibold'>
						Title
					</label>
					<InputText
						id='title'
						name='title'
						className='ring-0 outline-none border px-2 py-1'
						value={formValues.title}
						onChange={handleInputs}
						autoFocus
					/>
				</div>
				<div className='field my-1 '>
					<label htmlFor='price' className='font-semibold'>
						Price
					</label>
					<InputNumber
						id='price'
						inputClassName='ring-0 outline-none border px-2 py-1 rounded-md'
						value={formValues.price}
						onChange={(e) => setFormValues({ ...formValues, price: e.value ?? 0 })}
					/>
				</div>
				<div className='field my-1'>
					<label htmlFor='specs' className='font-semibold'>
						Specs
					</label>
					<InputText
						id='specs'
						name='specs'
						className='ring-0 outline-none border px-2 py-1'
						value={formValues.specs}
						onChange={handleInputs}
					/>
				</div>
				<div className='field my-1'>
					<label htmlFor='numberOfUsers' className='font-semibold'>
						Number Of Users
					</label>
					<InputText
						id='numberOfUsers'
						className='ring-0 outline-none border px-2 py-1'
						value={`${formValues.numberOfUsers}`}
						onChange={handleInputs}
					/>
				</div>
				<div className='field my-1 '>
					<label htmlFor='activePositionCount' className='font-semibold'>
						Active Position Count
					</label>
					<InputNumber
						id='activePositionCount'
						inputClassName='ring-0 outline-none border px-2 py-1 rounded-md'
						value={formValues.activePositionCount}
						onChange={(e) => setFormValues({ ...formValues, activePositionCount: e.value ?? 0 })}
					/>
				</div>
				<div className='field my-1 '>
					<label htmlFor='totalPositionCount' className='font-semibold'>
						Total Position Count
					</label>
					<InputNumber
						id='totalPositionCount'
						inputClassName='ring-0 outline-none border px-2 py-1 rounded-md'
						value={formValues.totalPositionCount}
						onChange={(e) => setFormValues({ ...formValues, totalPositionCount: e.value ?? 0 })}
					/>
				</div>
				{/* <div className='field flex flex-row items-center mt-3 justify-between'>
					<label htmlFor='isActive' className='font-semibold'>
						Active
					</label>
					<Checkbox
						id='isActive'
						name='isActive'
						className='border rounded-md h-fit'
						onChange={(e) => setFormValues({ ...formValues!, isActive: e.checked })}
						checked={Boolean(formValues?.isActive)}></Checkbox>
				</div> */}
			</Dialog>
		</>
	);
};

export default AdminPricingPage;
