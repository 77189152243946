import React, { useEffect } from "react";
import { useAppSelector } from "../../Redux/store/store";
import Alert from "../Alert/Alert";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../Utils/firebase";

type Props = {
	children: React.ReactElement | React.ReactNode;
	className?: React.HTMLAttributes<HTMLDivElement>["className"];
	pageTitle: string;
};

const PageContainer = ({ children, className, pageTitle }: Props) => {
	const { message } = useAppSelector((state) => state.global);
	useEffect(() => {
		document.title = `Talentify Pro | ${pageTitle}`;
		logEvent(analytics, "page_view", {
			page_location: window.location.origin,
			page_title: `Talentify Pro | ${pageTitle}`,
		});
	}, []);

	return (
		<>
			{message.length > 0 && message.map((item, index) => <Alert key={index} text={item.text} type={item.type} />)}
			<div
				className={`flex flex-col page-container lg:float-right flex-1 md:mx-5 h-screen md:h-95vh overflow-x-scroll my-auto md:rounded-2xl bg-[#f0f0f0] ${
					className ? className : ""
				}`}>
				{children}
			</div>
		</>
	);
};

export default PageContainer;
