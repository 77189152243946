import { CreateCreditCardType, IGeneralResponse } from "../../Constants/types";
import axiosInstance from "../../Helpers/axiosClient";
const credit_card = "credit-card";
export const CreateCreditCardService = async (body: CreateCreditCardType) => {
	try {
		const response = await axiosInstance.post(`${credit_card}`, body);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.data.message,
		};
	}
};
