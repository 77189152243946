import { IGeneralResponse, IPosition } from "../../Constants/types";
import axiosInstance from "../../Helpers/axiosClient";
const position = "positions";
export const GetPositionListByCompanyIdService = async (companyId: number) => {
	try {
		const response = await axiosInstance.get(`${position}/by-company?companyId=${companyId}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.message,
		};
	}
};
export const CreatePositionService = async (body: IPosition) => {
	try {
		const response = await axiosInstance.post(`${position}`, body);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.message,
		};
	}
};
export const UpdatePositionService = async (body: IPosition) => {
	try {
		const { id, ...rest } = body;
		const response = await axiosInstance.put(`${position}/${id}`, rest);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.message,
		};
	}
};
export const DeletePosition = async (id: number) => {
	try {
		const response = await axiosInstance.delete(`${position}/${id}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.message,
		};
	}
};

export const GetPositionByIdService = async (id: number) => {
	try {
		const response = await axiosInstance.get(`${position}/${id}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.message,
		};
	}
};

export const GetActivePositionsService = async () => {
	try {
		const response = await axiosInstance.get(`${position}/active`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.message,
		};
	}
};
