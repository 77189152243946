import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	CreatePositionService,
	DeletePosition,
	GetActivePositionsService,
	GetPositionByIdService,
	GetPositionListByCompanyIdService,
	UpdatePositionService,
} from "../services/position.service";
import { IPosition } from "../../Constants/types";

export const getPositionListByCompanyId = createAsyncThunk(
	"position/getPositionList",
	async (companyId: number, thunkAPI) => {
		try {
			return await GetPositionListByCompanyIdService(companyId);
		} catch (error: any) {
			const message =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);
export const createPosition = createAsyncThunk("position/createPosition", async (body: IPosition, thunkAPI) => {
	try {
		return await CreatePositionService(body);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const updatePosition = createAsyncThunk("position/updatePosition", async (body: IPosition, thunkAPI) => {
	try {
		return await UpdatePositionService(body);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
export const deletePosition = createAsyncThunk("position/deletePosition", async (id: number, thunkAPI) => {
	try {
		return await DeletePosition(id);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const getPositionById = createAsyncThunk("position/getPositionById", async (id: number, thunkAPI) => {
	try {
		return await GetPositionByIdService(id);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const getActivePositions = createAsyncThunk("position/getActivePositions", async (_, thunkAPI) => {
	try {
		return await GetActivePositionsService();
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
