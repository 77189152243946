import axios from "axios";
import { API_BASE_URL, LOCAL_STORAGE_TOKEN_KEY } from "../Constants/api";

let axiosInstance = axios.create({
	baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use((request: any) => {
	const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
	request.headers.Authorization = `Bearer ${token}`;
	return request;
});
axiosInstance.interceptors.response.use((response) => response);
export default axiosInstance;
