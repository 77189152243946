import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button } from "primereact/button";
import React, { useState } from "react";
import { useAppDispatch } from "../../Redux/store/store";
import { showAlert } from "../../Redux/reducers/reducers";

type Props = {
	clientSecret: string;
	onPaymentSuccess: () => void;
	rowId: number;
};

const StripeForm = ({ clientSecret, onPaymentSuccess, rowId }: Props) => {
	const stripe = useStripe();
	const stripeElements = useElements();
	const dispatch = useAppDispatch();
	const [isProcessing, setIsProcessing] = useState(false);
	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!stripe || !stripeElements) {
			return;
		} else {
			setIsProcessing(true);
			const { error, paymentIntent } = await stripe.confirmPayment({
				elements: stripeElements,
				confirmParams: {
					return_url: `${window.location.origin}/received-applications?rowId=${rowId}`,
				},
				redirect: "if_required",
			});
			if (error) {
				dispatch(
					showAlert({
						text: error.message || "Unable to proceed with the payment!",
						type: "error",
					})
				);
				return;
			} else if (paymentIntent?.status === "succeeded") {
				onPaymentSuccess();
			}
			setIsProcessing(false);
		}
	};
	return (
		<form id='payment-form' onSubmit={handleSubmit} className='flex flex-col'>
			<PaymentElement />
			<Button
				className='border border-black rounded-md self-center mt-5 py-1 w-fit px-2'
				label={isProcessing ? "Processing..." : "Pay"}
				loading={isProcessing}
				disabled={isProcessing}
			/>
		</form>
	);
};

export default StripeForm;
