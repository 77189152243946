import { IGeneralResponse, IReceivedApplication } from "../../Constants/types";
import axiosInstance from "../../Helpers/axiosClient";
const receviedApplication = "received-applications";

export const CreateReceivedApplicationService = async (body: IReceivedApplication) => {
	try {
		const response = await axiosInstance.post(`${receviedApplication}`, body);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: "",
		};
	}
};
export const GetReceivedApplicationsListByCompanyIdService = async (companyId: number) => {
	try {
		const response = await axiosInstance.get(`${receviedApplication}/by-company/${companyId}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: "",
		};
	}
};

export const GetReceivedApplicationsListService = async () => {
	try {
		const response = await axiosInstance.get(`${receviedApplication}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: "",
		};
	}
};

export const UpdateReceivedApplicationService = async (body: IReceivedApplication) => {
	try {
		const response = await axiosInstance.put(`${receviedApplication}/${body.id}`, body);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: "",
		};
	}
};
export const UpdateReceivedApplicationStatusService = async (body: {
	id: number;
	status: string;
	emailContent?: string;
}) => {
	try {
		const { id, ...rest } = body;
		const response = await axiosInstance.put(`${receviedApplication}/update-status/${id}`, { ...rest });
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: "",
		};
	}
};
export const GetAIReviewService = async (id: number) => {
	try {
		const response = await axiosInstance.get(`${receviedApplication}/ai-review/${id}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: "",
		};
	}
};
