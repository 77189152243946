import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { APP_PATHS } from "./Constants/constants";
import LandingPage from "./Pages/Auth/LandingPage";
import SignInPage from "./Pages/Auth/SignInPage";
import ForgotPasswordPage from "./Pages/Auth/ForgotPasswordPage";
import GetStartedPage from "./Pages/Auth/GetStartedPage";
import ApplyPositionPage from "./Pages/App/ApplyPositionPage";
import HelpCenterPage from "./Pages/App/HelpCenterPage";
import JobsPage from "./Pages/App/JobsPage";
import RouteProtecter from "./Components/RouteProtecter/RouteProtecter";
import DashboardPage from "./Pages/App/DashboardPage";
import UsersPage from "./Pages/App/UsersPage";
import ReceivedApplicationsPage from "./Pages/App/ReceivedApplicationsPage";
import MeetingsPage from "./Pages/App/MeetingsPage";
import PositionsPage from "./Pages/App/PositionsPage";
import SettingsPage from "./Pages/App/SettingsPage";
import UpgradePlanPage from "./Pages/App/UpgradePlanPage";
import ChangePasswordPage from "./Pages/App/ChangePasswordPage";
import AdminRouteProtecter from "./Components/RouteProtecter/AdminRouteProtecter";
import AdminDashboardPage from "./Pages/App/Admin/AdminDashboardPage";
import AdminCompaniesPage from "./Pages/App/Admin/AdminCompaniesPage";
import AdminHelpCenterPage from "./Pages/App/Admin/AdminHelpCenterPage";
import AdminUsersPage from "./Pages/App/Admin/AdminUsersPage";
import AdminPricingPage from "./Pages/App/Admin/AdminPricingPage";
import AdminLogsPage from "./Pages/App/Admin/AdminLogsPage";
import NotFoundPage from "./Pages/NotFoundPage";
import { LOCAL_STORAGE_TOKEN_KEY } from "./Constants/api";
import { useAppDispatch } from "./Redux/store/store";
import { getUserByToken } from "./Redux/actions/auth.actions";
import { logout } from "./Redux/reducers/reducers";
import MeetingPage from "./Pages/App/MeetingPage";
import Loading from "./Components/Loading/Loading";

const Routers = React.memo(() => {
	const dispatch = useAppDispatch();
	const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

	useEffect(() => {
		if (token) {
			dispatch(getUserByToken());
		} else {
			dispatch(logout());
		}
	}, []);

	return (
		<Suspense fallback={<Loading />}>
			<Routes>
				{/* AUTH PAGES */}
				<Route path={`/${APP_PATHS.LANDING}`} element={<LandingPage />} />
				<Route path={`/${APP_PATHS.SIGN_IN}`} element={<SignInPage />} />
				<Route path={`/${APP_PATHS.FORGOT_PASSWORD}`} element={<ForgotPasswordPage />} />
				<Route path={`/${APP_PATHS.GET_STARTED}`} element={<GetStartedPage />} />
				<Route path={`/${APP_PATHS.APPLY_POSITION}`} element={<ApplyPositionPage />} />
				<Route path={`/${APP_PATHS.HELP_CENTER}`} element={<HelpCenterPage />} />
				<Route path={`/${APP_PATHS.JOBS}`} element={<JobsPage />} />
				<Route path={`/${APP_PATHS.MEETING}/:meetingId`} element={<MeetingPage />} />
				{/* APP PAGES */}
				<Route element={<RouteProtecter />}>
					<Route path={`/${APP_PATHS.DASHBOARD}`} element={<DashboardPage />} />
					<Route path={`/${APP_PATHS.USERS}`} element={<UsersPage />} />
					<Route path={`/${APP_PATHS.RECEIVED_APPLICATIONS}`} element={<ReceivedApplicationsPage />} />
					<Route path={`/${APP_PATHS.MEETINGS}`} element={<MeetingsPage />} />
					<Route path={`/${APP_PATHS.POSITIONS}`} element={<PositionsPage />} />
					<Route path={`/${APP_PATHS.SETTINGS}`} element={<SettingsPage />} />
					<Route path={`/${APP_PATHS.HELP_CENTER}`} element={<HelpCenterPage />} />
					{/* <Route path={`/${APP_PATHS.UPGRADE_PLAN}`} element={<UpgradePlanPage />} /> */}
					<Route path={`/${APP_PATHS.CHANGE_PASSWORD}`} element={<ChangePasswordPage />} />
				</Route>
				{/* ADMIN PAGES */}
				<Route element={<AdminRouteProtecter />}>
					<Route path={`/${APP_PATHS.ADMIN_DASHBOARD}`} element={<AdminDashboardPage />} />
					<Route path={`/${APP_PATHS.ADMIN_COMPANIES}`} element={<AdminCompaniesPage />} />
					<Route path={`/${APP_PATHS.ADMIN_HELP_CENTER}`} element={<AdminHelpCenterPage />} />
					<Route path={`/${APP_PATHS.ADMIN_USERS}`} element={<AdminUsersPage />} />
					<Route path={`/${APP_PATHS.ADMIN_PRICING}`} element={<AdminPricingPage />} />
					<Route path={`/${APP_PATHS.ADMIN_LOGS}`} element={<AdminLogsPage />} />
				</Route>
				<Route path='*' element={<NotFoundPage />} />
			</Routes>
		</Suspense>
	);
});

export default Routers;
