import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import PageContainer from "../../Components/PageContainer/PageContainer";
import { ChangeEvent, useEffect, useState } from "react";
import { IMeeting, IReceivedApplication, IReceivedApplicationStatuses, IUser } from "../../Constants/types";
import { clearAiReviewResult, showAlert } from "../../Redux/reducers/reducers";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import BodyHeader from "../../Components/BodyHeader/BodyHeader";
import {
	getAiReview,
	getReceivedApplicationsListByCompanyId,
	updateReceivedApplicationStatus,
} from "../../Redux/actions/receivedApplication.actions";
import moment from "moment";
import { XMarkIcon, CheckIcon } from "@heroicons/react/24/outline";
import { Button } from "primereact/button";
import { createMeeting } from "../../Redux/actions/meeting.actions";
import { getUserListByCompanyId } from "../../Redux/actions/user.actions";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { API_BASE_URL } from "../../Constants/api";
import { Dropdown } from "primereact/dropdown";
import { LOOKUP_KEYS, ReceviedApplicationStatuses } from "../../Constants/constants";
import AIIcon from "../../Assets/svg/AIIcon";
import { Tooltip } from "primereact/tooltip";
import Lottie from "lottie-react";
import axiosInstance from "../../Helpers/axiosClient";
import { HttpStatusCode } from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "../../Components/StripeForm/StripeForm";
import { useLocation } from "react-router-dom";
import shortid from "shortid";
import { InputTextarea } from "primereact/inputtextarea";
type Props = {};
const initialMeetingForm = {
	receivedApplicationId: -1,
	companyId: -1,
	date: "",
	email: "",
	isActive: true,
	meetingLink: "",
	positionId: -1,
	resume: undefined,
	with: "",
	userIds: [],
};

const ReceivedApplicationsPage = (props: Props) => {
	const { user, reFetch, receviedApplications, users, todaysMeetings, loading, aiReviewResult } = useAppSelector(
		(state) => state.global
	);
	const dispatch = useAppDispatch();
	const [isMeetingModalOpen, setIsMeetingModalOpen] = useState(false);
	const [isDialogVisible, setIsDialogVisible] = useState(false);
	const [applicationStatus, setApplicationStatus] = useState<IReceivedApplicationStatuses>();
	const [rejectionEmailContent, setRejectionEmailContent] = useState("");
	const [id, setId] = useState(0);
	const [meetingForm, setMeetingForm] = useState<IMeeting>(initialMeetingForm);
	const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
	const [isAiReviewDialogVisible, setIsAiReviewDialogVisible] = useState(false);
	const [stripeConfirmationFormStep, setStripeConfirmationFormStep] = useState(0);
	const [rowId, setRowId] = useState(0);
	/* STRIPE */
	const [stripePromise, setStripePromise] = useState<any>(null);
	const [stripePublishableKey, setStripePublishableKey] = useState<string | undefined>();
	const [stripeClientSecret, setStripeClientSecret] = useState<undefined | string>();
	const { search } = useLocation();
	const getStripePublishableKey = async () => {
		const response = await axiosInstance.get("get-stripe-key");
		if (response.data.status === HttpStatusCode.Ok) {
			setStripePublishableKey(response.data.data);
			setStripePromise(loadStripe(response.data.data));
		}
	};
	const stripeCreatePaymentIntent = async () => {
		const response = await axiosInstance.get("stripe-create-payment-intent");
		if (response.data.status === HttpStatusCode.Ok) {
			setStripeClientSecret(response.data.data.clientSecret);
		} else {
			dispatch(
				showAlert({
					text: "Stripe connection failed, please try again later",
					type: "error",
				})
			);
		}
	};

	useEffect(() => {
		dispatch(getReceivedApplicationsListByCompanyId(user.company.id));
		if (search) {
			let _rowId = Number(search.split("=")[1]);
			if (Boolean(_rowId)) {
				setRowId(_rowId);
				setIsAiReviewDialogVisible(true);
				setStripeConfirmationFormStep(2);
				dispatch(getAiReview(_rowId));
			}
		}
	}, []);

	useEffect(() => {
		if (reFetch) {
			if (isMeetingModalOpen) {
				setIsMeetingModalOpen(false);
				setMeetingForm(initialMeetingForm);
			}
			if (isDialogVisible) {
				setIsDialogVisible(false);
			}
			dispatch(getReceivedApplicationsListByCompanyId(user.companyId));
		}
	}, [reFetch]);

	useEffect(() => {
		if (isMeetingModalOpen && users.length < 1) {
			dispatch(getUserListByCompanyId(user.company.id));
		}
	}, [isMeetingModalOpen]);

	const handleMeetingInputs = (event: ChangeEvent<HTMLInputElement>) => {
		setMeetingForm({ ...meetingForm, [event.currentTarget.name]: event.currentTarget.value });
	};
	const onSetAMeeting = () => {
		if (!meetingForm.meetingLink || !meetingForm.date) {
			dispatch(
				showAlert({
					text: "Please fill all fields",
					type: "error",
				})
			);
		} else {
			dispatch(createMeeting(meetingForm));
		}
	};
	const hideDialog = () => {
		if (isMeetingModalOpen) {
			setIsMeetingModalOpen(false);
			setMeetingForm(initialMeetingForm);
		}
		if (isDialogVisible) setIsDialogVisible(false);
		if (isAiReviewDialogVisible) {
			setIsAiReviewDialogVisible(false);
			dispatch(clearAiReviewResult());
			stripeConfirmationFormStep !== 0 && setStripeConfirmationFormStep(0);
			rowId !== 0 && setRowId(0);
		}
	};
	const onSave = () => {
		if (isDialogVisible) {
			dispatch(
				updateReceivedApplicationStatus({
					id,
					status: applicationStatus!,
					emailContent: applicationStatus === "Rejected" ? rejectionEmailContent : undefined,
				})
			);
		} else {
			onSetAMeeting();
		}
	};
	const dialogFooter = () => (
		<div className='flex flex-row items-center gap-2 justify-between mt-5 md:mt-0'>
			<Button
				label='Cancel'
				loading={loading}
				disabled={loading}
				className='border border-red-600 text-red-600 outline-none ring-0 p-1'
				onClick={hideDialog}
			/>
			<Button
				label='Save'
				disabled={loading}
				loading={loading}
				className='border border-green-600 outline-none ring-0 text-green-600 py-1 px-2'
				onClick={onSave}
			/>
		</div>
	);
	const onAIReview = async (rowData: IReceivedApplication) => {
		setIsAiReviewDialogVisible(true);
		if (user.company.pricing.lookupkey !== LOOKUP_KEYS.BUSINESS_PRO) {
			setRowId(rowData.id!);
			await getStripePublishableKey();
		} else {
			dispatch(getAiReview(rowData.id!));
		}
	};
	const onPaymentSuccess = () => {
		setStripeConfirmationFormStep(2);
		dispatch(
			showAlert({
				text: "Thank you for your payment!",
				type: "success",
			})
		);
		setTimeout(() => {
			dispatch(getAiReview(rowId));
		}, 1500);
	};
	const actionBodyTemplate = (rowData: IReceivedApplication) => {
		return (
			<div className='flex flex-row items-center relative gap-2 justify-center'>
				<Button
					icon='pi pi-pencil'
					className='w-5'
					severity='info'
					onClick={() => {
						setApplicationStatus(rowData.status);
						setIsDialogVisible(true);
						setId(rowData.id!);
						setRejectionEmailContent(
							`Hello ${rowData.fullName}, even tho your resume is impressive, ` +
								`we are sorry to inform you that after carefully consideration, we have decided to move forward with other candidates who suits our needs a little better.` +
								` Thank you for your interest to our company and we wish you a bright career in your life.`
						);
					}}
				/>
				<Button
					icon='pi pi-calendar'
					className='w-5 outline-none ring-0'
					severity='secondary'
					onClick={() => {
						setMeetingForm({
							...meetingForm,
							with: rowData.fullName,
							email: rowData.email,
							positionId: rowData.position!.id!,
							companyId: rowData.position!.companyId,
							resume: rowData.resume,
							receivedApplicationId: rowData.id!,
							meetingLink: "",
							// meetingLink: shortid.generate(),
						});
						setIsMeetingModalOpen(true);
					}}
				/>
				<Tooltip target='.ai-review' className='text-xs'>
					Get an AI Review
				</Tooltip>
				<Button
					data-pr-position='top'
					className='w-5 ai-review outline-none ring-0 cursor-pointer'
					onClick={() => onAIReview(rowData)}>
					<AIIcon />
				</Button>
			</div>
		);
	};
	return (
		<>
			<PageContainer pageTitle='Received Applications'>
				<BodyHeader leftItem={`You have ${todaysMeetings.length} meetings today`} />
				<div className='p-5 drop-shadow-md'>
					<DataTable showGridlines loading={loading} value={receviedApplications} stripedRows rowHover dataKey='id'>
						<Column field='position.position' header='Position' headerClassName='bg-black text-white text-xs' />
						<Column field='fullName' header='Full Name' headerClassName='bg-black text-white text-xs w-[250px]' />
						<Column field='email' header='Email' headerClassName='bg-black text-white text-xs' className='w-[250px]' />
						<Column
							field='appliedDate'
							header='Applied Date'
							headerClassName='bg-black text-white text-xs'
							className='w-[200px]  '
							body={(row) => <div>{moment(row.appliedDate).format("DD.MM.YYYY")}</div>}
						/>
						<Column
							field='appliedFrom'
							header='Applied From'
							className='w-[220px]'
							headerClassName='bg-black text-white text-xs'
						/>
						<Column
							field='coverLetter'
							header='Cover Letter'
							className='w-[250px] overflow-scroll flex whitespace-nowrap no-scrollbar max-h-[60px]'
							headerClassName='bg-black text-white text-xs'
						/>

						<Column field='status' headerClassName='bg-black text-white text-xs' header='Status' />
						<Column
							field='resume'
							headerClassName='bg-black text-white text-xs'
							header='Resume'
							body={(row) => (
								<div>
									{row.resume && (
										<a href={`${row.resume}`} target='_blank'>
											View Resume
										</a>
									)}
								</div>
							)}
						/>

						<Column
							field='isActive'
							headerClassName='bg-black text-white text-xs'
							header='Active'
							style={{ maxWidth: 50 }}
							body={(row) =>
								row.isActive ? (
									<CheckIcon width={18} height={18} strokeWidth={4} />
								) : (
									<XMarkIcon width={18} height={18} strokeWidth={4} />
								)
							}
						/>
						<Column body={actionBodyTemplate} headerClassName='bg-black text-white text-xs w-[100px]'></Column>
					</DataTable>
				</div>
			</PageContainer>
			<Dialog
				visible={isMeetingModalOpen}
				closeOnEscape
				style={{ width: "32rem" }}
				breakpoints={{ "960px": "75vw", "641px": "90vw" }}
				header={() => (
					<div className='font-normal'>
						Set a meeting with <b>{meetingForm.with}</b>
					</div>
				)}
				modal
				className='p-fluid'
				footer={dialogFooter}
				onHide={hideDialog}>
				<div className='field my-1'>
					<label htmlFor='meetingLink' className='font-bold'>
						Meeting Link
					</label>
					<InputText
						id='meetingLink'
						name='meetingLink'
						className='ring-0 outline-none border px-2 py-1'
						value={meetingForm.meetingLink}
						onChange={handleMeetingInputs}
					/>
				</div>
				<div className='field my-1'>
					<label htmlFor='positionClosingDate' className='font-bold'>
						Meeeting Date
					</label>
					<Calendar
						inputClassName='ring-0 outline-none border px-2 py-1'
						value={meetingForm.date as any}
						showTime
						onChange={(e) => setMeetingForm({ ...meetingForm, date: e.value as any })}
					/>
				</div>
				<div className='field my-1'>
					<label htmlFor='employmentType' className='font-bold'>
						Attendees
					</label>
					<MultiSelect
						value={selectedUsers}
						onChange={(e) => {
							let ids = e.value.map((user: IUser) => user.id);
							setMeetingForm({ ...meetingForm, userIds: ids });
							setSelectedUsers(e.value);
						}}
						options={users}
						optionLabel='fullName'
						placeholder='Select Attendees'
						maxSelectedLabels={3}
						className='ring-0 outline-none border px-2 py-1'
						itemClassName='ring-0 outline-none border'
					/>
				</div>
			</Dialog>
			<Dialog
				visible={isDialogVisible}
				style={{ width: "32rem" }}
				closeOnEscape
				breakpoints={{ "960px": "75vw", "641px": "90vw" }}
				header={"Update Received Application Status"}
				modal
				className='p-fluid'
				footer={dialogFooter}
				onHide={hideDialog}>
				<div className='field my-1 '>
					<label htmlFor='applicationStatus' className='font-semibold'>
						Status
					</label>
					<Dropdown
						value={applicationStatus}
						onChange={(e) => {
							setApplicationStatus(e.value);
						}}
						options={ReceviedApplicationStatuses}
						className='w-full md:w-14rem ring-0 outline-none border'
						id='applicationStatus'
					/>
					{applicationStatus === "Rejected" && (
						<div className='field my-1'>
							<label htmlFor='meetingLink' className='font-bold'>
								Rejection Email Content
							</label>
							<InputTextarea
								id='rejectionEmailContent'
								name='rejectionEmailContent'
								rows={3}
								cols={30}
								autoResize={true}
								className='ring-0 outline-none border px-2 py-1'
								value={rejectionEmailContent}
								onChange={(e) => {
									setRejectionEmailContent(e.currentTarget.value);
								}}
							/>
						</div>
					)}
				</div>
			</Dialog>
			<Dialog
				visible={isAiReviewDialogVisible}
				style={{ width: "35rem" }}
				closeOnEscape
				header={"AI Resume Review"}
				modal
				className='p-fluid'
				onHide={hideDialog}>
				{user.company.pricing.lookupkey === LOOKUP_KEYS.BUSINESS_PRO || stripeConfirmationFormStep === 2 ? (
					<>
						<div className='m-auto'>
							<Lottie
								animationData={require("../../Assets/Animations/AIReviewAnimation.json")}
								autoPlay={true}
								loop={true}
								className='w-[250px] m-auto'
							/>
							{!aiReviewResult && (
								<div className='w-full text-center'>
									<code className='text-xs'>
										Hey there, as TalentifyPro-AI I'm reviewing this resume for you, just a second please!
									</code>
								</div>
							)}
						</div>
						{aiReviewResult && (
							<div
								className='text-sm h-[200px] p-2 overflow-y-scroll'
								dangerouslySetInnerHTML={{ __html: aiReviewResult }}
							/>
						)}
					</>
				) : (
					<div>
						{stripeConfirmationFormStep === 0 ? (
							<div className='flex flex-col'>
								<p>
									<span>
										Your company's current plan <b>"{user.company.pricing.title}"</b> does not cover free AI Resume
										Review feature.
									</span>
									<br />
									<span>
										In order to use this feature you need to pay <b>$1</b>
									</span>
								</p>
								<br />

								<Button
									className='border-success bg-success text-white border rounded-lg w-fit mx-auto px-3 py-1'
									label='Proceed to Payment'
									onClick={async () => {
										setStripeConfirmationFormStep(1);
										await stripeCreatePaymentIntent();
									}}
								/>
							</div>
						) : (
							stripeClientSecret &&
							stripePublishableKey && (
								<Elements
									stripe={stripePromise}
									options={{
										clientSecret: stripeClientSecret,
									}}>
									<StripeForm clientSecret={stripeClientSecret} onPaymentSuccess={onPaymentSuccess} rowId={rowId} />
								</Elements>
							)
						)}
					</div>
				)}
			</Dialog>
		</>
	);
};

export default ReceivedApplicationsPage;
