import {
	CallControls,
	CallingState,
	SpeakerLayout,
	StreamTheme,
	useCall,
	useCallStateHooks,
} from "@stream-io/video-react-sdk";

import "@stream-io/video-react-sdk/dist/css/styles.css";
import React, { useEffect } from "react";

import { EndMeetingService } from "../../Redux/services/meeting.service";
import { useNavigate } from "react-router-dom";
import { APP_PATHS } from "../../Constants/constants";

type Props = {
	meetingId: string;
	onParticipantCountChange: (number: number) => void;
	isGuest: boolean;
};

const MeetingBody = ({ meetingId, onParticipantCountChange, isGuest }: Props) => {
	const call = useCall();
	const navigate = useNavigate();
	const { useCallCallingState, useParticipantCount } = useCallStateHooks();
	const participantCount = useParticipantCount();
	const callingState = useCallCallingState();
	useEffect(() => {
		if (participantCount > 0) {
			onParticipantCountChange(participantCount);
		}
	}, [participantCount]);
	const handleLeave = async () => {
		call?.endCall();
		try {
			await EndMeetingService(meetingId);
			isGuest ? navigate(`/${APP_PATHS.JOBS}`) : navigate(`/${APP_PATHS.DASHBOARD}`);
		} catch (error) {
			isGuest ? navigate(`/${APP_PATHS.JOBS}`) : navigate(`/${APP_PATHS.DASHBOARD}`);
		}
	};
	useEffect(() => {
		if (callingState === CallingState.LEFT) {
			isGuest ? navigate(`/${APP_PATHS.JOBS}`) : navigate(`/${APP_PATHS.DASHBOARD}`);
		}
	}, [callingState]);

	return (
		<StreamTheme>
			<SpeakerLayout participantsBarPosition={"bottom"} />
			<CallControls onLeave={handleLeave} />
		</StreamTheme>
	);
};

export default MeetingBody;
