type Props = {};

const NotFoundPage = (props: Props) => {
	return (
		<div className='flex items-center justify-center w-screen h-screen'>
			The page you are looking for does not exist !!
		</div>
	);
};

export default NotFoundPage;
