import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateCreditCardType } from "../../Constants/types";
import { CreateCreditCardService } from "../services/creditCard.service";

export const createCreditCard = createAsyncThunk("creditCard/create", async (body: CreateCreditCardType, thunkAPI) => {
	try {
		return await CreateCreditCardService(body);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
