import React, { useEffect, useState } from "react";
import PageContainer from "../../../Components/PageContainer/PageContainer";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { GetAllUsersService } from "../../../Redux/services/users.service";

type Props = {};

const AdminUsersPage = (props: Props) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const getData = async () => {
		const response = await GetAllUsersService();
		setData(response.data);
		setLoading(false);
	};
	useEffect(() => {
		getData();
	}, []);

	return (
		<PageContainer pageTitle='Admin Users Page'>
			<DataTable loading={loading} value={data} showGridlines stripedRows rowHover dataKey='id'>
				<Column headerClassName='bg-black text-white' field='fullName' header='Name Surname' />
				<Column headerClassName='bg-black text-white' field='email' header='Email' />
				<Column headerClassName='bg-black text-white' field='company.companyName' header='Company' />
				<Column headerClassName='bg-black text-white' field='company.pricing.title' header='Pricing' />
			</DataTable>
		</PageContainer>
	);
};

export default AdminUsersPage;
