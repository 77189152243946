import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import PageContainer from "../../Components/PageContainer/PageContainer";
import { useEffect, useLayoutEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import BodyHeader from "../../Components/BodyHeader/BodyHeader";
import moment from "moment";
import { updateMeeting } from "../../Redux/actions/meeting.actions";
import { IUser } from "../../Constants/types";
import { getUserByToken } from "../../Redux/actions/auth.actions";
import { getDashboardData } from "../../Redux/actions/dashboard.actions";
import DashboardBox from "../../Components/DashboardBox/DashboardBox";
import Icon from "../../Components/Icon/Icon";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { APP_PATHS } from "../../Constants/constants";
import axiosInstance from "../../Helpers/axiosClient";

const meetingUpdateModalInitial = {
	isOpen: false,
	meetingId: -1,
};

const DashboardPage = () => {
	const { user, userActiveMeetings, todaysMeetings, reFetch, dashboardData, allMeetings, loading } = useAppSelector(
		(state) => state.global
	);

	const dispatch = useAppDispatch();

	const [meetingUpdateModal, setMeetingUpdateModal] = useState(meetingUpdateModalInitial);
	useLayoutEffect(() => {
		if (todaysMeetings.length) {
			if (moment(todaysMeetings[0].date).format("DD.MM.YYYY HH:mm") <= moment(new Date()).format("DD.MM.YYYY HH:mm"))
				setMeetingUpdateModal({
					isOpen: true,
					meetingId: todaysMeetings[0].id!,
				});
		} else {
			if (meetingUpdateModal.isOpen) setMeetingUpdateModal(meetingUpdateModalInitial);
		}
	}, [userActiveMeetings]);

	const onYesClick = () => {
		let userIds = todaysMeetings[0].users!.map((user: IUser) => user.id);
		dispatch(updateMeeting({ ...todaysMeetings[0], isMeetingCompleted: true, userIds }));
	};

	useEffect(() => {
		if (reFetch) {
			dispatch(getUserByToken());
			dispatch(getDashboardData(user.email));
		}
	}, [reFetch]);

	useEffect(() => {
		dispatch(getUserByToken());
		dispatch(getDashboardData(user.email));
		// axiosInstance.post("users/cancel-subscription", {
		// 	email: user.email,
		// });
	}, []);

	return (
		<>
			<PageContainer pageTitle='Dashboard'>
				<BodyHeader leftItem={`Welcome, ${user.fullName}`} />
				<div className='flex flex-col gap-5'>
					<div className='flex flex-col md:flex-row items-center justify-evenly flex-wrap'>
						<DashboardBox
							title='Users'
							values={[{ field: "Total", value: dashboardData?.totalUsers || 0 }]}
							icon={<Icon iconName={"users"} />}
							path={APP_PATHS.USERS}
						/>
						<DashboardBox
							title='Position'
							values={[
								{ field: "Total", value: dashboardData?.positions.total || 0 },
								{ field: "Active", value: dashboardData?.positions.active || 0 },
							]}
							icon={<Icon iconName={"open-position"} />}
							path={APP_PATHS.POSITIONS}
						/>
						<DashboardBox
							title='Received Applications'
							values={[
								{ field: "Received", value: dashboardData?.receivedApplications.received || 0 },
								{ field: "Interviewing", value: dashboardData?.receivedApplications.interviewing || 0 },
								{ field: "Hired", value: dashboardData?.receivedApplications.hired || 0 },
								{ field: "Rejected", value: dashboardData?.receivedApplications.rejected || 0 },
							]}
							icon={<Icon iconName={"check"} />}
							path={APP_PATHS.RECEIVED_APPLICATIONS}
						/>
						<DashboardBox
							title="Today's Meetings"
							values={[{ field: "Total", value: todaysMeetings?.length || 0 }]}
							icon={<Icon iconName={"meeting"} />}
							className='flex lg:hidden'
							path={APP_PATHS.MEETINGS}
						/>
					</div>
					<hr className='hidden lg:inline' />
					<div className='p-5 drop-shadow-md hidden lg:inline'>
						<div className='mb-2.5 text-2xl font-semibold flex items-center gap-2'>
							<span className='-mt-1'>{user.fullName.split(" ")[0]}'s Meetings</span> <Icon iconName={"meeting"} />
						</div>
						<DataTable loading={loading} value={allMeetings} showGridlines stripedRows rowHover dataKey='id'>
							<Column headerClassName='bg-black text-white' field='position.position' header='Position' />
							<Column headerClassName='bg-black text-white' field='with' header='Name Surname' />
							<Column headerClassName='bg-black text-white' field='email' header='Email' />
							<Column
								headerClassName='bg-black text-white'
								field='meetingLink'
								header='Meeting Link'
								body={(row) => (
									<a
										href={row.meetingLink}
										// href={`${window.location.origin}/meeting/${row.meetingLink}`}
										target='_blank'
										className='underline'>
										{row.meetingLink}
										{/* {`${window.location.origin}/meeting/${row.meetingLink}`} */}
									</a>
								)}
							/>
							<Column
								headerClassName='bg-black text-white'
								field='date'
								header='Date'
								body={(row) => {
									return <div>{moment(row.date).format("DD.MM.YYYY HH:mm")}</div>;
								}}
							/>
							<Column
								headerClassName='bg-black text-white'
								field='users'
								body={(row) => row.users.map((user: IUser, index: number) => <div key={index}>{user.fullName}</div>)}
								header='Users'
							/>
							<Column
								field='isMeetingCompleted'
								headerClassName='bg-black text-white'
								header='Completed'
								body={(row) =>
									row.isMeetingCompleted ? (
										<CheckIcon width={18} height={18} strokeWidth={4} />
									) : (
										<XMarkIcon width={18} height={18} strokeWidth={4} />
									)
								}
							/>
						</DataTable>
					</div>
				</div>
			</PageContainer>
			<Dialog
				visible={meetingUpdateModal.isOpen}
				style={{ width: "32rem" }}
				closeOnEscape
				breakpoints={{ "960px": "75vw", "641px": "90vw" }}
				onHide={() => setMeetingUpdateModal({ meetingId: -1, isOpen: false })}
				modal
				header={() => (
					<div className='font-medium'>
						{todaysMeetings[0]?.with} - {todaysMeetings[0]?.position?.position}
					</div>
				)}
				className='p-fluid'>
				<div className=' flex min-w-fit flex-col items-center justify-around rounded-xl'>
					<span className='text-center font-medium mb-5'>
						Did you complete today's meeting with <span className='font-bold'>{todaysMeetings[0]?.with}</span>?
					</span>
					<div className='flex flex-row items-center gap-5 justify-between md:mt-0'>
						<Button
							label='No'
							outlined
							className='border border-red-600 text-red-600 outline-none ring-0 p-1 w-[100px]'
							onClick={() => setMeetingUpdateModal(meetingUpdateModalInitial)}
						/>
						<Button
							label='Yes'
							className='border border-green-600 outline-none ring-0 text-green-600 py-1 px-2 w-[100px]'
							onClick={onYesClick}
						/>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default DashboardPage;
