import { IGeneralResponse, IPricing } from "../../Constants/types";
import axiosInstance from "../../Helpers/axiosClient";
const pricing = "pricing";
export const GetPricingListService = async () => {
	try {
		const response = await axiosInstance.get(pricing);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.data.message,
		};
	}
};

export const CreatePricingService = async (body: Partial<IPricing>) => {
	try {
		const response = await axiosInstance.post(pricing, body);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.data.message,
		};
	}
};

export const UpdatePricingService = async (body: IPricing) => {
	try {
		const response = await axiosInstance.put(`${pricing}/${body.id}`, body);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.data.message,
		};
	}
};
