import PageContainer from "../../Components/PageContainer/PageContainer";
import { APP_PATHS, CancelationReasons, DummySettings } from "../../Constants/constants";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import BodyHeader from "../../Components/BodyHeader/BodyHeader";
import { API_BASE_URL } from "../../Constants/api";
import { useNavigate } from "react-router-dom";
import { CameraIcon } from "@heroicons/react/24/outline";
import { createRef, useEffect, useState } from "react";
import { cancelSubscription, updateUser } from "../../Redux/actions/user.actions";
import { ProgressSpinner } from "primereact/progressspinner";
import { getUserByToken } from "../../Redux/actions/auth.actions";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { logout } from "../../Redux/reducers/reducers";
import { saveCancelSubscriptionReason } from "../../Redux/actions/help-center.actions";
import moment from "moment";
import useUploadStorage from "../../Hooks/useUploadImage";

const SettingsPage = () => {
	const { user, loading, reFetch, isSubscriptionCanceled } = useAppSelector((state) => state.global);
	const { media, progress, setMedia, uploadCV, uploadImage } = useUploadStorage();
	const [file, setFile] = useState<File>();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [isCanceling, setIsCanceling] = useState(false);
	const [cancelationReason, setCancelationReason] = useState("");
	const [isOtherSelected, setIsOtherSelected] = useState(false);
	const fileRef = createRef<HTMLInputElement>();
	useEffect(() => {
		if (reFetch) {
			dispatch(getUserByToken());
		}
	}, [reFetch]);
	useEffect(() => {
		if (isSubscriptionCanceled) {
			setTimeout(() => {
				dispatch(logout());
			}, 2000);
		}
	}, [isSubscriptionCanceled]);

	useEffect(() => {
		if (media) {
			dispatch(
				updateUser({
					companyId: user.companyId,
					email: user.email,
					fullName: user.fullName,
					image: media!,
					id: user.id,
				})
			);
		}
	}, [media]);

	const onDialogSave = async () => {
		dispatch(
			saveCancelSubscriptionReason({
				category: "Subscription Cancelation",
				description: cancelationReason,
				email: user.email,
				status: "New",
				topic: "Subscription Cancelation",
			})
		);
		dispatch(cancelSubscription({ companyId: user.companyId }));
	};

	const hideDialog = () => {
		setIsCanceling(false);
	};
	const dialogFooter = () => (
		<div className='flex flex-row items-center gap-2 justify-between mt-5 md:mt-0'>
			<Button
				label='Cancel'
				loading={loading}
				disabled={loading || cancelationReason.length < 25}
				className='border border-red-600 text-red-600 p-1 outline-none ring-0'
				onClick={hideDialog}
			/>
			<Button
				label='Save'
				loading={loading}
				disabled={loading || cancelationReason.length < 25}
				className='border border-green-600 text-green-600 py-1 px-2 outline-none ring-0'
				onClick={onDialogSave}
			/>
		</div>
	);
	return (
		<>
			<PageContainer pageTitle='Settings'>
				<BodyHeader leftItem={<ArrowLeftIcon color='black' width={24} height={24} />} leavingTo={APP_PATHS.DASHBOARD} />
				<div className='md:w-[500px] mx-auto w-11/12 flex mt-10 justify-center items-center flex-col rounded-xl'>
					<div className='flex relative mb-5 w-full rounded-xl border-black border p-3 flex-row items-center justify-evenly'>
						{loading ? (
							<ProgressSpinner className='w-6 h-6 absolute right-2 cursor-pointer top-2' strokeWidth='5' />
						) : (
							<CameraIcon
								className='w-6 h-6 absolute right-2 cursor-pointer top-2'
								onClick={() => fileRef.current?.click()}
							/>
						)}
						<input
							type='file'
							accept='image/*'
							className='hidden'
							ref={fileRef}
							onChange={async (event) => {
								if (event.target.files) {
									const fileUploaded = event.target.files[0];
									const buffer = await fileUploaded.arrayBuffer();
									let fileNameSplitted = fileUploaded.name.split(".");
									let originalExtension =
										fileNameSplitted.length > 1 ? fileNameSplitted[fileNameSplitted.length - 1] : fileNameSplitted[0];
									const fileName = `${user.email.split(".com")[0]}.${originalExtension}`;
									const newFile = new File([buffer], fileName, { type: fileUploaded.type });
									uploadImage(newFile);
								}
							}}
						/>
						<img
							src={user.image ? `${user.image}` : require("../../Assets/Images/user_placeholder.jpeg")}
							className='w-32 object-cover h-32 rounded-full'
							alt='User Image'
						/>
						<div className='flex flex-col text-right'>
							<span className='font-semibold'>{user.fullName}</span>
							<span className='font-semibold'>{user.email}</span>
							<span className='font-semibold italic text-xs'>{user.company.companyName}</span>
							<span className='text-xs'>
								{user.company.isTrial ? (
									<span className=''>Your Trial Ends </span>
								) : (
									<span className=''>Your Trial Ended </span>
								)}

								<span className='font-semibold underline'>{moment(user.company.trialEndDate).fromNow()}</span>
							</span>
							<span className='text-xs'>
								<span className='font-semibold'>Current Plan: </span>
								{user.company.pricing.title}
							</span>
						</div>
					</div>
					{DummySettings.map((setting, index) => (
						<span
							key={index}
							onClick={() => navigate(`/${setting.link}`)}
							className='font-semibold w-[300px] my-2 py-1 hover:bg-black hover:text-white text-black border border-black trans-all cursor-pointer text-center rounded-xl'>
							{setting.text}
						</span>
					))}
					<span
						key={"index"}
						onClick={() => setIsCanceling(true)}
						className='font-semibold w-[300px] my-2 py-1 hover:bg-red-600 hover:text-white text-red-600 border border-red-600 trans-all cursor-pointer text-center rounded-xl'>
						Cancel Subscription
					</span>
				</div>
			</PageContainer>
			<Dialog
				visible={isCanceling}
				closeOnEscape
				style={{ width: "32rem" }}
				breakpoints={{ "960px": "75vw", "641px": "90vw" }}
				header="You're about to cancel your subscription"
				modal
				headerClassName='text-red-600'
				footer={dialogFooter}
				onHide={hideDialog}>
				<div className='flex flex-col w-full'>
					<span className='text-sm font-medium'>We are very sorry to see that you are considering leaving 😔</span>
					<br />
					<span className='text-sm font-medium'>
						Before you leave, please let us know what was the reason for it so we can make our app better for other
						users.
					</span>
					<br />
					<Dropdown
						value={isOtherSelected ? "Other" : cancelationReason}
						onChange={(e) => {
							if (e.value === "Other") {
								setIsOtherSelected(true);
								setCancelationReason("");
							} else {
								setIsOtherSelected(false);
								setCancelationReason(e.value);
							}
						}}
						options={CancelationReasons}
						className='ring-0 outline-none border px-2 my-1 focus:border-primary'
					/>
					{isOtherSelected && (
						<InputText
							className='ring-0 outline-none border px-2 py-2.5 focus:border-black mt-3'
							value={cancelationReason}
							onChange={(e) => setCancelationReason(e.currentTarget.value)}
						/>
					)}
				</div>
			</Dialog>
		</>
	);
};

export default SettingsPage;
