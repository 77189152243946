import { IGeneralResponse } from "../../Constants/types";
import axiosInstance from "../../Helpers/axiosClient";

export const GetStripeDataService = async (visitorFrom?: string) => {
	try {
		const response = await axiosInstance.get("/get-stripe-data" + "/" + visitorFrom);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.data.message,
		};
	}
};

export const GetStripePricesService = async () => {
	try {
		const response = await axiosInstance.get("/get-stripe-prices");
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.data.message,
		};
	}
};

export const CreateStripePaymentIntentService = async () => {
	try {
		const response = await axiosInstance.get("/create-payment-intent");
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.data.message,
		};
	}
};
