import axios from "axios";
import {
	EmilCodeVerificationBody,
	CreateUserBody,
	IGeneralResponse,
	RestPasswordBody,
	SignUpUserWithCompanyBody,
	UpdateUserBody,
} from "../../Constants/types";
import axiosInstance from "../../Helpers/axiosClient";
import { API_BASE_URL } from "../../Constants/api";
const users = "users";
export const GetUserListByCompanyIdService = async (id: number) => {
	try {
		const response = await axiosInstance.get(`${users}/get-by-company/${id}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: "",
		};
	}
};

export const GetAllUsersService = async () => {
	try {
		const response = await axiosInstance.get(`${users}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: "",
		};
	}
};
export const SignUpNewUserWithCompanyService = async (body: SignUpUserWithCompanyBody) => {
	try {
		const response = await axiosInstance.post(`${users}/sign-up-with-company`, body);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: "",
		};
	}
};

export const CreateUserService = async (body: CreateUserBody) => {
	try {
		const response = await axiosInstance.post(`${users}`, body);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: null,
			message: error.response.data.message,
		};
	}
};
export const UpdateUserService = async (body: UpdateUserBody) => {
	try {
		const response = await axiosInstance.put(`${users}/${body.id}`, body);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: null,
			message: error.response.data.message,
		};
	}
};

export const DeleteUserService = async (id: number) => {
	try {
		const response = await axiosInstance.delete(`${users}/${id}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: null,
			message: error.response.data.message,
		};
	}
};

export const ForgotPasswordService = async (email: string) => {
	try {
		const response = await axiosInstance.post(`${users}/forgot-password`, { email });
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: null,
			message: error.response.data.message,
		};
	}
};

export const EmilCodeVerificationService = async (body: EmilCodeVerificationBody) => {
	try {
		const response = await axiosInstance.post(`${users}/confirm-code`, body);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: null,
			message: error.response.data.message,
		};
	}
};

export const ResetPasswordService = async (body: RestPasswordBody) => {
	try {
		const { token, ...rest } = body;
		const response = await axiosInstance.post(
			users + "/reset-password",
			{ ...rest },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: null,
			message: error.response.data.message,
		};
	}
};

export const CancelSubscriptionService = async (body: { companyId: number }) => {
	try {
		const response = await axiosInstance.post(`${users}/cancel-subscription`, body);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: null,
			message: error.response.data.message,
		};
	}
};
