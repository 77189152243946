import {
	HelpCenterCategoryTypes,
	HelpCenterStatusTypes,
	IFieldTypes,
	IMenuItem,
	IReceivedApplicationStatuses,
	ISetting,
} from "./types";
const admin = "admin";
export const APP_PATHS = {
	DASHBOARD: "dashboard",
	test: "test",
	USERS: "users",
	POSITIONS: "positions",
	RECEIVED_APPLICATIONS: "received-applications",
	APPLY_POSITION: "apply/:id",
	MEETINGS: "meetings",
	UPDATE_MEETING: "update-meetings",
	CREATE_USER: "create-user",
	UPDATE_USER: "update-user",
	UPDATE_RECEIVED_APPLICATIONS: "update-received-application",
	OPEN_NEW_POSITIONS: "open-new-position",
	UPDATE_POSITION: "update-position",
	SIGN_IN: "sign-in",
	GET_STARTED: "get-started",
	LANDING: "",
	SETTINGS: "settings",
	FORGOT_PASSWORD: "forgot-password",
	HELP_CENTER: "help-center",
	UPGRADE_PLAN: "upgrade-plan",
	CHANGE_PASSWORD: "change-password",
	JOBS: "jobs",
	MEETING: "meeting",
	ADMIN_DASHBOARD: `${admin}/dashboard`,
	ADMIN_JOBS: `${admin}/jobs`,
	ADMIN_HELP_CENTER: `${admin}/help-center`,
	ADMIN_USERS: `${admin}/users`,
	ADMIN_COMPANIES: `${admin}/companies`,
	ADMIN_LOGS: `${admin}/logs`,
	ADMIN_PRICING: `${admin}/pricing`,
};
export const LOOKUP_KEYS = {
	FIRST_STEP: "firstStep_monthly",
	COMFORT_ZONE: "comfortZone_monthly",
	LETS_GET_SERIOUS: "letsGetSerious_monthly",
	BUSINESS_PRO: "businessPro_monthly",
};

export const ReceviedApplicationStatuses: IReceivedApplicationStatuses[] = [
	"Received",
	"Hired",
	"Interviewing",
	"Rejected",
];

export const SideBarMenuList: IMenuItem[] = [
	{
		name: "Dashboard",
		path: APP_PATHS.DASHBOARD,
		icon: "dashboard",
	},
	{
		name: "Users",
		path: APP_PATHS.USERS,
		icon: "user",
	},
	{
		name: "Positions",
		path: APP_PATHS.POSITIONS,
		icon: "open-position",
	},
	{
		name: "Received Applications",
		path: APP_PATHS.RECEIVED_APPLICATIONS,
		icon: "check",
	},
	{
		name: "Meetings",
		path: APP_PATHS.MEETINGS,
		icon: "meeting",
	},
	{
		name: "Settings",
		path: APP_PATHS.SETTINGS,
		icon: "settings",
	},
];
export const SuperAdminSideBarMenuList: IMenuItem[] = [
	{
		name: "Admin Dashboard",
		path: APP_PATHS.ADMIN_DASHBOARD,
		icon: "pi-th-large",
	},
	{
		name: "Users",
		path: APP_PATHS.ADMIN_USERS,
		icon: "pi-users",
	},
	{
		name: "Pricing",
		path: APP_PATHS.ADMIN_PRICING,
		icon: "pi-dollar",
	},
	{
		name: "Companies",
		path: APP_PATHS.ADMIN_COMPANIES,
		icon: "pi-building",
	},
	{
		name: "Logs",
		path: APP_PATHS.ADMIN_LOGS,
		icon: "pi-book",
	},
	{
		name: "HelpCenter",
		path: APP_PATHS.ADMIN_HELP_CENTER,
		icon: "pi-comments",
	},
];

export const NumberOfApplicationsOptions = {
	responsive: true,
	plugins: {
		legend: {
			position: "top" as const,
		},
		title: {
			display: false,
			text: "number of applications for the open positions",
		},
	},
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const NumberOfApplicationsData = {
	labels,
	datasets: [
		{
			label: "React Native Developer",
			data: [
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
			],
			backgroundColor: "#2E8BC0",
		},
		{
			label: "React Developer",
			data: [
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
			],
			backgroundColor: "#0C2D48",
		},
		{
			label: "Node JS Developer",
			data: [
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
			],
			backgroundColor: "#B1D4E0",
		},
		{
			label: "QA Tester",
			data: [
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
			],
			backgroundColor: "#2E8540",
		},
		{
			label: "IT Recuirter",
			data: [
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
				Math.floor(Math.random() * 5).toFixed(0),
			],
			backgroundColor: "#FDB81E",
		},
	],
};

export const FieldTypes: IFieldTypes[] = ["text", "number", "date", "document", "image"];
export const DummySettings: ISetting[] = [
	{
		text: "Change My Password",
		link: APP_PATHS.CHANGE_PASSWORD,
	},
	// {
	// 	text: "Upgrade Plan",
	// 	link: APP_PATHS.UPGRADE_PLAN,
	// },
	{
		text: "Help Center",
		link: APP_PATHS.HELP_CENTER,
	},
];
export const Currencies = ["USD", "Euro", "GBP", "Turkish Lira"];

export const WorkingStyles = ["On Site", "Hybrid", "Remote"];
export const EmploymentTypes = ["Full Time", "Half Time", "Freelance", "Contract Based"];
export const Experiences = ["0-1 Year", "1-3 Years", "3-5 Years", "5-8 Years", "8+ Years"];
export const Degrees = ["High School", "Associate Degree", "Bachelor's Degree", "Master's Degree", "Doctoral Degree"];

export const HelpCenterStatuses: HelpCenterStatusTypes[] = ["New", "Closed", "In Review", "Replied", "Solved"];
export const CountriesWithCities = [
	{ countryName: "Albania", cities: ["Tirana", "Other"] },
	{ countryName: "Andorra", cities: ["Andorra la Vella", "Other"] },
	{ countryName: "Austria", cities: ["Vienna", "Other"] },
	{
		countryName: "Australia",
		cities: [
			"Canberra",
			"Sydney",
			"Melbourne",
			"Brisbane",
			"Perth",
			"Adelaide",
			"Hobart",
			"Darwin",
			"Gold Coast",
			"Newcastle",
			"Other",
		],
	},
	{ countryName: "Belgium", cities: ["Brussels", "Other"] },
	{ countryName: "Bulgaria", cities: ["Sofia", "Other"] },
	{
		countryName: "Canada",
		cities: [
			"Ottawa",
			"Toronto",
			"Vancouver",
			"Montreal",
			"Calgary",
			"Edmonton",
			"Quebec City",
			"Winnipeg",
			"Halifax",
			"Victoria",
			"Other",
		],
	},
	{ countryName: "Croatia", cities: ["Zagreb", "Other"] },
	{ countryName: "Cyprus", cities: ["Nicosia", "Other"] },
	{ countryName: "Czech Republic", cities: ["Prague", "Other"] },
	{ countryName: "Denmark", cities: ["Copenhagen", "Other"] },
	{ countryName: "Estonia", cities: ["Tallinn", "Other"] },
	{ countryName: "Finland", cities: ["Helsinki", "Other"] },
	{
		countryName: "France",
		cities: [
			"Paris",
			"Marseille",
			"Lyon",
			"Toulouse",
			"Nice",
			"Nantes",
			"Strasbourg",
			"Montpellier",
			"Bordeaux",
			"Lille",
			"Other",
		],
	},
	{
		countryName: "Germany",
		cities: [
			"Berlin",
			"Hamburg",
			"Munich",
			"Cologne",
			"Frankfurt",
			"Stuttgart",
			"Düsseldorf",
			"Dortmund",
			"Essen",
			"Leipzig",
			"Other",
		],
	},
	{ countryName: "Greece", cities: ["Athens", "Thessaloniki", "Other"] },
	{ countryName: "Hungary", cities: ["Budapest", "Other"] },
	{ countryName: "Iceland", cities: ["Reykjavik", "Other"] },
	{
		countryName: "India",
		cities: [
			"New Delhi",
			"Mumbai",
			"Bangalore",
			"Chennai",
			"Kolkata",
			"Hyderabad",
			"Pune",
			"Ahmedabad",
			"Jaipur",
			"Lucknow",
			"Other",
		],
	},
	{
		countryName: "Italy",
		cities: [
			"Rome",
			"Milan",
			"Naples",
			"Turin",
			"Palermo",
			"Genoa",
			"Bologna",
			"Florence",
			"Venice",
			"Verona",
			"Other",
		],
	},
	{
		countryName: "Japan",
		cities: [
			"Tokyo",
			"Osaka",
			"Kyoto",
			"Yokohama",
			"Sapporo",
			"Fukuoka",
			"Nagoya",
			"Kobe",
			"Osaka",
			"Hiroshima",
			"Other",
		],
	},
	{
		countryName: "Mexico",
		cities: [
			"Mexico City",
			"Guadalajara",
			"Monterrey",
			"Puebla",
			"Tijuana",
			"Leon",
			"Juarez",
			"Zapopan",
			"Merida",
			"San Luis Potosi",
			"Other",
		],
	},
	{
		countryName: "Netherlands",
		cities: [
			"Amsterdam",
			"Rotterdam",
			"The Hague",
			"Utrecht",
			"Eindhoven",
			"Groningen",
			"Maastricht",
			"Haarlem",
			"Leiden",
			"Delft",
			"Other",
		],
	},
	{
		countryName: "New Zealand",
		cities: [
			"Wellington",
			"Auckland",
			"Christchurch",
			"Hamilton",
			"Tauranga",
			"Dunedin",
			"Palmerston North",
			"Napier",
			"Rotorua",
			"Queenstown",
			"Other",
		],
	},
	{
		countryName: "Norway",
		cities: [
			"Oslo",
			"Bergen",
			"Trondheim",
			"Stavanger",
			"Drammen",
			"Fredrikstad",
			"Kristiansand",
			"Sandnes",
			"Tromso",
			"Sarpsborg",
			"Other",
		],
	},
	{
		countryName: "Poland",
		cities: [
			"Warsaw",
			"Krakow",
			"Lodz",
			"Wroclaw",
			"Poznan",
			"Gdansk",
			"Szczecin",
			"Bydgoszcz",
			"Lublin",
			"Katowice",
			"Other",
		],
	},
	{
		countryName: "Portugal",
		cities: [
			"Lisbon",
			"Porto",
			"Amadora",
			"Braga",
			"Coimbra",
			"Funchal",
			"Setubal",
			"Aveiro",
			"Evora",
			"Faro",
			"Other",
		],
	},
	{
		countryName: "South Africa",
		cities: [
			"Cape Town",
			"Johannesburg",
			"Durban",
			"Pretoria",
			"Port Elizabeth",
			"Bloemfontein",
			"East London",
			"Polokwane",
			"Nelspruit",
			"Kimberley",
			"Other",
		],
	},
	{
		countryName: "South Korea",
		cities: [
			"Seoul",
			"Busan",
			"Incheon",
			"Daegu",
			"Daejeon",
			"Gwangju",
			"Ulsan",
			"Suwon",
			"Seongnam",
			"Goyang",
			"Other",
		],
	},
	{
		countryName: "Spain",
		cities: [
			"Madrid",
			"Barcelona",
			"Valencia",
			"Seville",
			"Zaragoza",
			"Malaga",
			"Murcia",
			"Palma",
			"Las Palmas",
			"Bilbao",
			"Other",
		],
	},
	{
		countryName: "Sweden",
		cities: [
			"Stockholm",
			"Gothenburg",
			"Malmö",
			"Uppsala",
			"Västerås",
			"Örebro",
			"Linköping",
			"Helsingborg",
			"Jönköping",
			"Norrköping",
			"Other",
		],
	},
	{
		countryName: "Switzerland",
		cities: [
			"Zurich",
			"Geneva",
			"Basel",
			"Bern",
			"Lausanne",
			"Lucerne",
			"St. Gallen",
			"Winterthur",
			"Lugano",
			"Zug",
			"Other",
		],
	},
	{
		countryName: "Turkey",
		cities: [
			"Adana",
			"Adiyaman",
			"Afyonkarahisar",
			"Agri",
			"Aksaray",
			"Amasya",
			"Ankara",
			"Antalya",
			"Ardahan",
			"Artvin",
			"Aydin",
			"Balikesir",
			"Bartin",
			"Batman",
			"Bayburt",
			"Bilecik",
			"Bingol",
			"Bitlis",
			"Bolu",
			"Burdur",
			"Bursa",
			"Canakkale",
			"Cankiri",
			"Corum",
			"Denizli",
			"Diyarbakir",
			"Duzce",
			"Edirne",
			"Elazig",
			"Erzincan",
			"Erzurum",
			"Eskisehir",
			"Gaziantep",
			"Giresun",
			"Gumushane",
			"Hakkari",
			"Hatay",
			"Igdir",
			"Isparta",
			"Istanbul",
			"Izmir",
			"Kahramanmaras",
			"Karabuk",
			"Karaman",
			"Kars",
			"Kastamonu",
			"Kayseri",
			"Kilis",
			"Kirikkale",
			"Kirklareli",
			"Kirsehir",
			"Kocaeli",
			"Konya",
			"Kutahya",
			"Malatya",
			"Manisa",
			"Mardin",
			"Mersin",
			"Mugla",
			"Mus",
			"Nevsehir",
			"Nigde",
			"Ordu",
			"Osmaniye",
			"Rize",
			"Sakarya",
			"Samsun",
			"Siirt",
			"Sinop",
			"Sivas",
			"Sanliurfa",
			"Sirnak",
			"Tekirdag",
			"Tokat",
			"Trabzon",
			"Tunceli",
			"Usak",
			"Van",
			"Yalova",
			"Yozgat",
			"Zonguldak",
		],
	},
	{
		countryName: "Ukraine",
		cities: [
			"Kyiv",
			"Kharkiv",
			"Odessa",
			"Dnipro",
			"Lviv",
			"Donetsk",
			"Zaporizhia",
			"Kryvyi Rih",
			"Mykolaiv",
			"Mariupol",
			"Other",
		],
	},
	{
		countryName: "United Arab Emirates",
		cities: ["Dubai", "Abu Dhabi", "Sharjah", "Ajman", "Fujairah", "Umm Al-Quwain", "Ras Al Khaimah", "Other"],
	},
	{
		countryName: "United Kingdom",
		cities: [
			"London",
			"Manchester",
			"Birmingham",
			"Liverpool",
			"Leeds",
			"Newcastle",
			"Sheffield",
			"Bristol",
			"Nottingham",
			"Leicester",
			"Edinburgh",
			"Glasgow",
			"Aberdeen",
			"Dundee",
			"Inverness",
			"Stirling",
			"Perth",
			"Dumfries",
			"St Andrews",
			"Aberfeldy",
			"Cardiff",
			"Swansea",
			"Newport",
			"Bangor",
			"St Davids",
			"Wrexham",
			"Aberystwyth",
			"Llandudno",
			"Merthyr Tydfil",
			"Carmarthen",
			"Belfast",
			"Londonderry",
			"Newry",
			"Armagh",
			"Dungannon",
			"Enniskillen",
			"Lisburn",
			"Omagh",
			"Coleraine",
			"Ballymena",
			"Other",
		],
	},
	{
		countryName: "United States",
		cities: [
			"Alabama",
			"Alaska",
			"Arizona",
			"Arkansas",
			"California",
			"Colorado",
			"Connecticut",
			"Delaware",
			"Florida",
			"Georgia",
			"Hawaii",
			"Idaho",
			"Illinois",
			"Indiana",
			"Iowa",
			"Kansas",
			"Kentucky",
			"Louisiana",
			"Maine",
			"Maryland",
			"Massachusetts",
			"Michigan",
			"Minnesota",
			"Mississippi",
			"Missouri",
			"Montana",
			"Nebraska",
			"Nevada",
			"New Hampshire",
			"New Jersey",
			"New Mexico",
			"New York",
			"North Carolina",
			"North Dakota",
			"Ohio",
			"Oklahoma",
			"Oregon",
			"Pennsylvania",
			"Rhode Island",
			"South Carolina",
			"South Dakota",
			"Tennessee",
			"Texas",
			"Utah",
			"Vermont",
			"Virginia",
			"Washington",
			"West Virginia",
			"Wisconsin",
			"Wyoming",
		],
	},
	{
		countryName: "Vietnam",
		cities: [
			"Hanoi",
			"Ho Chi Minh City",
			"Da Nang",
			"Hai Phong",
			"Can Tho",
			"Bien Hoa",
			"Hue",
			"Nha Trang",
			"Vung Tau",
			"Dalat",
			"Other",
		],
	},
	{
		countryName: "Argentina",
		cities: [
			"Buenos Aires",
			"Cordoba",
			"Rosario",
			"Mendoza",
			"San Juan",
			"Mar del Plata",
			"Salta",
			"Santa Fe",
			"La Plata",
			"San Miguel de Tucuman",
			"Other",
		],
	},
	{
		countryName: "Brazil",
		cities: [
			"Sao Paulo",
			"Rio de Janeiro",
			"Brasilia",
			"Salvador",
			"Fortaleza",
			"Belo Horizonte",
			"Manaus",
			"Curitiba",
			"Recife",
			"Porto Alegre",
			"Other",
		],
	},
	{
		countryName: "Czech Republic",
		cities: [
			"Prague",
			"Brno",
			"Ostrava",
			"Plzen",
			"Liberec",
			"Olomouc",
			"Usti nad Labem",
			"Hradec Kralove",
			"Ceske Budejovice",
			"Pardubice",
			"Other",
		],
	},
	{
		countryName: "Azerbaijan",
		cities: [
			"Baku",
			"Ganja",
			"Sumgait",
			"Mingachevir",
			"Lankaran",
			"Shirvan",
			"Sheki",
			"Yevlakh",
			"Goychay",
			"Nakhchivan",
			"Other",
		],
	},
];
export const HelpCenterCategories: HelpCenterCategoryTypes[] = [
	"Account Related",
	"Bug Report",
	"Feature Request",
	"Custom Pricing",
	"Others",
];

export const TermsAndConditions = `<p>Talentify Pro Personal Data Processing Information Text</p>

<p>Dear User,</p>
<br />
<p>At Softwarify, we are committed to protecting the privacy and security of your personal data collected during the use of Talentify Pro. This Personal Data Protection and Processing Information Text aims to inform you about our data processing activities, including the methods of collecting your personal data, the purposes of use, and your rights.</p>
<br />
<p>Data Controller and Representative: Sefa Ilyas Oz - Softwarify - info@sefailyasoz.com</p>
<br />
<p>Collected Personal Data:</p>
<br />
<p>Identity Information: Such as name, surname. Contact Information: Such as email address. User Data: Such as username, password. Transaction Security Information: Such as IP address, session login and interaction records. Method of Collecting Personal Data: Your personal data are collected directly from you during your use of Talentify Pro (via user input, registration forms, cookies, etc.).</p>
<br />
<p>Purpose of Processing Personal Data: The personal data collected may be processed for:</p>
<br />
<p>Improving the user experience of our software, Managing our user support services, Fulfilling our legal obligations, Conducting marketing and advertising activities. Data Processing Duration: Your personal data are retained for the period necessary to fulfill the purpose for which they are processed and within the legal retention periods.</p>
<br />
<p>Transfer of Personal Data: Your personal data may be transferred to third parties in a limited and measured manner, within the scope of legal obligations or as required by the contracts with our service providers, to the extent necessary for our services.</p>
<br />
<p>Your Rights as a Personal Data Owner: Under the KVKK, you have the right to:</p>
<br />
<p>Learn whether your personal data is being processed, Request information if your personal data has been processed, Understand the purpose of data processing and whether data is used for intended purposes, Know the third parties in the country or abroad to whom personal data has been transferred, Request correction of personal data if it is processed incompletely or inaccurately, Request deletion or destruction of personal data under the conditions set forth in Article 7 of KVKK, Request notification of the operations made to third parties to whom the personal data has been transferred, Object to an outcome that arises from the analysis of personal data exclusively through automated systems, Claim compensation for the damages resulting from unlawful processing of personal data. You can submit your requests regarding these rights to Sefa &#304;lyas &Ouml;z via email at info@sefailyasoz.com.</p>
<br />
<p>Cookie Policy for Talentify Pro</p>
<br />
<p>At Softwarify, we believe in being clear and open about how we collect and use data related to you. In the spirit of transparency, this Cookie Policy provides detailed information about how and when we use cookies on Talentify Pro. Please note that this policy is part of our Privacy Policy and should be read along with it.</p>
<br />
<p>What is a Cookie? A cookie is a small piece of data (text file) that a website &ndash; when visited by a user &ndash; asks your browser to store on your device to remember information about you, such as your language preference or login information.</p>
<br />
<p>How We Use Cookies <br /> We use cookies to make Talentify Pro work efficiently and to improve the user experience. Cookies allow us to:</p>
<br />
<p>1. Recognize you when you return to our software <br /> 2. Remember your preferences and settings <br/> 3. Enable functionality and personalization <br/> 4.Analyze how our services are performing <br/> 5. Deliver advertising that is relevant to your interests.</p>
<br />
<p>Security Measures: We keep our services under protected and encrypted files and do not open them to the third party companies. User informations like password or card number is encrypted and no one has a chance to see them.</p>
<br />
<p>This information text is effective as of November 8th, 2023 and may be updated by Softwarify.</p>`;

export const TurkishLiraSalaryRange = [
	"5000-10000",
	"10000-15000",
	"15000-20000",
	"20000-30000",
	"30000-50000",
	"50000-70000",
	"70000-100000",
	"100000+",
];
export const EuroDollarSalaryRange = ["1000-1500", "1500-2000", "2000-3000", "3000-5000", "5000-10000"];

export const CancelationReasons = [
	"Not satisfied with the services that this product provides",
	"Too hard and/or complicated to use",
	"Doesn't have the features I'm looking for",
	"Too expensive for me / our company",
	"Other",
];
