import { IGeneralResponse, ILogin } from "../../Constants/types";
import axiosInstance from "../../Helpers/axiosClient";
const auth = "auth";
export const LoginAsync = async (body: ILogin) => {
	try {
		const response = await axiosInstance.post(auth + "/login", body);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.data.message,
		};
	}
};
export const GetUserByTokenService = async () => {
	try {
		const response = await axiosInstance.get(auth);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: null,
			message: error.response.data.message,
		};
	}
};
