import { ChangeEvent, createRef, useEffect, useMemo, useState } from "react";
import PricingItem from "../../Components/PricingItem/PricingItem";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { EmilCodeVerificationBody, SignUpUserWithCompanyBody, StripeType } from "../../Constants/types";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { showAlert } from "../../Redux/reducers/reducers";
import { APP_PATHS, CountriesWithCities, TermsAndConditions } from "../../Constants/constants";
import { getStripePricingList } from "../../Redux/actions/pricing.actions";
import { emailCodeVerification, signUpNewUserWithCompany } from "../../Redux/actions/user.actions";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import Loading from "../../Components/Loading/Loading";

const GetStartedPage = () => {
	const navigate = useNavigate();
	const { isLeaving, pricingList, loading, mailId, tempToken, visitorFrom } = useAppSelector((state) => state.global);
	const paymentLinkRef = createRef<HTMLAnchorElement>();
	const dispatch = useAppDispatch();
	const [showTermsAndCondition, setShowTermsAndCondition] = useState(false);
	const [selectedPackage, setSelectedPackage] = useState<StripeType | null>(null);
	const [confirmPassword, setConfirmPassword] = useState("");
	const [emailVerificationForm, setEmailVerificationForm] = useState<EmilCodeVerificationBody>();
	const [paymentLink, setPaymentLink] = useState<string | undefined>(undefined);
	const [redirectable, setRedirectable] = useState(false);
	const [formValues, setFormValues] = useState<SignUpUserWithCompanyBody>({
		fullName: "",
		email: "",
		password: "",
		companyName: "",
		companyAddress: "",
		numberOfUsers: 0,
		lookupkey: "",
		termsAndCondition: false,
		phoneNumber: "",
		city: "",
		country: "",
	});
	// const [creditCardForm, setCreditCardForm] = useState({
	// 	cardNumber: "",
	// 	validThru: "",
	// 	cardHolder: "",
	// 	cvv: "",
	// });

	useEffect(() => {
		pricingList.length === 0 && dispatch(getStripePricingList(visitorFrom || "usd"));
	}, []);

	const handleGoBack = () => {
		navigate(`/${APP_PATHS.SIGN_IN}`);
	};
	const handleRegisteration = () => {
		if (
			!formValues.companyName ||
			!formValues.email ||
			!formValues.password ||
			!formValues.country ||
			!formValues.city ||
			!formValues.phoneNumber ||
			!formValues.fullName
		) {
			dispatch(showAlert({ text: "Please fill all fields !", type: "error" }));
			return;
		}
		if (confirmPassword !== formValues.password) {
			dispatch(showAlert({ text: "Passwords do not match !", type: "error" }));
			return;
		}
		dispatch(signUpNewUserWithCompany(formValues));
	};

	useEffect(() => {
		if (tempToken) {
			paymentLinkRef.current?.click();
			setTimeout(() => {
				setRedirectable(true);
			}, 3000);
		}
	}, [tempToken]);

	const handleInputs = (event: ChangeEvent<HTMLInputElement>) => {
		setFormValues({ ...formValues, [event.currentTarget.name]: event.currentTarget.value });
	};

	const handleEmailVerification = () => {
		if (emailVerificationForm) dispatch(emailCodeVerification(emailVerificationForm));
		else dispatch(showAlert({ text: "Please enter the verification code", type: "error" }));
	};
	const passwordStrengthCheck = useMemo(() => {
		var strength = 0;
		strength += /[A-Z]+/.test(formValues.password) ? 1 : 0;
		strength += /[a-z]+/.test(formValues.password) ? 1 : 0;
		strength += /[0-9]+/.test(formValues.password) ? 1 : 0;
		strength += /[\W]+/.test(formValues.password) ? 1 : 0;
		let message = "";
		switch (strength) {
			case 1:
				message = "Too weak!";
				break;
			case 2:
				message = "That is better ???";
				break;
			case 3:
				message = "Almost..";
				break;
			case 4:
				message = "Now we're talking!";
				break;
			default:
				message = "";
				break;
		}
		return message;
	}, [formValues.password]);
	// const handleCreditCard = () => {
	// 	const dataToBeSent: CreateCreditCardType = {
	// 		companyId: companyId!,
	// 		userId: userId!,
	// 		pricingId: 2,
	// 		cardNumber: creditCardForm.cardNumber,
	// 		cardHolder: creditCardForm.cardHolder,
	// 		cvv: Number(creditCardForm.cvv),
	// 		validThru: creditCardForm.validThru,
	// 	};
	// 	dispatch(createCreditCard(dataToBeSent));
	// };

	if (loading) return <Loading />;
	return (
		<div className='flex flex-col items-center justify-center h-screen relative'>
			{showTermsAndCondition && (
				<div
					className='bg-black bg-opacity-30 flex items-center justify-center w-screen h-screen fixed z-[9999]'
					onClick={() => setShowTermsAndCondition(false)}>
					<div
						className='bg-white w-10/12 lg:w-1/2 text-center overflow-scroll h-1/2 p-5 rounded-md'
						dangerouslySetInnerHTML={{ __html: TermsAndConditions }}
					/>
				</div>
			)}
			{tempToken ? (
				<div className='max-w-sm slide-from-bottom min-w-fit flex flex-col p-10 items-center justify-center bg-white pricing-card-container rounded-lg '>
					<span>You're being redirected to the payment page, please wait...</span>
					{redirectable && (
						<span>
							If you're still not redirected, please
							<a href={paymentLink} target='_blank' className='font-bold underline mx-1 cursor-pointer'>
								{`click here`}
							</a>
						</span>
					)}
					<a href={paymentLink} target='_blank' ref={paymentLinkRef} style={{ display: "none" }} />
				</div>
			) : mailId ? (
				<div className='slide-from-bottom pricing-card-container p-fluid p-5 items-center flex flex-col rounded-lg'>
					<span>Please enter the verification code we sent to {formValues.email}</span>
					<div className='field my-5'>
						<label htmlFor='code' className='font-semibold'>
							Verification Code
						</label>
						<InputText
							name='code'
							id='code'
							className='ring-0 outline-none focus:border-black hover:border-black transition-all duration-300 ease-in-out border px-2 py-1'
							onChange={(e) => {
								setEmailVerificationForm({
									code: e.currentTarget.value,
									mail: formValues.email,
									mailId: mailId,
								});
							}}
						/>
					</div>
					<Button
						label={loading ? "Confirming..." : "Confirm"}
						loading={loading}
						onClick={handleEmailVerification}
						className='py-1 border border-green-600 text-green-600'
					/>
				</div>
			) : (
				<>
					<div
						onClick={handleGoBack}
						className='flex-row items-center lg:flex justify-center left-5 z-30 lg:left-10 top-16 lg:top-10 absolute'>
						<ArrowLeftIcon className='h-5 w-5 mr-2 cursor-pointer lg:h-8 lg:w-8' strokeWidth={2} />
					</div>

					<div className={`${isLeaving.leaving ? "slide-from-top-reverse" : "slide-from-top"} flex flex-row `}>
						<div className='lg:text-3xl text-xl mt-5 text-center m-auto font-semibold lg:mb-10'>
							Select a plan that suits your needs best and get started!
							<br />
							<i className='text-sm'>All plans come with 3 days free trial!</i>
						</div>
					</div>
					<div
						className={`relative${
							isLeaving.leaving ? "slide-from-bottom-reverse" : "slide-from-bottom"
						} w-11/12 overflow-scroll overflow-x-hidden mb-5 flex rounded-xl py-10 flex-col lg:flex-row gap-3 lg:gap-0 items-center justify-start ${
							pricingList.length > 1
								? "lg:justify-center lg:w-11/12"
								: "md:justify-start flex-col-reverse overflow-y-scroll  md:flex-row items-center justify-start md:w-10/12 lg:w-2/3 xl:w-1/2"
						} `}>
						{!selectedPackage ? (
							pricingList.map((pricing, index) => (
								<PricingItem
									key={index}
									pricing={pricing}
									onGetStarted={() => {
										setSelectedPackage(pricing);
										setFormValues({
											...formValues,
											numberOfUsers: Number(pricing.numberOfUsers),
											lookupkey: pricing.lookup_key,
										});
										setPaymentLink(pricing.paymentLink);
									}}
								/>
							))
						) : (
							<PricingItem key={100} pricing={selectedPackage} onGetStarted={() => {}} />
						)}
						{!selectedPackage && (
							<span className='lg:absolute lg:bottom-10 font-medium italic'>
								If you think our plans do not cover your needs,{" "}
								<Link
									className='underline font-semibold'
									to={`/${APP_PATHS.HELP_CENTER}?custom-pricing=true&topic=We-Need-Custom-Pricing`}>
									contact us
								</Link>{" "}
								for custom pricing!
							</span>
						)}
						{selectedPackage && (
							<div className='rounded-xl border px-5 py-7 border-black flex flex-row gap-5'>
								<div className='p-fluid'>
									<div className='field my-1'>
										<label htmlFor='fullName' className='font-semibold'>
											Name Surname
										</label>
										<InputText
											id='fullName'
											name='fullName'
											autoFocus
											className='ring-0 outline-none focus:border-black hover:border-black transition-all duration-300 ease-in-out border px-2 py-1'
											value={formValues.fullName}
											onChange={handleInputs}
										/>
									</div>
									<div className='field my-1'>
										<label htmlFor='email' className='font-semibold'>
											Email
										</label>
										<InputText
											id='email'
											type='email'
											name='email'
											className='ring-0 outline-none focus:border-black hover:border-black transition-all duration-300 ease-in-out border px-2 py-1'
											value={formValues.email}
											onChange={handleInputs}
										/>
									</div>
									<div className='field my-1'>
										<label htmlFor='positionClosingDate' className='font-semibold'>
											Birth Date
										</label>
										<Calendar
											inputClassName='ring-0 outline-none focus:border-black hover:border-black transition-all duration-300 ease-in-out border px-2 py-1'
											id='birthDate'
											name='birthDate'
											maxDate={new Date("01.01.2005")}
											value={formValues.birthDate ? new Date(formValues.birthDate) : undefined}
											onChange={(e) => setFormValues({ ...formValues, birthDate: e.value as Date })}
										/>
									</div>
									<div className='field my-1'>
										<label htmlFor='phoneNumber' className='font-semibold'>
											Phone Number
										</label>
										<InputText
											id='phoneNumber'
											name='phoneNumber'
											className='ring-0 outline-none focus:border-black hover:border-black transition-all duration-300 ease-in-out border px-2 py-1'
											value={formValues.phoneNumber}
											onChange={handleInputs}
										/>
									</div>
									<div className='field my-1'>
										<label htmlFor='password' className='font-semibold'>
											Password
										</label>
										<InputText
											id='password'
											type='password'
											name='password'
											className='ring-0 outline-none focus:border-black hover:border-black transition-all duration-300 ease-in-out border px-2 py-1'
											value={formValues.password}
											onChange={handleInputs}
										/>
										{passwordStrengthCheck && (
											<span className={`text-xs mr-auto mb-2 italic font-medium`}>{passwordStrengthCheck}</span>
										)}
									</div>

									<div className='field my-1'>
										<label htmlFor='confirmPassword' className='font-semibold'>
											Confirm Password
										</label>
										<InputText
											id='confirmPassword'
											type='Password'
											className='ring-0 outline-none focus:border-black hover:border-black transition-all duration-300 ease-in-out border px-2 py-1'
											value={confirmPassword}
											onChange={(e) => setConfirmPassword(e.currentTarget.value)}
										/>
									</div>
								</div>
								<div className='p-fluid'>
									<div className='field my-1'>
										<label htmlFor='companyName' className='font-semibold'>
											Company Name
										</label>
										<InputText
											id='companyName'
											name='companyName'
											className='ring-0 outline-none focus:border-black hover:border-black transition-all duration-300 ease-in-out border px-2 py-1'
											value={formValues.companyName}
											onChange={handleInputs}
										/>
									</div>
									<div className='field my-1'>
										<label htmlFor='country' className='font-semibold'>
											Country
										</label>
										<Dropdown
											value={formValues.country}
											onChange={(e) => setFormValues({ ...formValues, country: e.value })}
											options={CountriesWithCities.map((item) => item.countryName)}
											className='w-full h-9 items-center ring-0 outline-none border'
											id='country'
										/>
									</div>
									<div className='field my-1'>
										<label htmlFor='city' className='font-semibold'>
											City
										</label>
										<Dropdown
											value={formValues.city}
											onChange={(e) => setFormValues({ ...formValues, city: e.value })}
											options={CountriesWithCities.find((item) => item.countryName === formValues.country)?.cities}
											className='w-full h-9 items-center ring-0 outline-none border'
											id='city'
										/>
									</div>
									<div className='field my-1'>
										<label htmlFor='companyAddress' className='font-semibold'>
											Company Address
										</label>
										<InputText
											id='companyAddress'
											name='companyAddress'
											className='ring-0 outline-none focus:border-black hover:border-black transition-all duration-300 ease-in-out border px-2 py-1'
											value={formValues.companyAddress}
											onChange={handleInputs}
										/>
									</div>

									<div className='field flex flex-row items-center my-3 justify-between'>
										<label
											htmlFor='termsAndCondition'
											className='font-semibold'
											onClick={() => {
												setShowTermsAndCondition(true);
												setFormValues({ ...formValues, termsAndCondition: true });
											}}>
											Terms and Conditions
										</label>
										<Checkbox
											id='termsAndCondition'
											inputId='termsAndCondition'
											name='termsAndCondition'
											className='border rounded-md h-fit ring-0 outline-none'
											onChange={(e) => {
												setFormValues({ ...formValues, termsAndCondition: Boolean(e.target.checked) });
											}}
											checked={formValues.termsAndCondition}></Checkbox>
									</div>
									<Button
										label={loading ? "Signing Up..." : "Sign Up"}
										loading={loading}
										disabled={!formValues.termsAndCondition}
										onClick={handleRegisteration}
										className='py-1 border mt-9 border-green-600 text-green-600'
									/>
								</div>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default GetStartedPage;
