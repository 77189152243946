import { createRef, useEffect, useState } from "react";
import PageContainer from "../../Components/PageContainer/PageContainer";
import {
	CountriesWithCities,
	Currencies,
	Degrees,
	EmploymentTypes,
	EuroDollarSalaryRange,
	Experiences,
	TurkishLiraSalaryRange,
} from "../../Constants/constants";
import { showAlert } from "../../Redux/reducers/reducers";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { Editor } from "primereact/editor";
import BodyHeader from "../../Components/BodyHeader/BodyHeader";
import {
	createPosition,
	deletePosition,
	getPositionListByCompanyId,
	updatePosition,
} from "../../Redux/actions/position.actions";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { XMarkIcon, CheckIcon } from "@heroicons/react/24/outline";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { IPosition } from "../../Constants/types";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";
import { MultiSelect } from "primereact/multiselect";
import { generateString, replaceEnglishCharacters } from "../../Helpers/helperFunctions";

const initialFormValues: IPosition = {
	coverLetter: false,
	department: "",
	description: "",
	country: "",
	city: "",
	numberOfNeed: 1,
	position: "",
	positionClosingDate: new Date(new Date().setDate(new Date().getDate() + 7)),
	resume: true,
	salaryRange: "1000-1500",
	employmentType: "Full Time",
	workingStyle: "Remote",
	companyId: -1,
	salaryCurrency: "USD",
	education: "",
	experience: "",
	keywords: "",
	isActive: true,
};
const PositionsPage = () => {
	const { reFetch, user, openPositions, loading } = useAppSelector((state) => state.global);
	const [isDeleting, setIsDeleting] = useState({
		deleting: false,
		id: -1,
	});
	const [isDialogVisible, setIsDialogVisible] = useState(false);
	const [description, setDescription] = useState("");
	const otherCopyOptionRef = createRef<HTMLInputElement>();
	const [copyOption, setCopyOption] = useState<string | undefined>(undefined);
	const [formValues, setFormValues] = useState<IPosition>({ ...initialFormValues, companyId: user.companyId });
	const [isLinkCopierOpen, setIsLinkCopierOpen] = useState(-1);
	const [selectedEductions, setSelectedEductions] = useState<string[]>([]);
	const [selectedExperiences, setSelectedExperiences] = useState<string[]>([]);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getPositionListByCompanyId(user.company.id));
	}, []);

	useEffect(() => {
		if (reFetch) {
			dispatch(getPositionListByCompanyId(user.company.id));
			let vals = initialFormValues;
			vals = { ...vals, companyId: user.companyId };
			setSelectedEductions([]);
			setSelectedExperiences([]);
			setFormValues(vals);
			setIsDialogVisible(false);
		}
	}, [reFetch]);

	const handleDelete = (id: number) => {
		dispatch(deletePosition(id));
		setIsDeleting({ deleting: false, id: -1 });
	};
	const onCopyOptionClick = async (id: number, from: string) => {
		await navigator.clipboard.writeText(`${window.location.origin}/apply/${id}?from=${from}`);
		dispatch(
			showAlert({
				text: "Position application link copied!",
				type: "success",
			})
		);
		if (from !== "Other") {
			setIsLinkCopierOpen(-1);
		} else {
			setCopyOption("Other");
		}
	};
	const onOpenNewPosition = () => {
		if (user.company.pricing.activePositionCount === openPositions.filter((position) => position.isActive).length) {
			dispatch(
				showAlert({
					text: `You have reached to maximum active open position capacity`,
					type: "error",
				})
			);
			// TODO: allow user to upgrade package from Alert
			return;
		}
		if (user.company.pricing.totalPositionCount === openPositions.length) {
			dispatch(
				showAlert({
					text: `You have reached to maximum position capacity`,
					type: "error",
				})
			);
			return;
		}
		let vals = initialFormValues;
		vals = { ...vals, companyId: user.companyId };

		setFormValues(vals);
		setIsDialogVisible(true);
	};

	const onDialogSave = () => {
		if (isDeleting.deleting) {
			handleDelete(isDeleting.id);
		} else {
			const experience = selectedExperiences.join(", ");
			const education = selectedEductions.join(", ");
			let dataToBeSent = { ...formValues, experience, education, description };

			if (
				!dataToBeSent.position ||
				!dataToBeSent.city ||
				!dataToBeSent.country ||
				!description ||
				!dataToBeSent.education ||
				!dataToBeSent.experience
			) {
				dispatch(
					showAlert({
						text: `Please fill all required fields!`,
						type: "error",
					})
				);
				return;
			}
			if (formValues?.id) {
				dataToBeSent = { ...dataToBeSent, updatedAt: `${new Date()}` };
				dispatch(updatePosition(dataToBeSent));
			} else {
				dataToBeSent = { ...dataToBeSent, openedAt: `${new Date()}` };
				dispatch(createPosition(dataToBeSent));
			}
		}
	};

	useEffect(() => {
		let timeout = undefined as any;
		if (copyOption && copyOption !== "Other") {
			timeout = setTimeout(() => {
				let replaced = replaceEnglishCharacters(copyOption);
				navigator.clipboard.writeText(
					`${window.location.origin}/apply/${isDeleting.id}?from=${replaced.replace(" ", "")}`
				);
				dispatch(
					showAlert({
						text: "Position application link copied!",
						type: "success",
					})
				);
				setIsLinkCopierOpen(-1);
				setCopyOption(undefined);
				setIsDeleting({ deleting: false, id: -1 });
			}, 1000);
		}
		if (copyOption && copyOption === "Other") {
			otherCopyOptionRef.current?.focus();
		}
		return () => clearTimeout(timeout);
	}, [copyOption]);

	const actionBodyTemplate = (rowData: IPosition) => {
		const randomTarget = generateString(10);
		return rowData.isActive ? (
			<div className='flex flex-row items-center relative gap-2 justify-center'>
				<Button
					icon='pi pi-pencil'
					className='w-5 outline-none ring-0'
					severity='info'
					onClick={() => {
						if (rowData.isActive) {
							setFormValues(rowData);
							const _exp = rowData.experience ? rowData.experience.split(",") : [];
							const _edu = rowData.education ? rowData.education.split(",") : [];
							setSelectedExperiences(_exp);
							setSelectedEductions(_edu);
							setDescription(rowData.description);
						} else {
							setFormValues({ ...formValues, isActive: rowData.isActive });
						}
						setIsDialogVisible(true);
					}}
				/>
				<Button
					icon='pi pi-trash'
					className='w-5 outline-none ring-0'
					severity='danger'
					onClick={() => {
						setFormValues({ ...formValues, position: rowData.position });
						setIsDeleting({ deleting: true, id: rowData.id! });
					}}
				/>

				<Tooltip target={`.${randomTarget}`} className='text-sm bg-transparent' autoHide={false}>
					<div
						id='link-copier'
						className={` text-white flex flex-col items-center rounded-md ${
							copyOption === undefined ? "w-20" : "w-fit"
						} text-xs gap-2 transition-all duration-200 ease-in-out`}>
						<span
							id='link-copier'
							onClick={() => onCopyOptionClick(rowData.id!, "LinkedIn")}
							className='cursor-pointer hover:underline'>
							LinkedIn
						</span>
						<span
							id='link-copier'
							onClick={() => onCopyOptionClick(rowData.id!, "Indeed")}
							className='cursor-pointer hover:underline'>
							Indeed
						</span>
						<span
							id='link-copier'
							onClick={() => onCopyOptionClick(rowData.id!, "Glassdoor")}
							className='cursor-pointer hover:underline'>
							Glassdoor
						</span>
						<span
							id='link-copier'
							onClick={() => onCopyOptionClick(rowData.id!, "ZipRecruiter")}
							className='cursor-pointer hover:underline'>
							ZipRecruiter
						</span>
						<span
							id='link-copier'
							onClick={() => onCopyOptionClick(rowData.id!, "Kariyer")}
							className='cursor-pointer hover:underline'>
							Kariyer
						</span>

						<span
							id='link-copier'
							onClick={() => {
								onCopyOptionClick(rowData.id!, "Other");
								setIsDeleting({ deleting: false, id: rowData.id! });
							}}
							className='cursor-pointer hover:underline'>
							Other
						</span>
						{Boolean(copyOption) && (
							<div>
								<InputText
									ref={otherCopyOptionRef}
									placeholder='Specify Other (optional)'
									className='ring-0 outline-none border px-2 py-1 focus:border-primary text-black'
									onChange={(event) => setCopyOption(event.currentTarget.value)}
								/>
							</div>
						)}
					</div>
				</Tooltip>

				<Button
					icon='pi pi-link'
					className={`w-5 outline-none ring-0 ${randomTarget}`}
					data-pr-position='left'
					id='link-copier'
					severity='secondary'
					onClick={() => {
						isLinkCopierOpen === rowData.id ? setIsLinkCopierOpen(-1) : setIsLinkCopierOpen(rowData.id!);
					}}
				/>
			</div>
		) : (
			<></>
		);
	};
	const dialogFooter = () => (
		<div className='flex flex-row items-center gap-2 justify-between mt-5 md:mt-0'>
			<Button
				label='Cancel'
				loading={loading}
				disabled={loading}
				className='border border-red-600 text-red-600 p-1 outline-none ring-0'
				onClick={hideDialog}
			/>
			<Button
				label='Save'
				loading={loading}
				disabled={loading}
				className='border border-green-600 text-green-600 py-1 px-2 outline-none ring-0'
				onClick={onDialogSave}
			/>
		</div>
	);

	const hideDialog = () => {
		isDeleting.deleting ? setIsDeleting({ deleting: false, id: -1 }) : setIsDialogVisible(false);
		setTimeout(() => {
			let vals = initialFormValues;
			setSelectedExperiences([]);
			setSelectedEductions([]);
			setDescription("");
			vals = { ...vals, companyId: user.companyId };
			setFormValues(vals);
		}, 500);
	};

	return (
		<>
			<PageContainer pageTitle='Positions'>
				<BodyHeader
					leftItem={
						<span onClick={onOpenNewPosition} className='font-semibold cursor-pointer px-2 py-1 hover:underline'>
							Open New Position
						</span>
					}
				/>
				<div className='p-5 drop-shadow-md'>
					<DataTable
						showGridlines
						removableSort
						loading={loading}
						value={openPositions}
						stripedRows
						rowHover
						dataKey='id'>
						<Column
							field='position'
							header='Position'
							sortable
							headerClassName='bg-black text-white text-xs'
							className='text-sm w-max'
						/>
						<Column
							field='description'
							header='Description'
							className='text-sm w-[250px] overflow-scroll flex whitespace-nowrap no-scrollbar max-h-[60px]'
							headerClassName='bg-black text-white text-xs'
							body={(row) => (
								<div dangerouslySetInnerHTML={{ __html: row.description.substring(0, 100).concat("...") }} />
							)}
						/>
						<Column
							field='details'
							header='Details'
							headerClassName='bg-black text-white text-xs'
							className='text-sm w-[200px]  '
							body={(row) => (
								<div>
									{row.employmentType} | {row.workingStyle} 
								</div>
							)}
						/>
						<Column
							field='salaryRange'
							header='Salary'
							sortable
							className='text-sm w-max'
							headerClassName='bg-black text-white text-xs'
							body={(row) => (
								<div>
									{row.salaryRange} {row.salaryCurrency}
								</div>
							)}
						/>
						<Column
							field='numberOfNeed'
							header='Need'
							headerClassName='bg-black text-white text-xs'
							className='text-sm'
						/>
						<Column
							field='education'
							header='Education'
							headerClassName='bg-black text-white text-xs'
							className='text-sm w-[200px] overflow-scroll flex whitespace-nowrap'
						/>
						<Column
							field='experience'
							sortable
							header='Experience'
							headerClassName='bg-black text-white text-xs'
							className='text-sm'
						/>
						<Column
							field='country'
							header='Country City'
							headerClassName='bg-black text-white text-xs'
							className='text-sm'
							body={(row) => (
								<div>
									{row.country} / {row.city} 
								</div>
							)}
						/>
						<Column
							field='openedAt'
							headerClassName='bg-black text-white text-xs'
							header='Opened'
							className='text-sm'
							sortable
							body={(row) => <div>{moment(row.openedAt).format("DD.MM.YYYY")}</div>}
						/>
						<Column
							field='positionClosingDate'
							header='Closing'
							sortable
							headerClassName='bg-black text-white text-xs'
							className='text-sm'
							body={(row) => <div>{moment(row.positionClosingDate).format("DD.MM.YYYY")}</div>}
						/>
						<Column
							field='isActive'
							className='text-sm'
							headerClassName='bg-black text-white text-xs'
							header='Active'
							style={{ maxWidth: 50 }}
							body={(row) =>
								row.isActive ? (
									<CheckIcon width={18} height={18} strokeWidth={4} />
								) : (
									<XMarkIcon width={18} height={18} strokeWidth={4} />
								)
							}
						/>
						<Column
							body={actionBodyTemplate}
							className='text-sm'
							headerClassName='bg-black text-white text-sm w-[100px]'></Column>
					</DataTable>
				</div>
			</PageContainer>
			<Dialog
				visible={isDialogVisible}
				style={{ width: "32rem" }}
				closeOnEscape
				breakpoints={{ "960px": "75vw", "641px": "90vw" }}
				header={formValues?.id ? "Update Position " : "Open New Position"}
				modal
				className='p-fluid'
				footer={dialogFooter}
				onHide={hideDialog}>
				<div className='field my-1'>
					<label htmlFor='position' className='font-semibold'>
						Position *
					</label>
					<InputText
						id='position'
						className='ring-0 outline-none border px-2 py-1 focus:border-primary'
						value={formValues.position}
						onChange={(e) => setFormValues({ ...formValues, position: e.currentTarget.value })}
						autoFocus
					/>
				</div>
				<div className='field my-1'>
					<label htmlFor='department' className='font-semibold'>
						Department
					</label>
					<InputText
						id='department'
						className='ring-0 outline-none border px-2 py-1 focus:border-primary'
						value={formValues.department}
						onChange={(e) => setFormValues({ ...formValues, department: e.currentTarget.value })}
					/>
				</div>
				<div className='field my-1'>
					<label htmlFor='description' className='font-semibold'>
						Description *
					</label>
					<Editor
						value={description}
						onTextChange={(e) => setDescription(`${e.htmlValue}`)}
						style={{ height: "320px" }}
					/>
				</div>

				<div className='field my-1'>
					<label htmlFor='description' className='font-semibold'>
						Education *
					</label>
					<MultiSelect
						value={selectedEductions}
						onChange={(e) => {
							setSelectedEductions(e.value);
						}}
						options={Degrees}
						className='ring-0 outline-none border px-2 my-1 focus:border-primary'
						itemClassName='ring-0 outline-none border'
					/>
				</div>
				<div className='field my-1'>
					<label htmlFor='description' className='font-semibold'>
						Experience *
					</label>
					<MultiSelect
						value={selectedExperiences}
						onChange={(e) => {
							setSelectedExperiences(e.value);
						}}
						options={Experiences}
						className='ring-0 outline-none border px-2 my-1 focus:border-primary'
						itemClassName='ring-0 outline-none border'
					/>
				</div>
				<div className='field my-1'>
					<label htmlFor='country' className='font-semibold'>
						Country *
					</label>
					<Dropdown
						value={formValues.country}
						onChange={(e) => setFormValues({ ...formValues, country: e.value })}
						options={CountriesWithCities.sort((a: any, b: any) => a.countryName - b.countryName).map(
							(item) => item.countryName
						)}
						className='ring-0 outline-none border px-2 my-1 focus:border-primary'
						id='country'
					/>
				</div>
				<div className='field my-1'>
					<label htmlFor='city' className='font-semibold'>
						City *
					</label>
					<Dropdown
						value={formValues.city}
						onChange={(e) => setFormValues({ ...formValues, city: e.value })}
						options={CountriesWithCities.find((item) => item.countryName === formValues.country)?.cities.sort()}
						className='ring-0 outline-none border px-2 my-1 focus:border-primary'
						id='city'
					/>
				</div>
				<div className='field my-1'>
					<label htmlFor='positionClosingDate' className='font-semibold'>
						Position Closing Date
					</label>
					<Calendar
						inputClassName='ring-0 outline-none border px-2 py-1 focus:border-primary'
						id='positionClosingDate'
						value={formValues.positionClosingDate}
						minDate={new Date(new Date().setDate(new Date().getDate() + 7))}
						dateFormat='dd/mm/yy'
						onChange={(e) => setFormValues({ ...formValues, positionClosingDate: e.value as Date })}
					/>
				</div>
				<div className='field my-1'>
					<label htmlFor='employmentType' className='font-semibold'>
						Employment Type
					</label>
					<Dropdown
						value={formValues.employmentType}
						onChange={(e) => setFormValues({ ...formValues, employmentType: e.value })}
						options={EmploymentTypes}
						placeholder='Select an Employment Type'
						className='w-full md:w-14rem ring-0 outline-none border focus:border-primary'
						id='employmentType'
					/>
				</div>
				<div className='field my-1'>
					<label htmlFor='workingStyle' className='font-semibold'>
						Working Style
					</label>
					<Dropdown
						value={formValues.workingStyle}
						onChange={(e) => setFormValues({ ...formValues, workingStyle: e.value })}
						options={["On Site", "Hybrid", "Remote"]}
						placeholder='Select a Working Style'
						className='w-full md:w-14rem ring-0 outline-none border focus:border-primary'
						id='workingStyle'
					/>
				</div>
				<div className='field my-1 '>
					<label htmlFor='salaryCurrency' className='font-semibold'>
						Salary Currency
					</label>
					<Dropdown
						value={formValues.salaryCurrency}
						onChange={(e) => {
							if (e.value === "Turkish Lira") {
								setFormValues({ ...formValues, salaryCurrency: e.value, salaryRange: TurkishLiraSalaryRange[0] });
							} else {
								setFormValues({ ...formValues, salaryCurrency: e.value, salaryRange: EuroDollarSalaryRange[0] });
							}
						}}
						options={Currencies}
						placeholder='Select a Salary Currency'
						className='w-full md:w-14rem ring-0 outline-none border focus:border-primary'
						id='salaryCurrency'
					/>
				</div>
				<div className='field my-1 '>
					<label htmlFor='salaryRange' className='font-semibold'>
						Salary Range
					</label>
					<Dropdown
						value={formValues.salaryRange}
						onChange={(e) => setFormValues({ ...formValues, salaryRange: e.value })}
						options={formValues.salaryCurrency === "Turkish Lira" ? TurkishLiraSalaryRange : EuroDollarSalaryRange}
						placeholder='Select a Salary Range'
						className='w-full md:w-14rem ring-0 outline-none border focus:border-primary'
						id='salaryRange'
					/>
				</div>

				<div className='field my-1'>
					<label htmlFor='keywords' className='font-semibold'>
						Keywords
					</label>
					<InputText
						id='keywords'
						className='ring-0 outline-none border px-2 py-1 focus:border-primary'
						placeholder='separate your keywords with a comma'
						value={formValues.keywords}
						onChange={(e) => setFormValues({ ...formValues, keywords: e.currentTarget.value })}
					/>
				</div>
				<div className='field my-1 '>
					<label htmlFor='numberOfNeed' className='font-semibold'>
						Number of Need
					</label>
					<InputNumber
						id='numberOfNeed'
						inputClassName='ring-0 outline-none border px-2 py-1 rounded-md focus:border-primary'
						value={formValues.numberOfNeed}
						onChange={(e) => setFormValues({ ...formValues, numberOfNeed: e.value ?? 0 })}
					/>
				</div>
				<div className='field flex flex-row items-center mt-5 justify-between'>
					<label htmlFor='coverLetter' className='font-semibold'>
						Ask for a Cover Letter
					</label>
					<Checkbox
						id='coverLetter'
						name='coverLetter'
						inputId='coverLetter'
						className={`border focus:border-primary ${formValues?.coverLetter ? "" : "border-black"} rounded-md h-fit`}
						onChange={(e) => setFormValues({ ...formValues!, coverLetter: e.checked })}
						checked={Boolean(formValues?.coverLetter)}></Checkbox>
				</div>
				{Boolean(formValues.openedAt) && (
					<div className='field flex flex-row items-center mt-5 justify-between'>
						<label htmlFor='isActive' className='font-semibold'>
							Close Position
						</label>
						<Checkbox
							id='isActive'
							name='isActive'
							inputId='isActive'
							className={`border ${!formValues?.isActive ? "" : "border-black"} rounded-md h-fit`}
							onChange={(e) => setFormValues({ ...formValues!, isActive: !formValues?.isActive })}
							checked={Boolean(!formValues?.isActive)}></Checkbox>
					</div>
				)}
				{/* {Boolean(!formValues?.isActive) && (
					<small className='text-red-500'>
						If you close a position, you cannot re-open it by editing, a new one needs to be created
					</small>
				)} */}
			</Dialog>

			<Dialog
				visible={isDeleting.deleting}
				closeOnEscape
				style={{ width: "32rem" }}
				breakpoints={{ "960px": "75vw", "641px": "90vw" }}
				header='Confirm'
				modal
				footer={dialogFooter}
				onHide={hideDialog}>
				<div className='confirmation-content'>
					<i className='pi pi-exclamation-triangle mr-3' style={{ fontSize: "2rem" }} />
					<span>
						Are you sure you want to delete <b>{formValues.position}</b>?
					</span>
				</div>
			</Dialog>
		</>
	);
};

export default PositionsPage;
