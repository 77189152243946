import { IGeneralResponse, ICompany } from "../../Constants/types";
import axiosInstance from "../../Helpers/axiosClient";
const company = "company";
export const GetCompanyListService = async () => {
	try {
		const response = await axiosInstance.get(company);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.data.message,
		};
	}
};

export const CreateCompanyService = async (body: any) => {
	try {
		const response = await axiosInstance.post(company, body);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.data.message,
		};
	}
};

export const UpdateCompanyService = async (body: ICompany) => {
	try {
		const response = await axiosInstance.put(`${company}/${body.id}`, body);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.data.message,
		};
	}
};
