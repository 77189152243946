import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { CreatePricingService } from "../../Redux/services/pricing.service";
type Props = {};

const LandingPage = (props: Props) => {
	const navigate = useNavigate();
	const [isLeaving, setIsLeaving] = useState<"sign-in" | "get-started" | null>(null);
	useEffect(() => {
		if (isLeaving) {
			setTimeout(() => {
				navigate(`/${isLeaving}`);
			}, 350);
		}
	}, [isLeaving]);

	return (
		<div className='flex flex-col relative lg:flex-row items-center h-screen lg:items-start justify-between lg:w-10/12 w-full mx-auto'>
			<div className='flex flex-col text-center md:text-start mt-5 md:mt-0 font-semibold justify-evenly h-full'>
				<div className={`mb-5 md:mb-0 flex flex-col ${isLeaving ? "slide-from-top-reverse" : "slide-from-top"}`}>
					<div>
						<span className='text-6xl lg:text-8xl'>Talentify</span>
						<span className='font-semibold text-[16px] lg:text-[20px]'>PRO</span>
					</div>
					<span className='lg:ml-7 mt-1 text-md text-gray-600'>Managing hiring process never been easier!</span>
				</div>
				<div
					className={`flex flex-col text-white rounded-xl mx-2 lg:mx-0 text-start w-fit ${
						isLeaving ? "slide-from-bottom-reverse" : "slide-from-bottom"
					} app-info bg-gradient-to-r from-black to-gray-500 px-5 py-10`}>
					<div className='flex flex-row items-center my-2'>
						<ArrowRightCircleIcon className='w-8 h-8 mr-2' />
						<span className='flex-1'>Talentify Pro aims to centralize the hiring process in one single platform.</span>
					</div>
					<div className='flex flex-row items-center my-2'>
						<ArrowRightCircleIcon className='w-8 h-8 mr-2' />
						<span className='flex-1'>Open a new position on Talentify Pro and copy the application link.</span>
					</div>
					<div className='flex flex-row items-center my-2'>
						<ArrowRightCircleIcon className='w-8 h-8 mr-2' />
						<span className='flex-1'>Use this link to redirect applicants from other job posting platforms.</span>
					</div>
					<div className='flex flex-row items-center my-2'>
						<ArrowRightCircleIcon className='w-8 h-8 mr-2' />
						<span className='flex-1'>You will receive every single application here on Talentify Pro!</span>
					</div>
					<div className='flex flex-row items-center my-2'>
						<ArrowRightCircleIcon className='w-8 h-8 mr-2' />
						<span className='flex-1'>
							Say good bye to complicated excel sheets and trying to manage applications from different places!
						</span>
					</div>
				</div>
			</div>
			<button
				onClick={() => {
					setIsLeaving("sign-in");
				}}
				className={`font-semibold  ${
					isLeaving ? "slide-from-top-reverse" : "slide-from-top"
				} md:right-10 md:top-5 z-20 bg-primary-darker hover:bg-primary md:absolute lg:mt-5 mt-10 trans-all drop-shadow-xl text-white rounded-xl py-2 lg:px-6 w-11/12 lg:w-auto cursor-pointer text-center`}>
				Sign In
			</button>
			<div
				className={`flex flex-col items-center  justify-center mt-10 ${
					isLeaving ? "slide-from-right-reverse" : "slide-from-right"
				}`}>
				<Lottie animationData={require("../../Assets/Animations/AdminWorking.json")} loop={true} />
				{/* <button
					className={`font-semibold mb-10 rounded-xl py-2 w-full md:w-1/2 get-started`}
					onClick={() => {
						setIsLeaving("get-started");
					}}>
					Get Started
				</button> */}
				<button
					onClick={() => {
						setIsLeaving("get-started");
					}}
					className='inline-flex mb-10 h-12 animate-shimmer items-center justify-center rounded-xl border border-slate-800 bg-[linear-gradient(110deg,#000103,45%,#4f5a6e,55%,#000103)] bg-[length:200%_100%] w-11/12 font-medium text-white transition-colors focus:outline-none'>
					Get Started
				</button>
			</div>
		</div>
	);
};

export default LandingPage;
