import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../../Redux/store/store";
import SideBar from "../Navbar/SideBar";

type Props = {};

const AdminRouteProtecter = (props: Props) => {
	const location = useLocation();
	const { isAuthenticated, user } = useAppSelector((state) => state.global);

	return isAuthenticated && user?.isSuperAdmin ? (
		<div className='flex flex-row my-auto min-h-screen'>
			<SideBar />
			<Outlet />
		</div>
	) : (
		<Navigate to={"/sign-in"} state={{ from: location }} />
	);
};

export default AdminRouteProtecter;
