import { useAppDispatch, useAppSelector } from "../../../Redux/store/store";
import PageContainer from "../../../Components/PageContainer/PageContainer";
import BodyHeader from "../../../Components/BodyHeader/BodyHeader";
import { useEffect } from "react";
import { getSuperAdminDashboard } from "../../../Redux/actions/dashboard.actions";
import DashboardBox from "../../../Components/DashboardBox/DashboardBox";
import Icon from "../../../Components/Icon/Icon";

type Props = {};

const AdminDashboardPage = (props: Props) => {
	const dispatch = useAppDispatch();
	const { user, adminDashboardData } = useAppSelector((state) => state.global);

	useEffect(() => {
		dispatch(getSuperAdminDashboard());
	}, []);

	return (
		<>
			<PageContainer pageTitle='Dashboard'>
				<BodyHeader
					// leftItem={`something goes here`}
					rightItem={`Welcome, ${user.fullName}`}
				/>
				<div className='flex w-full pt-1 xl:justify-around md:justify-start flex-col overflow-scroll '>
					<div className='flex flex-col md:flex-row items-center justify-evenly flex-wrap'>
						<DashboardBox
							title='Users'
							values={[{ field: "Total", value: adminDashboardData?.totalUsers || 0 }]}
							icon={<Icon iconName={"users"} />}
							path=''
						/>
						<DashboardBox
							title='Position'
							values={[
								{ field: "Total", value: adminDashboardData?.totalPositions || 0 },
								{ field: "Active", value: adminDashboardData?.totalActivePositions || 0 },
							]}
							icon={<Icon iconName={"open-position"} />}
							path=''
						/>
						<DashboardBox
							title='Companies'
							values={[{ field: "Total", value: adminDashboardData?.totalCompanies || 0 }]}
							icon={<Icon iconName={"companies"} />}
							className='flex'
							path=''
						/>
					</div>
				</div>
			</PageContainer>
		</>
	);
};

export default AdminDashboardPage;
