import { IGeneralResponse } from "../../Constants/types";
import axiosInstance from "../../Helpers/axiosClient";
const dashboard = "dashboard";
export const GetDashboardDataService = async (email: string) => {
	try {
		const response = await axiosInstance.get(`${dashboard}?email=${email}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.data.message,
		};
	}
};

export const GetSuperAdminDashboardService = async () => {
	try {
		const response = await axiosInstance.get(`${dashboard}/super-admin`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.data.message,
		};
	}
};
