import { IPosition } from "../../Constants/types";
import moment from "moment";
import { Link } from "react-router-dom";

type Props = {
	item: IPosition;
	onViewDetails: (item: IPosition) => void;
};
// TODO
// 29.12.2023 te kapanması gereken pozisyon 03.12.2023 te kapanmış görünüyor!!

const JobPostItem = ({ item, onViewDetails }: Props) => {
	const ViewDetails = () => {
		onViewDetails(item);
	};

	return (
		<div className='h-[250px] lg:w-[325px] w-[350px] border flex flex-col rounded-xl p-2 bg-white drop-shadow-md'>
			<div className='flex flex-row justify-between'>
				{/* {item.icon ? <img src="item.icon" /> : <i></i>} */}
				<div className='flex flex-row items-end gap-1'>
					<i className='pi pi-building text-3xl'></i>
					<span className='font-semibold'>{item.company?.companyName}</span>
				</div>
				<span className='font-medium italic text-xs'>{moment(item.openedAt).format("MMMM Do YYYY")}</span>
			</div>
			<div className='flex flex-row justify-between items-center'>
				<span className='font-semibold text-lg'>{item.position}</span>
				{/* <span className='italic text-xs'>10 Applications</span> */}
			</div>
			<div className='flex flex-row items-center flex-wrap gap-1 my-1'>
				<span className='border px-1.5 py-0.5 rounded-lg bg-blue-400 text-white text-xs font-semibold'>
					{item.workingStyle}
				</span>
				<span className='border px-1.5 py-0.5 rounded-lg bg-blue-400 text-white text-xs font-semibold'>
					{item.employmentType}
				</span>
				{item.experience.split(",").map((exp, index) => (
					<span className='border px-1.5 py-0.5 rounded-lg bg-blue-400 text-white text-xs font-semibold' key={index}>
						{exp}
					</span>
				))}
				{item.education.split(",").map((edu, index) => (
					<span
						className='border px-1.5 py-0.5 rounded-lg w-fit bg-blue-400 text-white text-xs font-semibold'
						key={index}>
						{edu}
					</span>
				))}
				{item.city && (
					<span className='border px-1.5 py-0.5 rounded-lg bg-blue-400 text-white text-xs font-semibold'>
						{item.city}
					</span>
				)}
				{item.country && (
					<span className='border px-1.5 py-0.5 rounded-lg bg-blue-400 text-white text-xs font-semibold'>
						{item.country}
					</span>
				)}
				<span className='border px-1.5 py-0.5 rounded-lg bg-blue-400 text-white text-xs font-semibold'>
					{item.salaryRange}
				</span>
				<span className='border px-1.5 py-0.5 rounded-lg bg-blue-400 text-white text-xs font-semibold'>
					{item.salaryCurrency}
				</span>
			</div>

			<div className='flex flex-row items-center justify-between mt-auto'>
				<span
					className='border transition-all cursor-pointer duration-300 ease-in-out font-semibold rounded-md px-1 py-0.5 hover:bg-primary-darker bg-primary text-white'
					onClick={ViewDetails}>
					View Details
				</span>
				<Link
					target='_blank'
					to={`/apply/${item.id}?from=Talentify`}
					className='border transition-all duration-300 ease-in-out font-semibold rounded-md px-1.5 py-0.5 text-primary bg-white border-primary hover:bg-primary hover:text-white'>
					Apply Now
				</Link>
			</div>
		</div>
	);
};

export default JobPostItem;
