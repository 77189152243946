import { CheckIcon } from "@heroicons/react/24/outline";
import {
	UserIcon,
	DocumentIcon,
	PhoneIcon,
	BriefcaseIcon,
	Cog6ToothIcon,
	BuildingLibraryIcon,
	ChatBubbleLeftIcon,
	DocumentTextIcon,
	BuildingOfficeIcon,
	UsersIcon,
} from "@heroicons/react/24/solid";
import React from "react";

type Props = {
	iconName: string;
	className?: string;
};

const Icon: React.FC<Props> = React.memo(({ iconName, className }) => {
	if (iconName === "user") return <UserIcon className={`w-5 h-5 ${className}`} />;
	else if (iconName === "document") return <DocumentIcon className={`w-5 h-5 ${className}`} />;
	else if (iconName === "meeting") return <PhoneIcon className={`w-5 h-5 ${className}`} />;
	else if (iconName === "open-position") return <BriefcaseIcon className={`w-5 h-5 ${className}`} />;
	else if (iconName === "settings") return <Cog6ToothIcon className={`w-5 h-5 ${className}`} />;
	else if (iconName === "dashboard") return <BuildingLibraryIcon className={`w-5 h-5 ${className}`} />;
	else if (iconName === "help-center") return <ChatBubbleLeftIcon className={`w-5 h-5 ${className}`} />;
	else if (iconName === "logs") return <DocumentTextIcon className={`w-5 h-5 ${className}`} />;
	else if (iconName === "companies") return <BuildingOfficeIcon className={`w-5 h-5 ${className}`} />;
	else if (iconName === "users") return <UsersIcon className={`w-5 h-5 ${className}`} />;
	else return <CheckIcon className={`w-5 h-5 ${className}`} strokeWidth={3} />;
});

export default Icon;
