import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { getActivePositions } from "../../Redux/actions/position.actions";
import { Calendar } from "primereact/calendar";
import { IPosition, JobFilterValues } from "../../Constants/types";
import { MultiSelect } from "primereact/multiselect";
import {
	CountriesWithCities,
	Currencies,
	EmploymentTypes,
	EuroDollarSalaryRange,
	TurkishLiraSalaryRange,
	WorkingStyles,
} from "../../Constants/constants";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import JobPostItem from "../../Components/JobPostItem/JobPostItem";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import moment from "moment";
const intervalTiming = 500;
const JobsPage = () => {
	const { activePositions, loading } = useAppSelector((state) => state.global);
	const dispatch = useAppDispatch();
	const [cities, setCities] = useState([]);
	const [positionsData, setPositionsData] = useState<IPosition[]>([]);
	const [selectedJobDetails, setSelectedJobDetails] = useState<IPosition>();
	const [filterValues, setFilterValues] = useState<JobFilterValues | undefined>();
	useEffect(() => {
		dispatch(getActivePositions());
	}, []);
	const onClearFilters = () => setFilterValues(undefined);

	const hideDialog = () => {
		setSelectedJobDetails(undefined);
	};
	const handleFiltering = () => {
		let _activePositions = activePositions;
		if (typeof filterValues?.searchValue === "string") {
			_activePositions = _activePositions?.filter(
				(position) =>
					position.position.toLowerCase().includes(filterValues?.searchValue!) ||
					position.company?.companyName.toLowerCase().includes(filterValues?.searchValue!)
			);
		}
		if (filterValues?.country && filterValues?.country.length > 0) {
			_activePositions = _activePositions?.filter((position) => filterValues?.country?.includes(position.country));
		}
		if (filterValues?.city && filterValues?.city.length > 0) {
			_activePositions = _activePositions?.filter((position) => filterValues?.city?.includes(position.city));
		}
		if (filterValues?.salaryRange && filterValues?.salaryRange.length > 0) {
			_activePositions = _activePositions?.filter((position) =>
				filterValues?.salaryRange?.includes(position.salaryRange)
			);
		}
		if (filterValues?.salaryCurrency) {
			_activePositions = _activePositions?.filter(
				(position) => filterValues?.salaryCurrency === position.salaryCurrency
			);
		}
		if (filterValues?.workingStyle && filterValues?.workingStyle.length > 0) {
			_activePositions = _activePositions?.filter((position) =>
				filterValues?.workingStyle?.includes(position.workingStyle)
			);
		}
		if (filterValues?.employmentType && filterValues?.employmentType.length > 0) {
			_activePositions = _activePositions?.filter((position) =>
				filterValues?.employmentType?.includes(position.employmentType)
			);
		}
		if (Boolean(filterValues?.date)) {
			_activePositions = _activePositions?.filter(
				(position) => new Date(filterValues?.date!).getTime() < new Date(position.openedAt as any).getTime()
			);
		}

		setPositionsData(_activePositions!);
	};
	useEffect(() => {
		if (activePositions) {
			setPositionsData(activePositions);
		}
	}, [activePositions]);

	useEffect(() => {
		let interval = undefined as any;
		if (filterValues) {
			interval = setTimeout(() => handleFiltering(), intervalTiming);
		}
		return () => {
			clearTimeout(interval);
		};
	}, [filterValues]);

	useEffect(() => {
		let _cities = [] as any[];
		filterValues?.country?.forEach((element) => {
			let temp = CountriesWithCities.find((item) => item.countryName === element)?.cities;

			_cities = [..._cities, ...temp!];
		});
		let unique = [...new Set(_cities)];

		setCities(unique.sort() as never[]);
	}, [filterValues?.country]);

	return (
		<>
			<div className='flex flex-row justify-between gap-3 bg-gray-300 border-2 h-screen w-screen'>
				<div className='bg-white drop-shadow-md flex-col border py-2 px-3 my-3 mx-1 rounded-xl lg:w-[300px] w-[200px]'>
					<div className={`trans-all cursor-pointer tracking-wider text-center font-bold text-xl`}>Talentify</div>
					<hr className='my-2' />
					<div className='flex flex-row items-center w-full justify-between'>
						<span className='font-semibold text-lg'>Filter</span>
						<span className='text-red-500 text-sm hover:underline cursor-pointer' onClick={onClearFilters}>
							Clear Filters
						</span>
					</div>
					<hr className='my-2' />
					<div className='flex flex-col w-full my-2'>
						<label className='font-semibold' htmlFor='date'>
							Date of Posted
						</label>
						<Calendar
							value={filterValues?.date}
							dateFormat='dd.mm.yy'
							inputClassName='ring-0 outline-none border px-2 py-2.5'
							clearButtonClassName='bg-black'
							onChange={(e) => {
								setFilterValues({ ...filterValues, date: e.value });
							}}
						/>
					</div>
					<div className='flex flex-col w-full my-2'>
						<label htmlFor='employmentType' className='font-semibold'>
							Employment Type
						</label>
						<MultiSelect
							value={filterValues?.employmentType}
							onChange={(e) => setFilterValues({ ...filterValues, employmentType: e.value })}
							options={EmploymentTypes}
							className='ring-0 outline-none border px-2'
							itemClassName='ring-0 outline-none border'
							id='employmentType'
							showClear
						/>
					</div>
					<div className='flex flex-col w-full my-2'>
						<label className='font-semibold' htmlFor='date'>
							Working Style
						</label>
						<MultiSelect
							value={filterValues?.workingStyle}
							onChange={(e) => {
								setFilterValues({ ...filterValues, workingStyle: e.value });
							}}
							options={WorkingStyles}
							showClear
							className='ring-0 outline-none border px-2'
							itemClassName='ring-0 outline-none border'
						/>
					</div>
					<div className='flex flex-col w-full my-2'>
						<label className='font-semibold' htmlFor='date'>
							Salary Currency
						</label>
						<Dropdown
							value={filterValues?.salaryCurrency}
							onChange={(e) => {
								setFilterValues({ ...filterValues, salaryCurrency: e.value, salaryRange: undefined });
							}}
							options={Currencies}
							showClear
							className='ring-0 outline-none border px-2 '
						/>
					</div>
					<div className='flex flex-col w-full my-2'>
						<label className='font-semibold' htmlFor='date'>
							Salary Range
						</label>
						<MultiSelect
							value={filterValues?.salaryRange}
							onChange={(e) => {
								setFilterValues({ ...filterValues, salaryRange: e.value });
							}}
							options={filterValues?.salaryCurrency === "Turkish Lira" ? TurkishLiraSalaryRange : EuroDollarSalaryRange}
							className='ring-0 outline-none border px-2 '
							showClear
							itemClassName='ring-0 outline-none border'
						/>
					</div>
					<div className='flex flex-col w-full my-2'>
						<label className='font-semibold' htmlFor='date'>
							Location
						</label>
						<MultiSelect
							value={filterValues?.country}
							onChange={(e) => {
								setFilterValues({ ...filterValues, country: e.value });
							}}
							options={CountriesWithCities.map((item) => item.countryName)}
							placeholder='Select a country'
							className='ring-0 outline-none border px-2 my-1'
							itemClassName='ring-0 outline-none border'
							showClear
						/>
						<MultiSelect
							value={filterValues?.city}
							onChange={(e) => {
								setFilterValues({ ...filterValues, city: e.value });
							}}
							options={cities}
							className='ring-0 outline-none border px-2 my-1'
							placeholder='Select a city'
							itemClassName='ring-0 outline-none border'
							showClear
						/>
					</div>
				</div>
				<div className='flex flex-col justify-between gap-2 my-3 mx-1 rounded-xl flex-1 '>
					<div className='flex flex-row items-center bg-white drop-shadow-md rounded-xl py-2 px-3 border justify-between'>
						<div className='border px-2 py-1 border-primary-lighter flex flex-row items-center gap-2 rounded-md'>
							<i className='pi pi-search text-gray-500'></i>
							<InputText
								className='ring-0 outline-none w-[300px]'
								placeholder='Search position or company'
								onChange={(event) => {
									setFilterValues({ ...filterValues, searchValue: event.currentTarget.value || "" });
								}}
							/>
						</div>
					</div>
					<div
						className={`flex flex-row flex-wrap rounded-xl relative  overflow-scroll drop-shadow-md mb-auto py-2 gap-4 ${
							loading ? "flex-1" : ""
						}`}>
						{loading ? (
							<i className='pi pi-spinner absolute text-3xl left-1/2 top-1/2 animate-spin'></i>
						) : (
							positionsData?.map((position, index) => (
								<JobPostItem
									item={position}
									key={index}
									onViewDetails={(item) => {
										setSelectedJobDetails(item);
									}}
								/>
							))
						)}
					</div>
				</div>
			</div>
			<Dialog
				visible={Boolean(selectedJobDetails)}
				style={{ width: "32rem" }}
				closeOnEscape
				breakpoints={{ "960px": "75vw", "641px": "90vw" }}
				header={() => (
					<div className='font-normal'>
						<b>{selectedJobDetails?.position}</b> Details
					</div>
				)}
				modal
				className='p-fluid'
				onHide={hideDialog}>
				<div className='flex flex-col mb-5'>
					<b className='text-xl'>Company</b>
					<span>{selectedJobDetails?.company?.companyName}</span>
				</div>
				<div className='flex flex-col mb-5'>
					<b className='text-xl'>Job Description </b>
					<Editor disabled value={selectedJobDetails?.description} readOnly headerTemplate={<></>} />
				</div>
				<div className='flex flex-col mb-5'>
					<b className='text-xl'>Location</b>
					<span>
						{selectedJobDetails?.city} {selectedJobDetails?.country}
					</span>
				</div>
				<div className='flex flex-col mb-5'>
					<b className='text-xl'>Experience</b>
					<span>{selectedJobDetails?.experience}</span>
				</div>
				<div className='flex flex-col mb-5'>
					<b className='text-xl'>Working Style</b>
					<span>{selectedJobDetails?.workingStyle}</span>
				</div>
				<div className='flex flex-col mb-5'>
					<b className='text-xl'>Employment Type</b>
					<span>{selectedJobDetails?.employmentType}</span>
				</div>
				<div className='flex flex-col mb-5'>
					<b className='text-xl'>Salary</b>
					<span>
						{selectedJobDetails?.salaryRange} {selectedJobDetails?.salaryCurrency}
					</span>
				</div>
				<div className='flex flex-col mb-5'>
					<b className='text-xl'>Education</b>
					<span>{selectedJobDetails?.education}</span>
				</div>
				<div className='flex flex-col mb-5'>
					<b className='text-xl'>Open Slots</b>
					<span>{selectedJobDetails?.numberOfNeed}</span>
				</div>
				<div className='flex flex-col mb-5'>
					<b className='text-xl'>Position Opened</b>
					<span>{moment(selectedJobDetails?.openedAt).fromNow()}</span>
				</div>
				<div className='flex flex-col mb-5'>
					<b className='text-xl'>Position Closes</b>
					<span>{moment(selectedJobDetails?.positionClosingDate).fromNow()}</span>
				</div>
			</Dialog>
		</>
	);
};

export default JobsPage;
