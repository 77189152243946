import React from "react";
import { useNavigate } from "react-router-dom";

type IValue = {
	value: number;
	field: string;
};
type Props = {
	title: string;
	values: IValue[];
	icon: any;
	className?: string;
	path: string;
};

const DashboardBox = React.memo(({ icon, title, values, className, path }: Props) => {
	const navigate = useNavigate();
	const onTitleClick = () => {
		navigate(`/${path}`);
	};
	return (
		<div
			className={`flex flex-col justify-between h-[150px] m-2 py-3 px-5 w-[300px] rounded-lg bg-white drop-shadow-md ${className}`}>
			<div className='flex flex-row items-center justify-between'>
				<span onClick={onTitleClick} className='text-2xl cursor-pointer hover:underline font-semibold'>
					{title}
				</span>
				{icon}
			</div>
			<div className='flex flex-row items-center justify-between gap-5 overflow-x-scroll'>
				{values.map((value, index) => (
					<div className='flex flex-col items-center' key={index}>
						<span className='text-md font-semibold'>{value.field}</span>
						<span className='text-2xl font-semibold'>{value.value}</span>
					</div>
				))}
			</div>
		</div>
	);
});

export default DashboardBox;
