import { ChangeEvent, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { FileUpload } from "primereact/fileupload";
import BodyHeader from "../../Components/BodyHeader/BodyHeader";
import { getPositionById } from "../../Redux/actions/position.actions";
import moment from "moment";
import { IReceivedApplication } from "../../Constants/types";
import { createReceivedApplication } from "../../Redux/actions/receivedApplication.actions";
import Lottie from "lottie-react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { showAlert } from "../../Redux/reducers/reducers";
import { Editor } from "primereact/editor";
import { Checkbox } from "primereact/checkbox";
import { APP_PATHS, CountriesWithCities, TermsAndConditions } from "../../Constants/constants";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import Loading from "../../Components/Loading/Loading";
import useUploadStorage from "../../Hooks/useUploadImage";
import { isValidEmail } from "../../Helpers/helperFunctions";

type Props = {};

const ApplyPositionPage = (props: Props) => {
	const { appliedPosition, loading, success } = useAppSelector((state) => state.global);
	const location = useLocation();
	const { uploadCV, media } = useUploadStorage();
	const [showTermsAndCondition, setShowTermsAndCondition] = useState(false);
	const [formStep, setFormStep] = useState<"jobDetails" | "applicationForm" | "applied">("jobDetails");
	const dispatch = useAppDispatch();
	const [formValues, setFormValues] = useState<IReceivedApplication>({
		appliedDate: new Date(),
		appliedFrom: location.search.split("=")[1],
		positionId: Number(location.pathname.split("/apply/")[1]),
		email: "",
		fullName: "",
		status: "Received",
		coverLetter: "",
		resume: undefined,
		file: undefined,
		termsAndCondition: false,
		birthDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
		city: "",
		country: "",
		gender: undefined,
	});

	useEffect(() => {
		if (appliedPosition) {
			document.title = `${appliedPosition.position} (${appliedPosition.company?.companyName})`;
		}
	}, [appliedPosition]);
	useEffect(() => {
		if (media) {
			setFormValues({ ...formValues, resume: media });
		}
	}, [media]);

	useEffect(() => {
		const id = location.pathname.split("/apply/")[1];
		dispatch(getPositionById(Number(id)));
	}, []);

	const handleInputs = (event: ChangeEvent<HTMLInputElement>) => {
		setFormValues({ ...formValues, [event.currentTarget.name]: event.currentTarget.value });
	};
	useEffect(() => {
		if (!loading && success) {
			setFormStep("applied");
		}
	}, [success, loading]);

	const onPositionApply = () => {
		if (
			formValues.fullName === "" ||
			formValues.email === "" ||
			!formValues.birthDate ||
			!formValues.city ||
			!formValues.country ||
			!formValues.gender
		) {
			dispatch(showAlert({ type: "error", text: "Please fill all fields!" }));
			return null;
		}
		if (!formValues.resume) {
			dispatch(showAlert({ type: "error", text: "Please upload your resume / CV !" }));
			return null;
		}

		if (isValidEmail(formValues.email)) {
			dispatch(createReceivedApplication(formValues));
		} else {
			dispatch(showAlert({ type: "error", text: "Please check your email" }));
		}
	};

	return (
		<div className='w-screen h-screen items-center flex flex-col relative'>
			{loading ? (
				<Loading />
			) : (
				<>
					{showTermsAndCondition && (
						<div
							className='bg-black bg-opacity-30 flex items-center justify-center w-screen h-screen fixed z-[9999]'
							onClick={() => setShowTermsAndCondition(false)}>
							<div
								className='bg-white w-10/12 lg:w-1/2 text-center overflow-scroll h-1/2 p-5 rounded-md'
								dangerouslySetInnerHTML={{ __html: TermsAndConditions }}
							/>
						</div>
					)}
					<BodyHeader
						leftItem={`Position: ${appliedPosition?.position}`}
						rightItem={`Position Opened At: ${moment(appliedPosition?.openedAt).format("MMMM Do YYYY")}`}
					/>
					<div
						className={`bg-white rounded-lg drop-shadow-md flex trans-all flex-col w-full md:w-3/4 xl:w-2/3 mx-auto p-5 ${
							formStep === "jobDetails" ? "opacity-100 scale-100 z-10" : "opacity-0 scale-0 -z-10"
						}`}>
						<div className='flex flex-col md:flex-row md:items-center justify-between border-b my-4'>
							<span className='font-semibold'>Company</span>
							<span>{appliedPosition?.company?.companyName}</span>
						</div>
						<div className='flex flex-col md:flex-row md:items-center justify-between border-b my-4'>
							<span className='font-semibold'>Position</span>
							<span>{appliedPosition?.position}</span>
						</div>
						<div className='flex flex-col md:flex-row md:items-center justify-between border-b my-4'>
							<span className='font-semibold'>Description</span>
							{appliedPosition?.description && (
								<div className='max-w-[80%]'>
									<Editor value={appliedPosition.description} readOnly headerTemplate={<></>} />
								</div>
							)}
						</div>
						<div className='flex flex-col md:flex-row md:items-center justify-between border-b my-4'>
							<span className='font-semibold'>Experience</span>
							<span>{appliedPosition?.experience}</span>
						</div>
						<div className='flex flex-col md:flex-row md:items-center justify-between border-b my-4'>
							<span className='font-semibold'>Education</span>
							<span>{appliedPosition?.education}</span>
						</div>
						<div className='flex flex-col md:flex-row md:items-center justify-between border-b my-4'>
							<span className='font-semibold'>Department</span>
							<span>{appliedPosition?.department}</span>
						</div>
						<div className='flex flex-col md:flex-row md:items-center justify-between border-b my-4'>
							<span className='font-semibold'>Employment Type</span>
							<span>{appliedPosition?.employmentType}</span>
						</div>
						<div className='flex flex-col md:flex-row md:items-center justify-between border-b my-4'>
							<span className='font-semibold'>Working Style</span>
							<span>{appliedPosition?.workingStyle}</span>
						</div>
						<div className='flex flex-col md:flex-row md:items-center justify-between border-b my-4'>
							<span className='font-semibold'>City / Country</span>
							<span>
								{appliedPosition?.city} / {appliedPosition?.country}
							</span>
						</div>
						<div className='flex flex-col md:flex-row md:items-center justify-between border-b my-4'>
							<span className='font-semibold'>Number Of Need</span>
							<span>{appliedPosition?.numberOfNeed}</span>
						</div>
						<div className='flex flex-col md:flex-row md:items-center justify-between border-b my-4'>
							<span className='font-semibold'>Position Closing Date</span>
							<span>{moment(appliedPosition?.positionClosingDate).format("MMMM Do YYYY")}</span>
						</div>
						<div className='flex flex-col md:flex-row md:items-center justify-between border-b my-4'>
							<span className='font-semibold'>Salary</span>
							<span>
								{appliedPosition?.salaryRange} {appliedPosition?.salaryCurrency}
							</span>
						</div>
						<div className='flex flex-col md:flex-row md:items-center justify-between border-b my-4'>
							<span className='font-semibold'>Keywords</span>
							<span>{appliedPosition?.keywords}</span>
						</div>
						<button
							className={`w-1/2 my-5 self-center ${
								new Date(appliedPosition?.positionClosingDate!).getTime() < new Date().getTime()
									? "bg-success text-white bg-opacity-50"
									: "hover:bg-success border-success border text-success hover:text-white"
							}  rounded-lg trans-all `}
							disabled={new Date(appliedPosition?.positionClosingDate!).getTime() < new Date().getTime()}
							onClick={() => setFormStep("applicationForm")}>
							{new Date(appliedPosition?.positionClosingDate!).getTime() < new Date().getTime()
								? "This position is closed"
								: "Apply"}
						</button>
					</div>
					<div
						className={`bg-white absolute lg:top-20 top-24 pt-6 items-center left-1/2 -translate-x-1/2 rounded-lg drop-shadow-md flex flex-col md:w-4/5 lg:w-3/4 xl:w-1/3 w-full ${
							formStep === "applicationForm" ? "delay-500 opacity-100 scale-100 z-10" : "opacity-0 scale-0 -z-10"
						}`}>
						<div className='flex w-3/4 flex-col my-1'>
							<label htmlFor='fullName' className='font-bold'>
								Name Surname
							</label>
							<InputText
								id='fullName'
								name='fullName'
								className='ring-0 outline-none border px-2 py-1'
								onChange={handleInputs}
							/>
						</div>
						<div className='flex w-3/4 flex-col my-1'>
							<label htmlFor='Email' className='font-bold'>
								Email
							</label>
							<InputText
								id='Email'
								name='email'
								className='ring-0 outline-none border px-2 py-1'
								type='email'
								onChange={handleInputs}
							/>
						</div>
						<div className='flex w-3/4 flex-col my-1'>
							<label htmlFor='country' className='font-semibold'>
								Country
							</label>
							<Dropdown
								value={formValues.country}
								onChange={(e) => setFormValues({ ...formValues, country: e.value })}
								options={CountriesWithCities.sort((a: any, b: any) => a.countryName - b.countryName).map(
									(item) => item.countryName
								)}
								className='ring-0 outline-none border px-2 my-1'
								id='country'
							/>
						</div>
						<div className='flex w-3/4 flex-col my-1'>
							<label htmlFor='city' className='font-semibold'>
								City
							</label>
							<Dropdown
								value={formValues.city}
								onChange={(e) => setFormValues({ ...formValues, city: e.value })}
								options={CountriesWithCities.find((item) => item.countryName === formValues.country)?.cities.sort()}
								className='ring-0 outline-none border px-2 my-1'
								id='city'
							/>
						</div>
						<div className='flex w-3/4 flex-col my-1'>
							<label htmlFor='gender' className='font-semibold'>
								Gender
							</label>
							<Dropdown
								value={formValues.gender}
								onChange={(e) => setFormValues({ ...formValues, gender: e.value })}
								options={["Male", "Female", "Other"]}
								className='ring-0 outline-none border px-2 my-1'
								id='gender'
							/>
						</div>
						<div className='flex w-3/4 flex-col my-1'>
							<label htmlFor='birthDate' className='font-semibold'>
								Birth Date
							</label>
							<Calendar
								inputClassName='ring-0 outline-none border px-2 py-1'
								id='birthDate'
								maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
								value={formValues.birthDate ? new Date(formValues.birthDate) : formValues.birthDate}
								dateFormat='dd/mm/yy'
								onChange={(e) => setFormValues({ ...formValues, birthDate: e.value as Date })}
							/>
						</div>
						{appliedPosition?.coverLetter && (
							<div className='flex w-3/4 flex-col my-1'>
								<label htmlFor='coverLetter' className='font-bold'>
									Cover Letter
								</label>
								<InputTextarea
									id='coverLetter'
									className='ring-0 outline-none border px-2 py-1'
									rows={3}
									cols={30}
									autoResize={true}
									onChange={(e) => setFormValues({ ...formValues, coverLetter: e.currentTarget.value })}
								/>
							</div>
						)}
						<div className='flex w-3/4 flex-col my-1'>
							<label htmlFor='resume' className='font-bold'>
								Resume / CV
							</label>
							<FileUpload
								className='mt-2'
								mode='basic'
								accept='.pdf'
								maxFileSize={2000000}
								onSelect={async (e) => {
									if (e.files.length) {
										const fileUploaded = e.files[0];
										const buffer = await fileUploaded.arrayBuffer();
										let fileNameSplitted = fileUploaded.name.split(".");
										let originalExtension =
											fileNameSplitted.length > 1 ? fileNameSplitted[fileNameSplitted.length - 1] : fileNameSplitted[0];
										const fileName = `${formValues.email.split(".com")[0]}.${originalExtension}`;
										const newFile = new File([buffer], fileName, { type: fileUploaded.type });
										uploadCV(newFile);
									}
								}}
							/>
						</div>
						<div className='field flex flex-row w-3/4 items-center my-3 justify-between'>
							<label
								htmlFor='termsAndCondition'
								className='font-bold'
								onClick={() => {
									setShowTermsAndCondition(true);
									setFormValues({ ...formValues, termsAndCondition: true });
								}}>
								Terms and Conditions
							</label>
							<Checkbox
								id='termsAndCondition'
								inputId='termsAndCondition'
								name='termsAndCondition'
								className='border rounded-md h-fit ring-0 outline-none'
								onChange={(e) => {
									setFormValues({ ...formValues, termsAndCondition: Boolean(e.target.checked) });
								}}
								checked={formValues.termsAndCondition}></Checkbox>
						</div>
						<button
							disabled={!formValues.termsAndCondition}
							className='w-1/2 my-5 self-center border rounded-lg border-success trans-all hover:bg-success hover:text-white text-success disabled:opacity-60 disabled:cursor-not-allowed'
							onClick={onPositionApply}>
							Send
						</button>
					</div>
					<div
						className={`bg-white py-10 absolute top-1/2 items-center left-1/2 delay-500 -translate-x-1/2 -translate-y-1/2 rounded-lg drop-shadow-md flex flex-col md:w-4/5 lg:w-3/4 xl:w-1/3 w-11/12 ${
							formStep === "applied" ? "opacity-100 scale-100 z-10" : "opacity-0 scale-0 -z-10"
						}`}>
						<Lottie animationData={require("../../Assets/Animations/success.json")} />
						Your application has been received successfully!
						<Link
							className='mt-10 border rounded-md px-3 border-primary hover:border-primary-darker text-primary hover:text-primary-darker'
							to={`/${APP_PATHS.JOBS}`}>
							See All Job Listings
						</Link>
					</div>
				</>
			)}
		</div>
	);
};

export default ApplyPositionPage;
