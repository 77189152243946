import { Provider } from "react-redux";
import store from "./Redux/store/store";
import Main from "./Main";
import { BrowserRouter } from "react-router-dom";

import { PrimeReactProvider } from "primereact/api";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import Routers from "./Routers";

// https://github.com/RD17/react-trumbowyg#examples
function App() {
	return (
		<Provider store={store}>
			<PrimeReactProvider>
				<BrowserRouter>
					<Routers />
					<Main />
				</BrowserRouter>
			</PrimeReactProvider>
		</Provider>
	);
}

export default App;
