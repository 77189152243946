import { ChangeEvent, useEffect, useMemo, useState } from "react";
import Lottie from "lottie-react";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { showAlert } from "../../Redux/reducers/reducers";
import Input from "../../Components/Input/Input";
import { emailCodeVerification, forgotPassword, resetPassword } from "../../Redux/actions/user.actions";
import { APP_PATHS } from "../../Constants/constants";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
type Props = {};

const ForgotPasswordPage = (props: Props) => {
	const [formInputs, setFormInputs] = useState({
		email: "",
	});
	const [passwords, setPasswords] = useState({
		password: "",
		confirmPassword: "",
	});
	const navigate = useNavigate();
	const [code, setCode] = useState("");
	const { forgotPasswordMailId, isLeaving, tempToken, loading, navigateTo } = useAppSelector((state) => state.global);
	const dispatch = useAppDispatch();
	const passwordStrengthCheck = useMemo(() => {
		var strength = 0;
		strength += /[A-Z]+/.test(passwords.password) ? 1 : 0;
		strength += /[a-z]+/.test(passwords.password) ? 1 : 0;
		strength += /[0-9]+/.test(passwords.password) ? 1 : 0;
		strength += /[\W]+/.test(passwords.password) ? 1 : 0;
		let message = "";
		switch (strength) {
			case 1:
				message = "Too weak!";
				break;
			case 2:
				message = "That is better ???";
				break;
			case 3:
				message = "Almost..";
				break;
			case 4:
				message = "Now we're talking!";
				break;
			default:
				message = "";
				break;
		}
		return message;
	}, [passwords.password]);
	const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
		if (tempToken) {
			setPasswords({ ...passwords, [event.currentTarget.name]: event.currentTarget.value });
		} else {
			setFormInputs({ ...formInputs, [event.currentTarget.name]: event.currentTarget.value });
		}
	};
	useEffect(() => {
		if (navigateTo) {
			setTimeout(() => {
				navigate(`/${navigateTo}`);
			}, 1500);
		}
	}, [navigateTo]);

	const handleForgotPassword = () => {
		if (tempToken) {
			if (passwords.password === passwords.confirmPassword) {
				dispatch(
					resetPassword({
						email: formInputs.email,
						password: passwords.password,
						token: tempToken,
					})
				);
			} else {
				dispatch(
					showAlert({
						type: "error",
						text: "Password do not match !",
					})
				);
			}
		} else if (forgotPasswordMailId) {
			dispatch(
				emailCodeVerification({
					code,
					mailId: forgotPasswordMailId,
					mail: formInputs.email,
				})
			);
		} else {
			if (!formInputs.email) {
				dispatch(
					showAlert({
						type: "error",
						text: "Email field is required",
					})
				);
				return;
			} else {
				dispatch(forgotPassword(formInputs.email));
			}
		}
	};

	return (
		<div className='flex items-center justify-center'>
			<div
				className={`flex lg:flex-row lg:mt-24 my-12 flex-col-reverse items-center justify-center mx-auto signin-card rounded-xl w-11/12 lg:w-3/4 ${
					isLeaving.leaving ? "slide-from-top-reverse" : "slide-from-top"
				}`}>
				<div className='lg:border-r border-success border-t lg:border-t-0 mt-2 lg:mt-0 slide-from-left w-11/12 lg:w-1/2'>
					<Lottie animationData={require("../../Assets/Animations/forgotPassword.json")} loop={true} />
				</div>
				<div className='lg:flex-auto flex flex-col fade-in justify-center w-2/3 '>
					{tempToken ? (
						<div className='mx-auto p-fluid px-1 py-3 md:w-2/3 lg:w-2/3 xl:w-1/2 w-full items-center justify-evenly lg:justify-center flex flex-col '>
							<div className='field w-full my-2'>
								<label htmlFor='password'>New Password</label>
								<InputText
									id='password'
									className='ring-0 outline-none border px-2 py-1'
									type='password'
									name='password'
									onChange={handleInput}
								/>
								{passwordStrengthCheck && <span className='text-sm italic font-medium'>{passwordStrengthCheck}</span>}
							</div>
							<div className='field my-2 w-full'>
								<label htmlFor='confirmPassword'>Confirm New Password</label>
								<InputText
									name='confirmPassword'
									className='ring-0 outline-none border px-2 py-1'
									id='confirmPassword'
									type='password'
									onChange={handleInput}
								/>
							</div>
							<Button
								label={loading ? "Saving..." : "Save"}
								loading={loading}
								disabled={loading}
								className='border border-green-600 text-green-600 py-1 px-2 outline-none ring-0'
								onClick={handleForgotPassword}
							/>
						</div>
					) : forgotPasswordMailId ? (
						<>
							<span className='text-lg mb-2 mx-auto font-medium'>Please enter the code we sent to your email</span>
							<div className='mx-auto p-fluid min-w-max px-1 py-3 lg:h-auto h-64 md:w-2/3 lg:w-2/3 xl:w-1/2 w-full items-center justify-evenly lg:justify-center flex flex-col '>
								<div className='field my-2'>
									<label htmlFor='code'>Confirmation Code</label>
									<InputText
										name='code'
										id='code'
										type='number'
										className='ring-0 outline-none border px-2 py-1'
										onChange={(event) => setCode(event.currentTarget.value)}
									/>
								</div>
								<Button
									label={loading ? "Confirming..." : "Confirm"}
									loading={loading}
									disabled={loading}
									className='border border-green-600 w-fit my-4 text-green-600 py-1 px-12 outline-none ring-0'
									onClick={handleForgotPassword}
								/>
							</div>
						</>
					) : (
						<>
							<span className='text-lg mb-2 mx-auto text-center lg:text-start font-medium'>
								Enter your email to reset your password
							</span>
							<div className='mx-auto p-fluid min-w-max px-1 py-3 lg:h-auto h-64 md:w-2/3 lg:w-2/3 xl:w-1/2 w-full items-center justify-evenly lg:justify-center flex flex-col '>
								<div className='field my-2'>
									<label>Email</label>
									<InputText
										className='ring-0 outline-none border px-2 py-1'
										name='email'
										type='email'
										id='email'
										onChange={handleInput}
									/>
								</div>
								<Button
									label={loading ? "Sending..." : "Send"}
									loading={loading}
									disabled={loading}
									className='border border-green-600 w-fit my-4 text-green-600 py-1 px-12 outline-none ring-0'
									onClick={handleForgotPassword}
								/>
								<button
									onClick={() => navigate(`/${APP_PATHS.SIGN_IN}`)}
									className='px-5 my-1 py-1 w-fit mx-auto text-sm rounded-xl text-black hover:underline'>
									Sign In
								</button>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default ForgotPasswordPage;
