import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreatePricingService, GetPricingListService, UpdatePricingService } from "../services/pricing.service";
import { IPricing } from "../../Constants/types";
import { GetStripeDataService } from "../services/stripe.service";

export const getStripePricingList = createAsyncThunk(
	"pricing/getStripePricingList",
	async (visitorFrom: string, thunkAPI) => {
		try {
			return await GetStripeDataService(visitorFrom);
		} catch (error: any) {
			const message =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const createPricing = createAsyncThunk("pricing/createPricing", async (body: Partial<IPricing>, thunkAPI) => {
	try {
		return await CreatePricingService(body);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const updatePricing = createAsyncThunk("pricing/updatePricing", async (body: IPricing, thunkAPI) => {
	try {
		return await UpdatePricingService(body);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
