import { useEffect, useState } from "react";
import PageContainer from "../../Components/PageContainer/PageContainer";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import BodyHeader from "../../Components/BodyHeader/BodyHeader";
import { cancelMeeting, getMeetingListByCompanyId, updateMeeting } from "../../Redux/actions/meeting.actions";
import { IMeeting, IUser } from "../../Constants/types";
import { getUserListByCompanyId } from "../../Redux/actions/user.actions";
import { showAlert } from "../../Redux/reducers/reducers";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";

const MeetingsPage = () => {
	const { user, meetings, users, reFetch, loading } = useAppSelector((state) => state.global);
	const dispatch = useAppDispatch();
	const [selectedMeeting, setSelectedMeeting] = useState<IMeeting | undefined>(undefined);
	const [selectedId, setSelectedId] = useState(-1);
	const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);

	useEffect(() => {
		dispatch(getMeetingListByCompanyId(user.company.id));
	}, []);

	useEffect(() => {
		if (reFetch) {
			dispatch(getMeetingListByCompanyId(user.company.id));
			selectedMeeting && setSelectedMeeting(undefined);
			setSelectedId(-1);
		}
	}, [reFetch]);

	useEffect(() => {
		if (selectedMeeting && users.length < 1) {
			dispatch(getUserListByCompanyId(user.company.id));
		}
	}, [selectedMeeting]);

	const hideDialog = () => {
		setSelectedMeeting(undefined);
		setSelectedId(-1);
	};
	const onMeetingUpdate = () => {
		if (selectedId !== -1) {
			dispatch(cancelMeeting(selectedId));
		} else {
			if (!selectedMeeting?.meetingLink || selectedMeeting.userIds.length === 0 || !selectedMeeting.date) {
				dispatch(
					showAlert({
						text: "Please fill all fields",
						type: "error",
					})
				);
			} else {
				const { users, ...dataToBeSend } = selectedMeeting;
				dispatch(updateMeeting(dataToBeSend));
			}
		}
	};
	const dialogFooter = () => (
		<div className='flex flex-row items-center gap-2 justify-between mt-5 md:mt-0'>
			<Button
				disabled={loading}
				loading={loading}
				label='Cancel'
				outlined
				className='border border-red-600 text-red-600 outline-none ring-0 p-1'
				onClick={hideDialog}
			/>
			<Button
				disabled={loading}
				loading={loading}
				label='Save'
				className='border border-green-600 outline-none ring-0 text-green-600 py-1 px-2'
				onClick={onMeetingUpdate}
			/>
		</div>
	);

	const actionBodyTemplate = (rowData: IMeeting) => {
		return !rowData.isCanceled ? (
			<div className='flex flex-row items-center relative gap-2 justify-center'>
				<Button
					icon='pi pi-pencil'
					className='w-5 outline-none ring-0'
					severity='info'
					onClick={() => {
						const ids = rowData!.users!.map((user: IUser) => user.id);
						setSelectedUsers(rowData!.users!);
						setSelectedMeeting({ ...rowData, userIds: ids });
					}}
				/>
				<Tooltip target='.cancel-meeting' className='text-xs'>
					Cancel Meeting
				</Tooltip>
				<Button
					icon='pi pi-times'
					className='w-5 cancel-meeting'
					data-pr-position='top'
					id='cancel-meeting'
					severity='danger'
					onClick={() => {
						setSelectedMeeting({ ...selectedMeeting!, with: rowData.with });
						setSelectedId(rowData.id!);
					}}
				/>
			</div>
		) : (
			<></>
		);
	};

	return (
		<>
			<PageContainer pageTitle='Meetings'>
				<BodyHeader leftItem={`Welcome, ${user.fullName}`} />
				<div className='p-5 drop-shadow-md'>
					<DataTable showGridlines loading={loading} value={meetings} stripedRows rowHover dataKey='id'>
						<Column field='email' header='Email' headerClassName='bg-black text-white text-xs' />
						<Column
							field='with'
							header='Name Surname'
							headerClassName='bg-black text-white text-xs'
							className='w-[250px]'
						/>
						<Column
							field='date'
							header='Date'
							headerClassName='bg-black text-white text-xs'
							className='w-[200px]  '
							body={(row) => <div>{moment(row.date).format("DD.MM.YYYY HH:mm")}</div>}
						/>
						<Column
							field='position.position'
							header='Position'
							className='w-[220px]'
							headerClassName='bg-black text-white text-xs'
						/>
						<Column
							field='users'
							header='Attendees'
							className='w-[200px] overflow-scroll flex whitespace-nowrap no-scrollbar max-h-[60px]'
							headerClassName='bg-black text-white text-xs'
							body={(row) => (
								<ol className='flex flex-col' type='1'>
									{row.users!.map((item: IUser) => (
										<li key={item.email}>{item.fullName}</li>
									))}
								</ol>
							)}
						/>

						<Column
							field='meetingLink'
							header='Meeting Link'
							headerClassName='bg-black text-white text-xs'
							body={(row) => (
								<a href={`${row.meetingLink}`} target='_blank' className='underline'>
									{`${row.meetingLink}`}
								</a>
								// 	<a href={`${window.location.origin}/meeting/${row.meetingLink}`} target='_blank' className='underline'>
								// 	{`${window.location.origin}/meeting/${row.meetingLink}`}
								// </a>
							)}
						/>
						<Column
							field='isMeetingCompleted'
							headerClassName='bg-black text-white text-xs'
							header='Completed'
							style={{ maxWidth: 75 }}
							body={(row) =>
								row.isMeetingCompleted ? (
									<CheckIcon width={18} height={18} strokeWidth={4} />
								) : (
									<XMarkIcon width={18} height={18} strokeWidth={4} />
								)
							}
						/>
						<Column
							field='isCanceled'
							headerClassName='bg-black text-white text-xs'
							header='Canceled'
							style={{ maxWidth: 75 }}
							body={(row) =>
								row.isCanceled ? (
									<CheckIcon width={18} height={18} strokeWidth={4} />
								) : (
									<XMarkIcon width={18} height={18} strokeWidth={4} />
								)
							}
						/>
						<Column body={actionBodyTemplate} headerClassName='bg-black text-white w-[100px]'></Column>
					</DataTable>
				</div>
			</PageContainer>
			<Dialog
				visible={Boolean(selectedMeeting) && selectedId === -1}
				style={{ width: "32rem" }}
				closeOnEscape
				breakpoints={{ "960px": "75vw", "641px": "90vw" }}
				header={() => (
					<div className='font-normal'>
						Set a meeting with <b>{selectedMeeting?.with}</b>
					</div>
				)}
				modal
				className='p-fluid'
				footer={dialogFooter}
				onHide={hideDialog}>
				<div className='field my-3'>
					<label htmlFor='meetingLink' className='font-bold'>
						Meeting Link
					</label>
					<InputText
						id='meetingLink'
						className='ring-0 outline-none border px-2 py-1'
						value={selectedMeeting?.meetingLink}
						onChange={(e) => setSelectedMeeting({ ...selectedMeeting!, meetingLink: e.currentTarget.value })}
					/>
				</div>
				<div className='field my-3'>
					<label htmlFor='positionClosingDate' className='font-bold'>
						Meeeting Date
					</label>
					<Calendar
						inputClassName='ring-0 outline-none border px-2 py-1'
						value={new Date(selectedMeeting?.date!)}
						showTime
						onChange={(e) => {
							setSelectedMeeting({ ...selectedMeeting!, date: e.value as any });
						}}
					/>
				</div>
				<div className='field my-3'>
					<label htmlFor='employmentType' className='font-bold'>
						Attendees
					</label>
					<MultiSelect
						value={selectedUsers}
						onChange={(e) => {
							let ids = e.value.map((user: IUser) => user.id);
							setSelectedMeeting({ ...selectedMeeting!, userIds: ids });
							setSelectedUsers(e.value);
						}}
						options={users}
						optionLabel='fullName'
						placeholder='Select Attendees'
						maxSelectedLabels={3}
						className='ring-0 outline-none border px-2 py-1'
						itemClassName='ring-0 outline-none border'
					/>
				</div>

				<div className='field flex flex-row items-center mt-3 justify-between'>
					<label htmlFor='isMeetingCompleted' className='font-bold'>
						Is Meeting Completed
					</label>
					<Checkbox
						id='isMeetingCompleted'
						name='isMeetingCompleted'
						className='border rounded-md h-fit'
						onChange={(e) => setSelectedMeeting({ ...selectedMeeting!, isMeetingCompleted: e.checked })}
						checked={Boolean(selectedMeeting?.isMeetingCompleted)}></Checkbox>
				</div>
			</Dialog>
			<Dialog
				visible={selectedId !== -1}
				style={{ width: "32rem" }}
				closeOnEscape
				breakpoints={{ "960px": "75vw", "641px": "90vw" }}
				header={() => (
					<div className='font-normal'>
						Cancel meeting with <b>{selectedMeeting?.with}</b>
					</div>
				)}
				modal
				className='p-fluid'
				footer={dialogFooter}
				onHide={hideDialog}>
				<span>Are you sure to cancel this meeting?</span>
			</Dialog>
		</>
	);
};

export default MeetingsPage;
