import { ChangeEvent, useEffect, useMemo, useState } from "react";
import PageContainer from "../../Components/PageContainer/PageContainer";
import { APP_PATHS } from "../../Constants/constants";
import { showAlert } from "../../Redux/reducers/reducers";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import BodyHeader from "../../Components/BodyHeader/BodyHeader";
import { resetPassword } from "../../Redux/actions/user.actions";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

type Props = {};

const ChangePasswordPage = (props: Props) => {
	const { user, navigateTo, token, loading } = useAppSelector((state) => state.global);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [passwords, setPasswords] = useState({
		currentPassword: "",
		newPassword: "",
		confirmPassword: "",
	});

	const handleInputs = (event: ChangeEvent<HTMLInputElement>) => {
		setPasswords({ ...passwords, [event.currentTarget.name]: event.currentTarget.value });
	};

	useEffect(() => {
		if (navigateTo) {
			setTimeout(() => {
				navigate(`/${navigateTo}`);
			}, 1200);
		}
	}, [navigateTo]);

	const passwordStrengthCheck = useMemo(() => {
		var strength = 0;
		strength += /[A-Z]+/.test(passwords.newPassword) ? 1 : 0;
		strength += /[a-z]+/.test(passwords.newPassword) ? 1 : 0;
		strength += /[0-9]+/.test(passwords.newPassword) ? 1 : 0;
		strength += /[\W]+/.test(passwords.newPassword) ? 1 : 0;
		let message = "";
		switch (strength) {
			case 1:
				message = "Too weak!";
				break;
			case 2:
				message = "That is better ???";
				break;
			case 3:
				message = "Almost..";
				break;
			case 4:
				message = "Now we're talking!";
				break;
			default:
				message = "";
				break;
		}
		return message;
	}, [passwords.newPassword]);

	const onSave = () => {
		if (passwords.newPassword === passwords.confirmPassword) {
			dispatch(
				resetPassword({
					email: user.email,
					password: passwords.newPassword,
					currentPassword: passwords.currentPassword,
					token: token!,
				})
			);
		} else if (!passwords.currentPassword) {
			dispatch(
				showAlert({
					type: "error",
					text: "Please enter your current password",
				})
			);
		} else {
			dispatch(
				showAlert({
					type: "error",
					text: "Password do not match !",
				})
			);
		}
	};

	return (
		<PageContainer pageTitle='Change Password'>
			<BodyHeader leftItem={<ArrowLeftIcon color='black' width={24} height={24} />} leavingTo={APP_PATHS.SETTINGS} />
			<div className='lg:m-auto mx-auto items-center min-w-max px-1 py-3 rounded-xl justify-evenly lg:justify-center flex flex-col '>
				<span className='mb-5 text-lg font-semibold'>You can change your password here.</span>
				<div className='field my-1 flex flex-col'>
					<label htmlFor='currentPassword' className='font-bold'>
						Current Password
					</label>
					<InputText
						id='currentPassword'
						name='currentPassword'
						autoFocus
						type='password'
						className='ring-0 outline-none border px-2 py-1 w-[250px]'
						onChange={handleInputs}
					/>
				</div>
				<div className='field my-1 flex flex-col'>
					<label htmlFor='newPassword' className='font-bold'>
						New Password
					</label>
					<InputText
						id='newPassword'
						name='newPassword'
						type='password'
						className='ring-0 outline-none border px-2 py-1 w-[250px]'
						onChange={handleInputs}
					/>

					{passwordStrengthCheck && (
						<span className={`text-xs mr-auto mb-2 italic font-medium`}>{passwordStrengthCheck}</span>
					)}
				</div>
				<div className='field my-1 flex flex-col'>
					<label htmlFor='confirmPassword' className='font-bold'>
						Confirm New Password
					</label>
					<InputText
						id='confirmPassword'
						name='confirmPassword'
						type='password'
						className='ring-0 outline-none border px-2 py-1 w-[250px]'
						onChange={handleInputs}
					/>
				</div>
				<Button
					label={loading ? "Saving..." : "Save"}
					className='my-2 py-1 px-12 rounded-xl text-black border-black hover:bg-black hover:text-white trans-all font-semibold border'
					loading={loading}
					onClick={onSave}
				/>
			</div>
		</PageContainer>
	);
};

export default ChangePasswordPage;
