import React from "react";

type Props = {};

const Loading = (props: Props) => {
	return (
		<div className='flex flex-col items-center justify-center h-screen relative'>
			<span className='font-semibold tracking-wide animate-bounce'>Loading...</span>
		</div>
	);
};

export default Loading;
