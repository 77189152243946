import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EncryptStorage } from "encrypt-storage";
import { APP_PATHS } from "../../Constants/constants";
import { IAlert, IMeeting, InitialState, IUser } from "../../Constants/types";
import { getUserByToken, login } from "../actions/auth.actions";
import { createPricing, getStripePricingList, updatePricing } from "../actions/pricing.actions";
import {
	emailCodeVerification,
	createUser,
	deleteUser,
	forgotPassword,
	getUserListByCompanyId,
	resetPassword,
	signUpNewUserWithCompany,
	updateUser,
	cancelSubscription,
} from "../actions/user.actions";
import { LOCAL_STORAGE_TOKEN_KEY } from "../../Constants/api";
import {
	createPosition,
	deletePosition,
	getActivePositions,
	getPositionById,
	getPositionListByCompanyId,
	updatePosition,
} from "../actions/position.actions";
import {
	createReceivedApplication,
	getAiReview,
	getReceivedApplicationsList,
	getReceivedApplicationsListByCompanyId,
	updateReceivedApplication,
	updateReceivedApplicationStatus,
} from "../actions/receivedApplication.actions";
import {
	cancelMeeting,
	createMeeting,
	deleteMeeting,
	getMeetingListByCompanyId,
	updateMeeting,
} from "../actions/meeting.actions";
import moment from "moment";
import { getDashboardData, getSuperAdminDashboard } from "../actions/dashboard.actions";
import {
	createHelpCenterRequest,
	getHelpCenterRequests,
	saveCancelSubscriptionReason,
} from "../actions/help-center.actions";
import { createCreditCard } from "../actions/creditCard.actions";
import { updateCompany } from "../actions/company.actions";

export const encryptStorage = new EncryptStorage("talentManager-secret-key", {
	encAlgorithm: "Rabbit",
	storageType: "localStorage",
	prefix: "@talentify_apptoken",
});
const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

export const initialState: InitialState = {
	error: false,
	success: false,
	message: [],
	isAuthenticated: undefined,
	user: {} as IUser,
	users: [],
	isSidebarOpen: true,
	isLeaving: {
		leaving: false,
		path: "",
		state: undefined,
	},
	openPositions: [],
	receviedApplications: [],
	isFetchingUser: false,
	pricingList: [],
	navigateTo: "",
	reFetch: false,
	loading: false,
	refreshToken: undefined,
	token: token,
	appliedPosition: undefined,
	meetings: [],
	userActiveMeetings: [],
	todaysMeetings: [],
	forgotPasswordMailId: undefined,
	isForgotPasswordCodeVerified: undefined,
	tempToken: undefined,
	dashboardData: undefined,
	companyId: undefined,
	userId: undefined,
	adminDashboardData: undefined,
	helpCenterRequests: [],
	allMeetings: [],
	activePositions: undefined,
	aiReviewResult: undefined,
	streamToken: "",
	isSubscriptionCanceled: false,
	visitorFrom: "",
};

export const reducer = createSlice({
	name: "global",
	initialState,
	reducers: {
		clearGlobalStates: (state) => {
			state.message = [];
			state.error = false;
			state.success = false;
		},
		sidebarToggle: (state) => {
			state.isSidebarOpen = !state.isSidebarOpen;
		},
		setIsLeaving: (state, action: PayloadAction<{ path: string; leaving: boolean; state?: string }>) => {
			state.isLeaving = action.payload;
		},
		showAlert: (state, action: PayloadAction<IAlert>) => {
			state.message = [...state.message, action.payload];
		},
		setVisitorFrom: (state, action: PayloadAction<string>) => {
			state.visitorFrom = action.payload;
		},
		logout: (state) => {
			localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
			localStorage.removeItem("streamToken");
			state.isAuthenticated = false;
			state.user = {} as IUser;
			state.token = undefined;
			state.refreshToken = undefined;
		},
		clearAiReviewResult: (state) => {
			state.aiReviewResult = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			// *********** Login START *********** \\
			.addCase(login.pending, (state) => {
				state.isFetchingUser = true;
				state.success = false;
				state.error = false;
				state.tempToken = undefined;
				state.isForgotPasswordCodeVerified = undefined;
				state.forgotPasswordMailId = undefined;
				state.navigateTo = undefined;
			})
			.addCase(login.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.isAuthenticated = true;

					const { accessToken, refreshToken, streamToken, ...rest } = action.payload.data;
					localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, accessToken);
					localStorage.setItem("streamToken", streamToken);
					state.user = rest;
					state.streamToken = streamToken;
					state.token = accessToken;
					state.refreshToken = refreshToken;
				} else {
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
					state.error = true;
				}
				state.isFetchingUser = false;
				// *********** Login END *********** \\
			}) // *********** Get Pricing List START *********** \\
			.addCase(getStripePricingList.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
			})
			.addCase(getStripePricingList.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.pricingList = action.payload.data;
				} else {
					// state.message = action.payload.message || [];
					state.error = true;
				}
				state.loading = false;
				// *********** Get Pricing List END *********** \\
			}) // *********** Get User List By Company Id START *********** \\
			.addCase(getUserListByCompanyId.pending, (state) => {
				state.success = false;
				state.error = false;
				state.navigateTo = undefined;
				state.isFetchingUser = true;
				state.isLeaving = {
					leaving: false,
					path: "",
				};
			})
			.addCase(getUserListByCompanyId.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.users = action.payload.data;
				} else {
					// state.message = action.payload.message || [];
					state.error = true;
				}
				state.isFetchingUser = false;
				// *********** Get User List By Company Id END *********** \\
			}) // *********** Sign Up With Company START *********** \\
			.addCase(signUpNewUserWithCompany.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
			})
			.addCase(signUpNewUserWithCompany.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.mailId = action.payload.data.emailId;
					state.companyId = action.payload.data.companyId;
					state.userId = action.payload.data.userId;
					state.success = true;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
				} else {
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
					state.error = true;
				}
				state.loading = false;
				// *********** Sign Up With Company END *********** \\
			}) // *********** Get User By Token START *********** \\
			.addCase(getUserByToken.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.message = [];
			})
			.addCase(getUserByToken.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					const data = action.payload.data;
					state.streamToken = localStorage.getItem("streamToken") || "";
					state.user = data;
					state.allMeetings = data.meetings;
					const userMeetings = data.meetings.filter(
						(meeting: IMeeting) => meeting.isActive && !meeting.isMeetingCompleted
					);
					state.userActiveMeetings = userMeetings;
					const today = moment(new Date()).format("DD.MM.YYYY");
					state.todaysMeetings = userMeetings.filter(
						(item: IMeeting) => moment(item.date).format("DD.MM.YYYY") === today
					);
					state.isAuthenticated = true;
				} else if (action.payload.status === 401) {
					state.error = true;
					state.token = undefined;
					localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
					state.isAuthenticated = false;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// *********** Get User By Token END *********** \\
			}) // *********** Create User START *********** \\
			.addCase(createUser.pending, (state) => {
				state.isFetchingUser = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
			})
			.addCase(createUser.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.reFetch = true;
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.isFetchingUser = false;
				// *********** Create User END *********** \\
			}) // *********** Get Position List By Company Id START *********** \\
			.addCase(getPositionListByCompanyId.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
			})
			.addCase(getPositionListByCompanyId.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.openPositions = action.payload.data;
				} else {
					state.error = true;
				}
				state.loading = false;
				// *********** Get Position List By Company Id END *********** \\
			}) // *********** Create Position START *********** \\
			.addCase(createPosition.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
			})
			.addCase(createPosition.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.reFetch = true;
				} else {
					state.error = true;
				}
				state.loading = false;
				// *********** Create Position END *********** \\
			}) // *********** Delete Position START *********** \\
			.addCase(deletePosition.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
			})
			.addCase(deletePosition.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.reFetch = true;
				} else {
					state.error = true;
				}
				state.loading = false;
				// *********** Delete Position END *********** \\
			}) // ***********  Get Position By Id START *********** \\
			.addCase(getPositionById.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
			})
			.addCase(getPositionById.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.appliedPosition = action.payload.data;
				} else {
					state.error = true;
				}
				state.loading = false;
				// ***********  Get Position By Id END *********** \\
			})
			// ***********  Get Active Positions START *********** \\
			.addCase(getActivePositions.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
			})
			.addCase(getActivePositions.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.activePositions = action.payload.data;
				} else {
					state.error = true;
				}
				state.loading = false;
				// ***********  Get Active Positions END *********** \\
			})

			// ***********  Get Received Applications By Company Id START *********** \\
			.addCase(getReceivedApplicationsListByCompanyId.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
			})
			.addCase(getReceivedApplicationsListByCompanyId.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.receviedApplications = action.payload.data;
				} else {
					state.error = true;
				}
				state.loading = false;
				// ***********  Get Received Applications By Company Id END *********** \\
			}) // ***********  Create Received Application START *********** \\
			.addCase(createReceivedApplication.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
			})
			.addCase(createReceivedApplication.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.success = true;
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Create Received Application END *********** \\
			})
			// ***********  Get Received Applications List START *********** \\
			.addCase(getReceivedApplicationsList.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
			})
			.addCase(getReceivedApplicationsList.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.success = true;
					state.receviedApplications = action.payload.data;
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Get Received Applications List END *********** \\
			})
			// ***********  Update Position START *********** \\
			.addCase(updatePosition.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
			})
			.addCase(updatePosition.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.reFetch = true;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Update Position END *********** \\
			})
			// ***********  Update User START *********** \\
			.addCase(updateUser.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.message = [];
			})
			.addCase(updateUser.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.reFetch = true;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Update User END *********** \\
			})
			// ***********  Delete User START *********** \\
			.addCase(deleteUser.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
			})
			.addCase(deleteUser.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.reFetch = true;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Delete User END *********** \\
			})
			// ***********  Update Recevied Application START *********** \\
			.addCase(updateReceivedApplication.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
			})
			.addCase(updateReceivedApplication.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.reFetch = true;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Update Recevied Application END *********** \\
			})
			// ***********  Create Meeting START *********** \\
			.addCase(createMeeting.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
				state.message = [];
			})
			.addCase(createMeeting.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.reFetch = true;
					state.success = true;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Create Meeting END *********** \\
			})
			// ***********  Get Meeting List By Company Id START *********** \\
			.addCase(getMeetingListByCompanyId.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
				state.message = [];
			})
			.addCase(getMeetingListByCompanyId.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.meetings = action.payload.data;
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Get Meeting List By Company Id END *********** \\
			})
			// ***********  Update Meeting START *********** \\
			.addCase(updateMeeting.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
				state.message = [];
			})
			.addCase(updateMeeting.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.reFetch = true;
					state.success = true;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Update Meeting END *********** \\
			})
			// ***********  Cancel Meeting START *********** \\
			.addCase(cancelMeeting.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
				state.message = [];
			})
			.addCase(cancelMeeting.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.reFetch = true;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Cancel Meeting END *********** \\
			})
			// ***********  Delete Meeting START *********** \\
			.addCase(deleteMeeting.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
				state.message = [];
			})
			.addCase(deleteMeeting.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.reFetch = true;
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Delete Meeting END *********** \\
			}) // ***********  Forgot Password START *********** \\
			.addCase(forgotPassword.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
				state.message = [];
			})
			.addCase(forgotPassword.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.forgotPasswordMailId = action.payload.data;
					state.success = true;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Forgot Password END *********** \\
			}) // ***********  Forgot Password Check Code START *********** \\
			.addCase(emailCodeVerification.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
				state.message = [];
			})
			.addCase(emailCodeVerification.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.tempToken = action.payload.data.tempToken;
					state.success = true;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Forgot Password Check Code END *********** \\
			}) // ***********  Forgot Password Check Code START *********** \\
			.addCase(resetPassword.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
				state.message = [];
			})
			.addCase(resetPassword.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.success = true;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
					state.navigateTo = APP_PATHS.SIGN_IN;
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Forgot Password Check Code END *********** \\
			}) // ***********  Dashboard Data START *********** \\
			.addCase(getDashboardData.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
				state.message = [];
			})
			.addCase(getDashboardData.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.dashboardData = action.payload.data;
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Dashboard Data END *********** \\
			})
			// ***********  Admin Dashboard Data START *********** \\
			.addCase(getSuperAdminDashboard.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
				state.message = [];
			})
			.addCase(getSuperAdminDashboard.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.adminDashboardData = action.payload.data;
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Admin Dashboard Data END *********** \\
			})
			// ***********  Help Center START *********** \\
			.addCase(createHelpCenterRequest.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
				state.message = [];
			})
			.addCase(createHelpCenterRequest.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.success = true;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Help Center END *********** \\
			}) // ***********  Get Help Center Request START *********** \\
			.addCase(getHelpCenterRequests.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
				state.message = [];
			})
			.addCase(getHelpCenterRequests.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.helpCenterRequests = action.payload.data;
					state.success = true;
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Get Help Center Request END *********** \\
			})
			// ***********  Create Pricing START *********** \\
			.addCase(createPricing.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
				state.message = [];
			})
			.addCase(createPricing.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.success = true;
				} else {
					state.error = true;
				}
				state.loading = false;
				// ***********  Create Pricing END *********** \\
			})

			// ***********  Update Company START *********** \\
			.addCase(updateCompany.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
				state.message = [];
			})
			.addCase(updateCompany.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.success = true;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.reFetch = true;
				state.loading = false;
				// ***********  Update Company END *********** \\
			})
			// ***********  Update Pricing START *********** \\
			.addCase(updatePricing.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.message = [];
			})
			.addCase(updatePricing.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.success = true;
					state.reFetch = true;
				} else {
					state.error = true;
				}
				state.loading = false;
				// ***********  Update Pricing END *********** \\
			})
			// ***********  Create Credit Card START *********** \\
			.addCase(createCreditCard.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.navigateTo = undefined;
				state.message = [];
			})
			.addCase(createCreditCard.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.success = true;
					state.tempToken = undefined;
					state.mailId = undefined;
					state.navigateTo = APP_PATHS.SIGN_IN;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
				} else {
					state.error = true;

					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Create Credit Card END *********** \\
			})
			// ***********  Update Received Application Status START *********** \\
			.addCase(updateReceivedApplicationStatus.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.message = [];
			})
			.addCase(updateReceivedApplicationStatus.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.success = true;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
					state.reFetch = true;
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Update Received Application Status END *********** \\
			})
			// ***********  Get AI Review START *********** \\
			.addCase(getAiReview.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.message = [];
			})
			.addCase(getAiReview.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.success = true;
					state.aiReviewResult = action.payload.data;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Get AI Review END *********** \\
			})
			// ***********  Cancel Subscription START *********** \\
			.addCase(cancelSubscription.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = false;
				state.reFetch = false;
				state.message = [];
			})
			.addCase(cancelSubscription.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.success = true;
					state.message = [
						{
							text: action.payload.message,
							type: "success",
						},
					];
					state.isSubscriptionCanceled = true;
				} else {
					state.error = true;
					state.message = [
						{
							text: action.payload.message,
							type: "error",
						},
					];
				}
				state.loading = false;
				// ***********  Cancel Subscription END *********** \\
			});
	},
});

export const {
	sidebarToggle,
	setIsLeaving,
	clearGlobalStates,
	showAlert,
	logout,
	clearAiReviewResult,
	setVisitorFrom,
} = reducer.actions;

export default reducer.reducer;
