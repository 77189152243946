import { IMeeting, IGeneralResponse } from "../../Constants/types";
import axiosInstance from "../../Helpers/axiosClient";
const meeting = "meetings";
export const GetMeetingListByCompanyIdService = async (companyId: number) => {
	try {
		const response = await axiosInstance.get(`${meeting}/by-company?companyId=${companyId}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.message,
		};
	}
};
export const GetMeetingListByPositionIdService = async (positionId: number) => {
	try {
		const response = await axiosInstance.get(`${meeting}/by-position?positionId=${positionId}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.message,
		};
	}
};
export const CreateMeetingService = async (body: IMeeting) => {
	try {
		const response = await axiosInstance.post(`${meeting}`, body);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.message,
		};
	}
};
export const UpdateMeetingService = async (body: IMeeting) => {
	try {
		const { id, isActive, ...rest } = body;
		const response = await axiosInstance.put(`${meeting}/${id}`, rest);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.message,
		};
	}
};
export const DeleteMeetingService = async (id: number) => {
	try {
		const response = await axiosInstance.delete(`${meeting}/${id}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.message,
		};
	}
};

export const GetMeetingByIdService = async (id: number) => {
	try {
		const response = await axiosInstance.get(`${meeting}/${id}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.message,
		};
	}
};

export const CancelMeetingService = async (id: number) => {
	try {
		const response = await axiosInstance.get(`${meeting}/cancel-meeting/${id}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.message,
		};
	}
};

export const GetMeetingByMeetingLinkService = async (meetingLink: string) => {
	try {
		const response = await axiosInstance.get(`${meeting}/meeting-link/${meetingLink}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.message,
		};
	}
};

export const StartMeetingService = async (meetingLink: string, meetingId: string) => {
	try {
		const response = await axiosInstance.get(`${meeting}/start-meeting/${meetingLink}/${meetingId}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.message,
		};
	}
};
export const EndMeetingService = async (meetingLink: string) => {
	try {
		const response = await axiosInstance.get(`${meeting}/end-meeting/${meetingLink}`);
		return response.data as IGeneralResponse;
	} catch (error: any) {
		return {
			status: error.response.status,
			data: undefined,
			message: error.response.message,
		};
	}
};
