import React from "react";

type Props = { text: string; type: "success" | "error" | "warning" };

const Alert = ({ text, type }: Props) => {
	return (
		<span
			className={`alert ${type === "success" ? "success-alert" : type === "error" ? "error-alert" : "warning-alert"}`}
		>
			{text}
		</span>
	);
};

export default Alert;
