import React, { useEffect } from "react";
import { getHelpCenterRequests } from "../../../Redux/actions/help-center.actions";
import { useAppDispatch, useAppSelector } from "../../../Redux/store/store";
import PageContainer from "../../../Components/PageContainer/PageContainer";
import BodyHeader from "../../../Components/BodyHeader/BodyHeader";
import Table from "../../../Components/Table/Table";
import { IUser } from "../../../Constants/types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";

type Props = {};

const AdminHelpCenterPage = (props: Props) => {
	const dispatch = useAppDispatch();
	const { helpCenterRequests, loading } = useAppSelector((state) => state.global);

	useEffect(() => {
		dispatch(getHelpCenterRequests());
	}, []);

	return (
		<PageContainer pageTitle='Admin Help Center'>
			<DataTable loading={loading} value={helpCenterRequests} showGridlines stripedRows rowHover dataKey='id'>
				<Column headerClassName='bg-black text-white' field='topic' header='Topic' />
				<Column headerClassName='bg-black text-white' field='description' header='Description' />
				<Column headerClassName='bg-black text-white' field='email' header='Email' />
				<Column
					headerClassName='bg-black text-white'
					field='createdAt'
					header='Date'
					body={(row) => <div>{moment(row.createdAt).format("DD.MM.YYYY HH:mm")}</div>}
				/>
				<Column headerClassName='bg-black text-white' field='user.fullName' header='User' />
				<Column headerClassName='bg-black text-white' field='user.company.companyName' header='Company' />
				<Column headerClassName='bg-black text-white' field='status' header='Status' />
			</DataTable>
		</PageContainer>
	);
};

export default AdminHelpCenterPage;
