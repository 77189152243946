import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetUserByTokenService, LoginAsync } from "../services/auth.service";
import { ILogin } from "../../Constants/types";

export const login = createAsyncThunk("auth/login", async (data: ILogin, thunkAPI) => {
	try {
		return await LoginAsync(data);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const getUserByToken = createAsyncThunk("auth/getUserByToken", async (_, thunkAPI) => {
	try {
		return await GetUserByTokenService();
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
