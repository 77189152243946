import { initializeApp } from "firebase/app";
import * as _firestore from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
	apiKey: "AIzaSyBLe7FOrDWYRvl7rxZ-SlWwsNbdF_QmQ0g",
	authDomain: "talentify-dec4b.firebaseapp.com",
	projectId: "talentify-dec4b",
	storageBucket: "talentify-dec4b.appspot.com",
	messagingSenderId: "641901905710",
	appId: "1:641901905710:web:d6a6909c6096539b265cbf",
	measurementId: "G-SNRBJ8K469",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const firestore = getFirestore(firebaseApp);
