import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { StreamCall, StreamVideo, StreamVideoClient, User } from "@stream-io/video-react-sdk";
import MeetingBody from "../../Components/Meeting/MeetingBody";
import { Button } from "primereact/button";
import { IMeeting } from "../../Constants/types";
import { GetMeetingByMeetingLinkService, StartMeetingService } from "../../Redux/services/meeting.service";
import { HttpStatusCode } from "axios";
import { showAlert } from "../../Redux/reducers/reducers";
import { InputText } from "primereact/inputtext";

type Props = {};
let STREAM_CONSTANTS = {
	apiKey: "u5s9vvnbd92c",
	appId: 1278894,
	secret: "rkdehfwvzqfnm5c7tmyt8z5zhnc8zdwxjzr8rjp6veeeta6yvbeeqrf4vcedygsg",
};

const MeetingPage = (props: Props) => {
	const { user, token, streamToken } = useAppSelector((state) => state.global);
	const { meetingId } = useParams();
	const [guestName, setGuestName] = useState("");
	const [client, setClient] = useState<any>();
	const dispatch = useAppDispatch();
	const [guestStreamToken, setGuestStreamToken] = useState("");
	const [call, setCall] = useState<any>();
	const [currentMeeting, setCurrentMeeting] = useState<IMeeting>();
	const getMeeting = async (meetingId: string) => {
		const response = await GetMeetingByMeetingLinkService(meetingId);

		if (response.status === HttpStatusCode.Ok) {
			setCurrentMeeting(response.data.meeting);
			!Boolean(user?.fullName) && setGuestStreamToken(response.data.streamToken);
		} else {
			dispatch(
				showAlert({
					text: response.message,
					type: "error",
				})
			);
		}
	};
	useEffect(() => {
		if (meetingId) {
			getMeeting(meetingId);
		}
	}, []);
	useEffect(() => {
		let timeout = null as any;
		if (currentMeeting && !currentMeeting.isMeetingStarted) {
			timeout = setTimeout(() => {
				getMeeting(meetingId!);
			}, 5000);
		}
		return () => clearTimeout(timeout);
	}, [currentMeeting]);

	const startMeeting = async () => {
		await StartMeetingService(currentMeeting?.meetingLink!, "");
	};
	useEffect(() => {
		if (user?.fullName && Boolean(currentMeeting)) {
			startMeeting();
		}
	}, [user, currentMeeting]);

	useEffect(() => {
		if (!Boolean(call) && !Boolean(client))
			if (user?.fullName && token) {
				const _user: User = {
					id: user.email,
				};
				const _client = new StreamVideoClient({
					apiKey: STREAM_CONSTANTS.apiKey,
					user: _user,
					token: streamToken,
				});
				const _call = _client.call("default", meetingId || "talentify-meeting");
				_call.join({ create: true });
				setClient(_client);
				setCall(_call);
			}
	}, [user, token]);
	const joinCallAsGuest = () => {
		try {
			const _user: User = {
				id: "guest",
				name: guestName,
			};
			const _client = new StreamVideoClient({
				apiKey: STREAM_CONSTANTS.apiKey,
				user: _user,
				token: guestStreamToken,
			});
			const _call = _client.call("default", meetingId || "talentify-meeting");
			_call.join();
			setClient(_client);
			setCall(_call);
		} catch (error) {}
	};

	return call && client ? (
		<StreamVideo client={client}>
			<StreamCall call={call}>
				<MeetingBody
					meetingId={meetingId || "talentify-meeting"}
					onParticipantCountChange={(count) => {}}
					isGuest={Boolean(user?.fullName) ? false : true}
				/>
			</StreamCall>
		</StreamVideo>
	) : (
		<div className='bg-black w-screen h-screen text-white font-semibold text-2xl flex flex-col items-center justify-center'>
			{currentMeeting?.isMeetingStarted ? (
				<div className='flex flex-col'>
					<span>They are waiting for you</span>
					<InputText
						className='border border-white text-black rounded-md px-2 py-0.5 mt-2'
						placeholder='Enter your name'
						onChange={(event) => setGuestName(event.currentTarget.value)}
					/>
					<Button
						label='Join meeting'
						className='border border-white rounded-md px-2 py-0.5 mt-2'
						onClick={joinCallAsGuest}
						disabled={!guestName}
					/>
				</div>
			) : user?.fullName ? (
				<>We are setting up the meeting, just a second...</>
			) : (
				<>Looks like you're the first one here. We will let you know once meeting started</>
			)}
		</div>
	);
};

export default MeetingPage;
