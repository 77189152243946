import { useEffect, useState } from "react";
import PageContainer from "../../../Components/PageContainer/PageContainer";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { GetCompanyListService } from "../../../Redux/services/company.service";
import moment from "moment";

const AdminCompaniesPage = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const getData = async () => {
		const response = await GetCompanyListService();
		setData(response.data);
		setLoading(false);
	};
	useEffect(() => {
		getData();
	}, []);
	return (
		<PageContainer pageTitle='Admin Company Page'>
			<DataTable loading={loading} value={data} showGridlines stripedRows rowHover dataKey='id'>
				<Column headerClassName='bg-black text-white' field='companyName' header='Company' />
				<Column headerClassName='bg-black text-white' field='isTrial' header='Is Trial' />
				<Column
					headerClassName='bg-black text-white'
					field='trialEndDate'
					header='Trial End Date'
					body={(row) => <div>{moment(row.trialEndDate).format("MMMM DD YYYY")}</div>}
				/>
				<Column headerClassName='bg-black text-white' field='country' header='Country' />
				<Column headerClassName='bg-black text-white' field='city' header='City' />
				<Column headerClassName='bg-black text-white' field='address' header='Address' />
				<Column headerClassName='bg-black text-white' field='pricing.title' header='Pricing' />
				<Column
					headerClassName='bg-black text-white'
					field='trackPrices'
					header='Track Prices'
					body={(row) => {
						return <div>sefa</div>;
					}}
				/>
			</DataTable>
		</PageContainer>
	);
};

export default AdminCompaniesPage;
