import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetDashboardDataService, GetSuperAdminDashboardService } from "../services/dashboard.service";

export const getDashboardData = createAsyncThunk("dashboard/getDashboardData", async (email: string, thunkAPI) => {
	try {
		return await GetDashboardDataService(email);
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const getSuperAdminDashboard = createAsyncThunk("dashboard/getSuperAdminDashboard", async (_, thunkAPI) => {
	try {
		return await GetSuperAdminDashboardService();
	} catch (error: any) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
