export const isUrl = (value: string) => {
	const urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

	return urlPattern.test(value);
};

function clearNumber(value = "") {
	return value.replace(/\D+/g, "");
}
export function isValidEmail(email: string): boolean {
	// Regular expression for validating email addresses
	const emailRegex: RegExp = /^[a-zA-Z0-9_\u00C0-\u017F]+@[a-zA-Z0-9_\u00C0-\u017F]+\.[a-zA-Z0-9_\u00C0-\u017F]+$/;

	// Test the email against the regular expression
	return emailRegex.test(email);
}

export function formatExpirationDate(value: string) {
	const clearValue = clearNumber(value);

	if (clearValue.length >= 3) {
		return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
	}

	return clearValue;
}

export function formatCreditCardNumber(value: string) {
	if (!value) {
		return value;
	}

	const issuer = undefined as any;
	const clearValue = clearNumber(value);
	let nextValue;

	switch (issuer) {
		case "amex":
			nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`;
			break;
		case "dinersclub":
			nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`;
			break;
		default:
			nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(
				12,
				19
			)}`;
			break;
	}

	return nextValue.trim();
}

const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

export function generateString(length: number) {
	let result = " ";
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}

	return result.trim();
}

export function replaceEnglishCharacters(text: string) {
	const englishChars = {
		á: "a",
		é: "e",
		í: "i",
		ó: "o",
		ú: "u",
		ü: "u",
		ñ: "n",
		Á: "A",
		É: "E",
		Í: "I",
		Ó: "O",
		Ú: "U",
		Ü: "U",
		Ñ: "N",
		ğ: "g",
		ş: "s",
		ı: "i",
		ö: "o",
		ç: "c",
		Ğ: "G",
		Ş: "S",
		İ: "I",
		Ö: "O",
		Ç: "C",
	} as any;

	return text.replace(/[áéíóúüñÁÉÍÓÚÜÑçğşŞĞöÖİı]/g, (char) => englishChars[char] || "").replace(/[^a-zA-Z ]/g, "");
}
