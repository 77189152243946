import { ChangeEvent, useEffect, useState } from "react";

import BodyHeader from "../../Components/BodyHeader/BodyHeader";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { HelpCenterCategories, HelpCenterStatuses } from "../../Constants/constants";
import { HelpCenterRequestType } from "../../Constants/types";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { showAlert } from "../../Redux/reducers/reducers";
import { createHelpCenterRequest } from "../../Redux/actions/help-center.actions";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";

type Props = {};

const HelpCenterPage = (props: Props) => {
	const { user, loading, success } = useAppSelector((state) => state.global);
	const [formData, setFormData] = useState<HelpCenterRequestType>({
		category: HelpCenterCategories[0],
		description: "",
		email: user.email,
		status: HelpCenterStatuses[0],
		topic: "",
	});
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	useEffect(() => {
		if (location.search.includes("custom-pricing=true")) {
			const _topic = location.search.split("&topic=")[1];

			setFormData({ ...formData, category: HelpCenterCategories[3], topic: _topic.split("-").join(" ") });
		}
	}, []);

	const onSend = () => {
		if (!formData.topic || !formData.description) {
			dispatch(
				showAlert({
					text: "Please fill both fields!",
					type: "error",
				})
			);
		} else {
			dispatch(createHelpCenterRequest(formData));
		}
	};
	const handleInputs = (event: ChangeEvent<HTMLInputElement>) => {
		setFormData({ ...formData, [event.currentTarget.name]: event.currentTarget.value });
	};
	useEffect(() => {
		if (success) {
			setTimeout(() => {
				navigate(-1);
			}, 1500);
		}
	}, [success]);

	return (
		<>
			<BodyHeader
				leftItem={<ArrowLeftIcon color='black' width={24} height={24} />}
				onLeftItemClick={() => navigate(-1)}
			/>
			<div className='w-full ml-5 h-screen  flex pt-1 items-center flex-col rounded-xl overflow-scroll'>
				<span className='font-medium text-lg'>
					Please fill the form below to let us know what kind of help you need.
				</span>
				<span className='font-medium '>We will contact you as soon as possible</span>
				<div className='p-fluid'>
					<div className='field my-1'>
						<label htmlFor='topic' className='font-semibold'>
							Topic
						</label>
						<InputText
							name='topic'
							id='topic'
							onChange={handleInputs}
							value={formData.topic}
							className='ring-0 outline-none border px-2 py-1'
						/>
					</div>
					<div className='field my-1'>
						<label htmlFor='description' className='font-semibold'>
							Description
						</label>
						<InputTextarea
							name='description'
							id='description'
							onChange={(e) => setFormData({ ...formData, description: e.currentTarget.value })}
							value={formData.description}
							className='ring-0 outline-none border px-2 py-1'
						/>
					</div>
					<div className='field my-1'>
						<label htmlFor='email' className='font-semibold'>
							Email
						</label>
						<InputText
							name='email'
							id='email'
							className='ring-0 outline-none border px-2 py-1'
							disabled={Boolean(Object.values(user).length)}
							onChange={handleInputs}
							value={formData.email}
						/>
					</div>
					<div className='field my-1'>
						<label htmlFor='category' className='font-semibold'>
							Category
						</label>
						<Dropdown
							value={formData.category}
							onChange={(e) => setFormData({ ...formData, category: e.value })}
							options={HelpCenterCategories}
							className='ring-0 outline-none border p-0'
						/>
					</div>
				</div>
				<Button
					label='Send'
					onClick={onSend}
					disabled={loading}
					loading={loading}
					className='border border-green-600 text-green-600 py-1 px-2 outline-none ring-0'
				/>
			</div>
		</>
	);
};

export default HelpCenterPage;
