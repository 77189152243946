import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/solid";
import { APP_PATHS, SideBarMenuList, SuperAdminSideBarMenuList } from "../../Constants/constants";
import { Bars3Icon } from "@heroicons/react/24/solid";
import Icon from "../Icon/Icon";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { logout, sidebarToggle } from "../../Redux/reducers/reducers";
import { Link } from "react-router-dom";
type Props = {};

const SideBar = (props: Props) => {
	const dispatch = useAppDispatch();
	const { isSidebarOpen, user } = useAppSelector((state) => state.global);

	const handleLogout = () => {
		dispatch(logout());
	};
	return (
		<div className={`${isSidebarOpen ? "w-52" : " w-11"} hidden md:flex sidebar relative`}>
			<div className='mb-5 flex flex-row trans-all relative items-center w-full h-7 text-white justify-between '>
				<Link
					to={`/${APP_PATHS.DASHBOARD}`}
					className={`${
						isSidebarOpen ? "opacity-100 translate-x-0 z-40 scale-100" : "opacity-0 scale-0 -translate-x-40 -z-40"
					} trans-all cursor-pointer tracking-wider font-bold text-xl ml-3`}>
					Talentify <span className='text-sm'>Pro</span>
				</Link>
				<Bars3Icon
					className={`w-6 h-6 absolute right-2.5 trans-all cursor-pointer  top-1`}
					onClick={() => dispatch(sidebarToggle())}
				/>
			</div>
			{SideBarMenuList.map((item, index) => (
				<Link
					key={index}
					to={`/${item.path}`}
					className={`text-white cursor-pointer w-11/12 text-sm overflow-auto sidebar-item bg-black hover:bg-white trans-all border border-white hover:text-black rounded-xl px-2 py-1 my-2 font-semibold`}>
					<div className='grid grid-cols-10'>
						<Icon iconName={item.icon} />
						<div
							className={`trans-all ml-2 min-w-[140px] ${
								isSidebarOpen ? "opacity-100 translate-x-0 z-40 scale-100" : "scale-0 -translate-x-40 opacity-0 -z-40"
							}`}>
							{item.name}
						</div>
					</div>
				</Link>
			))}

			{user?.isSuperAdmin && (
				<>
					<hr className='bg-white border-white border w-full my-5' />
					{SuperAdminSideBarMenuList.map((item, index) => (
						<Link
							key={index}
							to={`/${item.path}`}
							className={`text-white cursor-pointer w-11/12 text-sm overflow-auto sidebar-item bg-black hover:bg-white trans-all border border-white hover:text-black rounded-xl px-2 py-1 my-2 font-semibold`}>
							<div className='grid grid-cols-10'>
								<i className={`pi mt-0.5 ml-0.5 ${item.icon}`}></i>
								<div
									className={`trans-all ml-2 min-w-[140px] ${
										isSidebarOpen
											? "opacity-100 translate-x-0 z-40 scale-100"
											: "scale-0 -translate-x-40 opacity-0 -z-40"
									}`}>
									{item.name}
								</div>
							</div>
						</Link>
					))}
				</>
			)}
			<div
				onClick={handleLogout}
				className={`text-white cursor-pointer absolute bottom-0 w-11/12 text-sm overflow-auto sidebar-item bg-black hover:bg-white trans-all border border-white hover:text-black rounded-xl px-2 py-1 my-2 font-semibold`}>
				<div className='grid grid-cols-10'>
					<ArrowLeftOnRectangleIcon className={`w-5 h-5`} />
					<div
						className={`trans-all ml-2 min-w-[140px] ${
							isSidebarOpen ? "opacity-100 translate-x-0 z-40 scale-100" : "scale-0 -translate-x-40 opacity-0 -z-40"
						}`}>
						Log Out
					</div>
				</div>
			</div>
		</div>
	);
};

export default SideBar;
