import { Bars3Icon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import MobileNavbar from "../Navbar/MobileNavbar";
import { useState } from "react";
import { useAppSelector } from "../../Redux/store/store";

type Props = {
	leftItem?: any;
	leavingTo?: string;
	rightItem?: string;
	onLeftItemClick?: () => void;
};

const BodyHeader = ({ leavingTo, leftItem, rightItem, onLeftItemClick }: Props) => {
	const navigate = useNavigate();
	const { token } = useAppSelector((state) => state.global);
	const [isMobileNavbarVisible, setIsMobileNavbarVisible] = useState(false);
	const handleClick = () => {
		leavingTo && navigate(`/${leavingTo}`);
		onLeftItemClick && onLeftItemClick();
	};
	const toggleMobileNavbar = () => setIsMobileNavbarVisible(!isMobileNavbarVisible);
	return (
		<div className='w-[99%] md:h-10 mx-auto border border-black rounded-xl pr-2 py-2 pl-0.5 mt-2 mb-2 flex flex-row items-center justify-between'>
			{leftItem ? (
				<span
					onClick={handleClick}
					className='hover:border-black font-semibold text-black rounded-xl cursor-pointer px-2 py-1 trans-all'>
					{leftItem}
				</span>
			) : (
				<div />
			)}
			<div className={token ? "hidden md:flex" : "flex"}>
				{rightItem && <span className='font-semibold'>{rightItem}</span>}
			</div>
			{token && (
				<div className='flex md:hidden'>
					<Bars3Icon className={`w-6 h-6 trans-all cursor-pointer`} onClick={toggleMobileNavbar} />
					<MobileNavbar visible={isMobileNavbarVisible} onClose={toggleMobileNavbar} />
				</div>
			)}
		</div>
	);
};

export default BodyHeader;
