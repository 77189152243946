import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

import { useState } from "react";
import { firebaseApp } from "../Utils/firebase";
const storage = getStorage(firebaseApp);

const useUploadStorage = () => {
	const [media, setMedia] = useState<string | undefined>(undefined);
	const [progress, setProgress] = useState(0);
	const uploadImage = (file: File) => {
		const storageRef = ref(storage, `images/${file.name}`);

		const uploadTask = uploadBytesResumable(storageRef, file);
		uploadTask.on(
			"state_changed",
			(snapshot) => {
				setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				switch (snapshot.state) {
					case "paused":
						break;
					case "running":
						break;
				}
			},
			(error) => {
				// toast.error("Fotoğraf yüklenirken bir aksilik oldu!");
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: string) => {
					setMedia(downloadURL);
				});
			}
		);
	};
	const uploadCV = (file: File) => {
		const storageRef = ref(storage, `cv/${file.name}`);

		const uploadTask = uploadBytesResumable(storageRef, file);
		uploadTask.on(
			"state_changed",
			(snapshot) => {
				setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				switch (snapshot.state) {
					case "paused":
						break;
					case "running":
						break;
				}
			},
			(error) => {
				// toast.error("Fotoğraf yüklenirken bir aksilik oldu!");
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: string) => {
					setMedia(downloadURL);
				});
			}
		);
	};
	return { media, uploadImage, progress, setMedia, uploadCV };
};

export default useUploadStorage;
