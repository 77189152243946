import { APP_PATHS, SideBarMenuList } from "../../Constants/constants";
import { Link } from "react-router-dom";
import Icon from "../Icon/Icon";
import { XMarkIcon } from "@heroicons/react/24/outline";
type Props = {
	visible: boolean;
	onClose: () => void;
};

const MobileNavbar = ({ visible, onClose }: Props) => {
	return (
		<div
			className={`fixed flex ${
				visible ? "z-50 opacity-100 bg-opacity-70" : "-z-50 opacity-0"
			} trans-all w-screen h-screen left-0 top-0 bg-white items-center justify-center flex-col`}>
			<div className='absolute w-11/12 rounded-xl flex flex-row bg-white top-3.5 items-center justify-between'>
				<Link to={`/${APP_PATHS.DASHBOARD}`} className={` tracking-wider font-bold text-xl ml-3`}>
					Talentify
				</Link>
				<XMarkIcon width={25} height={25} strokeWidth={3} onClick={onClose} className='cursor-pointer' />
			</div>

			{SideBarMenuList.map((item, index) => (
				<Link
					key={index}
					to={`/${item.path}`}
					className={`text-white cursor-pointer w-60 text-center hover:border-black bg-black hover:bg-white trans-all border border-white hover:text-black rounded-xl p-2 my-2 font-semibold`}>
					<div className='flex flex-row items-center'>
						<Icon iconName={item.icon} />
						<div className={`trans-all ml-2`}>{item.name}</div>
					</div>
				</Link>
			))}
		</div>
	);
};

export default MobileNavbar;
